import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import camera from "../../assets/images/camera.png";
import full_camera from "../../assets/images/full_camera.png";
import ic_org_ck from "../../assets/images/ic_org_ck.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { encodeFileToBase64, numberWithCommas } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { updateSafeTradeOrder } from "../../redux/safe-trade/safeTradeOrder";

export default function SafeTradeStep3({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const { is_contract, is_parts, contract, parts, memo } = useSelector(
    (state) => state.safeTradeOrder
  );

  const inputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState({ contract: null, parts: null });

  const updateOrder = (order) => dispatch(updateSafeTradeOrder(order));

  const handleClickCheck = (key, value) => updateOrder({ [key]: value });

  const fileToSrc = async (key, file) => {
    if (file) {
      const src = await encodeFileToBase64(file);
      setImageSrc((imageSrc) => {
        return { ...imageSrc, [key]: src };
      });
    } else {
      setImageSrc((imageSrc) => {
        return { ...imageSrc, [key]: null };
      });
    }
  };

  useEffect(() => {
    fileToSrc("contract", contract);
    fileToSrc("parts", parts);
  }, [contract, parts]);

  useEffect(() => {
    !is_contract && updateOrder({ contract: null });
  }, [is_contract]);

  useEffect(() => {
    !is_parts && updateOrder({ parts: null });
  }, [is_parts]);

  const handleClickImage = (key) => {
    updateOrder({ [key]: null });
    inputRef.current.index = key;
    inputRef.current?.click();
  };

  const handleChangeFile = async (e) => {
    const file = e.target.files[0];

    if (file) {
      updateOrder({ [inputRef.current.index]: file });
    }

    e.target.value = "";
  };

  const handleClickNext = () => {
    history.push(routes.safeTradeStep4);
  };

  const canNext = (!is_contract || contract) && (!is_parts || parts);
  return (
    <BasicLayout
      stackTopbar={{
        title: "3/3",
        option: (
          <ButtonBase onClick={() => history.replace(routes.main)}>
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}
    >
      <div className="progressbar">
        <div className="per100"></div>
      </div>
      <div className="trade_wrap">
        <div className="pd_lst">
          <h3 className="pd_tit">보증서 및 구성품을 등록해 주세요.</h3>
          <p className="pd_txt">마지막 단계입니다.</p>
          <div className="pd_qs1">
            <div className="qs1">보증서가 있으신가요?</div>
            <ul>
              <li
                className={`${is_contract && "on"}`}
                onClick={() => handleClickCheck("is_contract", true)}
              >
                있음 {is_contract && <img src={ic_org_ck} />}
              </li>
              <li
                className={`${!is_contract && "on"}`}
                onClick={() => handleClickCheck("is_contract", false)}
              >
                없음 {!is_contract && <img src={ic_org_ck} />}
              </li>
            </ul>
          </div>
          <div className="pd_qs2">
            <div className="qs1">구성품이 있으신가요?</div>
            <ul>
              <li
                className={`${is_parts && "on"}`}
                onClick={() => handleClickCheck("is_parts", true)}
              >
                있음 {is_parts && <img src={ic_org_ck} />}
              </li>
              <li
                className={`${!is_parts && "on"}`}
                onClick={() => handleClickCheck("is_parts", false)}
              >
                없음 {!is_parts && <img src={ic_org_ck} />}
              </li>
            </ul>
          </div>
          {(is_contract || is_parts) && (
            <div className="pd_plus_pic">
              <div className="qs1">사진 첨부하기</div>
              <ul>
                {is_contract && (
                  <li
                    onClick={() => handleClickImage("contract")}
                    style={{ width: "auto" }}
                  >
                    <div className="img_add">
                      {imageSrc.contract ? (
                        <img
                          src={imageSrc.contract}
                          className={`pic_camera ${classes.img}`}
                        />
                      ) : (
                        <img src={full_camera} className="pic_camera" />
                      )}
                    </div>
                    보증서
                  </li>
                )}

                {is_parts && (
                  <li
                    onClick={() => handleClickImage("parts")}
                    style={{ width: "auto" }}
                  >
                    <div className="img_add">
                      {imageSrc.parts ? (
                        <img
                          src={imageSrc.parts}
                          className={`pic_camera ${classes.img}`}
                        />
                      ) : (
                        <img src={full_camera} className="pic_camera" />
                      )}
                    </div>
                    구성품
                  </li>
                )}
              </ul>
            </div>
          )}

          <div className="pd_etc_memo">
            <div className="qs1">기타메모</div>
            <textarea
              placeholder="영수증 있음, 쇼핑백 있음 등"
              onChange={(e) => updateOrder({ memo: e.target.value })}
              value={memo}
            />
          </div>
        </div>
        <div
          className={`ok_btn ${canNext && "on"}`}
          onClick={canNext ? handleClickNext : () => {}}
        >
          다음
        </div>
      </div>

      <input
        type="file"
        accept="image/*"
        onChange={handleChangeFile}
        ref={inputRef}
        style={{ display: "none" }}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({ img: { height: "100%", objectFit: "cover" } });
