import { ROLE } from "../../libs/routes";

// Actions
const INIT = "auth/INIT";
const UPDATE = "auth/UPDATE";

const INIT_STATE = {
  isSigned: false,
  role: ROLE.NONE,
  name: "",
  sns: "",
  socialId: "",
  userId: 0,
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case INIT:
      return { ...INIT_STATE };

    case UPDATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}

// Action 생성자
export function initAuth() {
  return { type: INIT };
}

export function updateAuth(payload) {
  return { type: UPDATE, payload };
}
