import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import StackTopbar from "../../../layouts/basic/StackTopbar";
import MainLayout from "../../../layouts/main/MainLayout";
import { formatTime, numberWithCommas, numFormat } from "../../../libs/utils";
import * as APIS from "../../../libs/apis";
import {
  loadingEnd,
  loadingStart,
} from "../../../redux/loading/LoadingActions";
import ic_ok_green from "../../../assets/images/ic_ok_green.png";
import ic_danger from "../../../assets/images/ic_danger.png";
import ic_phone from "../../../assets/images/ic_phone.png";
import ic_re_ok from "../../../assets/images/ic_re_ok.png";
import ic_re_off from "../../../assets/images/ic_re_off.png";
import ic_close from "../../../assets/images/ic_close.png";
import re_bar from "../../../assets/images/re_bar.png";
import ic_re_product_p from "../../../assets/images/ic_re_product_p.png";
import ic_re_product from "../../../assets/images/ic_re_product.png";
import ic_re_no from "../../../assets/images/ic_re_no.png";
import ic_re_product_ing from "../../../assets/images/ic_re_product_ing.png";
import { SAFE_TRADE_STATUS } from "../../../libs/consts";
import BasicLayout from "../../../layouts/basic/BasicLayout";
import {
  actionError,
  actionOpen,
  actionShow,
} from "../../../redux/action/ActionActions";
import routes from "../../../libs/routes";
import { messageShow } from "../../../redux/message/MessageActions";
import Text from "../../../components/text/Text";
import { toastShow } from "../../../redux/toast/ToastActions";

export default function MySafeTradeBuy({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history?.location?.state || {};
  const user = useSelector((state) => state.user);
  const orderBookId = locationState.id;

  const [order, setOrder] = useState({});
  const [orderHistory, setOrderHistory] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    dispatch(loadingStart);

    const item_id = await APIS.getMySafeTradeBuy(orderBookId)
      .then(({ data: { success, data } }) => {
        success && setOrder(data);

        return data.item_id;
      })
      .catch(() => {
        dispatch(loadingEnd);
      });

    if (item_id) {
      APIS.getSafeTradeHistory(item_id, "buy", orderBookId)
        .then(({ data: { success, data } }) => {
          success && setOrderHistory(data);
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const handleClickMakePayment = () => {
    history.push(routes.safeTradeBuy, {
      id: item_id,
      reservation,
      reservation_time,
      shop: {
        id: shop_id,
        name: shop_name,
        address: shop_address,
        phoneNumber: shop_phone,
      },
    });
  };

  const handleClickViewItem = () => {
    history.push(routes.safeTradeItem, { id: item_id });
  };

  const handleClickCancelPayment = () => {
    dispatch(
      actionShow("결제를 취소하시겠습니까?", null, [
        () => {},
        () => {
          dispatch(loadingStart);
          APIS.deleteSafeTradeBuyPayment({ itemId: item_id, orderBookId })
            .then(({ data: { success, message } }) => {
              if (success) {
                dispatch(toastShow("결제가 취소되었습니다."));
                init();
              } else {
                dispatch(actionError(message));
              }
            })
            .finally(() => dispatch(loadingEnd));
        },
      ])
    );
  };

  const handleClickConfirm = () => {
    dispatch(loadingStart);
    APIS.postSafeTradeConfirm(item_id)
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(toastShow("제품 구매가 확정되었습니다."));
          init();
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const handleClickReturn = () => {
    dispatch(
      actionShow("반품 신청하시겠습니까?", null, [
        () => {},
        () => {
          dispatch(loadingStart);
          APIS.deleteMySafeTrade(item_id)
            .then(({ data: { success, message } }) => {
              if (success) {
                dispatch(toastShow("반품 신청되었습니다."));
                init();
              } else {
                dispatch(actionError(message));
              }
            })
            .finally(() => dispatch(loadingEnd));
        },
      ])
    );
  };

  const {
    ordered_at,
    category_name,
    brand,
    name,
    serial,
    state,
    shop_id,
    shop_name,
    shop_address,
    shop_phone,
    status,
    price,
    fee,
    reservation,
    reservation_time,
    item_id,
  } = order;

  let icon = null;
  let title = "";
  let description = "";
  let buttonSection = <></>;
  switch (status) {
    case "PAYMENT_WAIT":
      icon = ic_danger;
      title = "결제 대기 중 입니다.";
      buttonSection = (
        <ul className="receipt_price_bt">
          <li>
            <button onClick={handleClickMakePayment}>결제 하기</button>
          </li>
          <li>|</li>
          <li>
            <button onClick={handleClickViewItem}>제품 보기</button>
          </li>
        </ul>
      );
      break;

    case "PAYMENT_DONE":
      icon = ic_ok_green;
      title = "결제가 완료되었습니다.";
      description = "수령 매장으로 배송이 진행됩니다.";
      buttonSection = (
        <ul className="receipt_price_bt">
          <li>
            <button onClick={handleClickCancelPayment}>결제 취소</button>
          </li>
          <li>|</li>
          <li>
            <button onClick={handleClickViewItem}>제품 보기</button>
          </li>
        </ul>
      );
      break;

    case "PAYMENT_CANCEL1":
    case "PAYMENT_CANCEL2":
      icon = ic_re_no;
      title = "결제가 취소되었습니다.";
      buttonSection = (
        <ul className="receipt_price_bt">
          <li className="disabled">
            <button>결제 취소</button>
          </li>
          <li>|</li>
          <li>
            <button onClick={handleClickViewItem}>제품 보기</button>
          </li>
        </ul>
      );
      break;

    case "DELIVERY":
      icon = ic_re_product_ing;
      title = "제품이 출고되었습니다.";
      buttonSection = (
        <ul className="receipt_price_bt">
          <li className="disabled">
            <button>결제 취소</button>
          </li>
          <li>|</li>
          <li>
            <button onClick={handleClickViewItem}>제품 보기</button>
          </li>
        </ul>
      );
      break;

    case "TRANSFER_WAIT":
      icon = ic_re_product;
      title = "제품이 매장에 도착했습니다.";
      description = "구매자 수령 대기중입니다.";
      buttonSection = (
        <ul className="receipt_price_bt">
          <li className="disabled">
            <button>결제 취소</button>
          </li>
          <li>|</li>
          <li>
            <button onClick={handleClickViewItem}>제품 보기</button>
          </li>
        </ul>
      );
      break;

    case "TRANSFER_COMPLETE":
      icon = ic_re_product;
      title = "구매가 완료되었습니다.";
      description = "구매를 확정해주세요.";
      buttonSection = (
        <ul className="receipt_price_bt">
          <li>
            <button onClick={handleClickReturn}>반품 신청</button>
          </li>
          <li>|</li>
          <li>
            <button onClick={handleClickConfirm}>구매 확정</button>
          </li>
        </ul>
      );
      break;

    case "COMPLETE":
      icon = ic_ok_green;
      title = "제품 구매가 확정되었습니다.";
      buttonSection = (
        <ul className="receipt_price_bt">
          <li className="disabled">
            <button>반품 신청</button>
          </li>
          <li>|</li>
          <li className="disabled">
            <button>구매 확정</button>
          </li>
        </ul>
      );
      break;

    case "TRANSFER_RETURN":
    case "TRANSFER_CANCEL":
      icon = ic_re_no;
      title = "구매가 취소되었습니다.";
      buttonSection = (
        <ul className="receipt_price_bt">
          <li className="disabled">
            <button>반품 신청</button>
          </li>
          <li>|</li>
          <li>
            <button onClick={handleClickViewItem}>제품 보기</button>
          </li>
        </ul>
      );
      break;
  }

  return (
    <BasicLayout stackTopbar={{ title: "주문 상세", border: true }} white>
      <div className="receipt_wrap">
        <div className="receipt_progress">
          <div className="receipt product">
            <img src={icon} alt="" />
            <p>{title}</p>
            <span>{description}</span>
          </div>
          {/* <img src={re_bar} alt="" style={{ width: "100%" }} /> */}
          <div className="receipt_price">
            <ul className="receipt_price_ul line">
              <li>상품 금액</li>
              <li>
                <span className="spoqa">{numberWithCommas(price)}</span>원
              </li>
            </ul>
            <ul className="receipt_price_ul line">
              <li>수수료</li>
              <li>
                <span className="spoqa">{numberWithCommas(fee)}</span>원
              </li>
            </ul>
            <ul className="receipt_price_ul last">
              <li>결제 금액</li>
              <li>
                <span className="spoqa">{numberWithCommas(+price + +fee)}</span>
                원
              </li>
            </ul>
            {buttonSection}
          </div>
          <div className="receipt_num">
            <h3 className="spoqa">
              {orderBookId}
              <br />
              <span className="spoqa receipt_date">
                {formatTime(ordered_at, "YYYY.MM.DD")}
              </span>
            </h3>
          </div>
          <div className="bar"></div>
          <div className="receipt_info product">
            <h3>구매 제품 정보</h3>
            <table>
              <tr>
                <td>카테고리</td>
                <td>
                  {category_name} &gt; {brand}
                </td>
              </tr>
              <tr>
                <td>제품명</td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>모델 번호</td>
                <td className="spoqa">{serial}</td>
              </tr>
              <tr>
                <td>제품 상태</td>
                <td className="ftbd">{state}</td>
              </tr>
            </table>
          </div>
          <div className="bar"></div>
          <div className="receipt_info product">
            <h3>구매 접수 정보</h3>
            <table>
              <tr>
                <td className="toptd">수령매장</td>
                <td>
                  {shop_name}
                  <p className="grad">{shop_address}</p>
                  <span className="spoqa orgbt">
                    <img src={ic_phone} />
                    {shop_phone}
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div className="bar" />
          <SafeTradeBuyHistory history={orderHistory} />
        </div>
      </div>
    </BasicLayout>
  );
}

const STATUS_MAP = {
  PAYMENT: [
    "PAYMENT_WAIT",
    "PAYMENT_CANCEL1",
    "PAYMENT_DONE",
    "PAYMENT_CANCEL2",
    "PREVIEW_READY",
    "PREVIEW_PAYMENT",
  ],
  DELIVERY: [
    "DELIVERY",
    "TRANSFER_WAIT",
    "TRANSFER_COMPLETE",
    "TRANSFER_RETURN",
  ],
  COMPLETE: ["RETURN_COMPLETE", "COMPLETE", "TRANSFER_CANCEL"],
};

// 제품 진행 내역
const SafeTradeBuyHistory = ({ history = [] }) => {
  const [tab, setTab] = useState("");

  useEffect(() => {
    if (history.find(({ status }) => STATUS_MAP.COMPLETE.includes(status))) {
      setTab("COMPLETE");
      return;
    }
    if (history.find(({ status }) => STATUS_MAP.DELIVERY.includes(status))) {
      setTab("DELIVERY");
      return;
    }
    if (history.find(({ status }) => STATUS_MAP.PAYMENT.includes(status))) {
      setTab("PAYMENT");
      return;
    }
  }, [history]);

  const filtered = history.filter(({ status }) =>
    STATUS_MAP[tab]?.includes(status)
  );

  return (
    <div className="receipt_history product">
      <h3>주문 진행 내역</h3>
      <ul>
        <li
          className={`${tab === "PAYMENT" ? "on" : ""}`}
          onClick={() => setTab("PAYMENT")}
        >
          결제
        </li>
        <li
          className={`wd85 ${tab === "DELIVERY" ? "on" : ""}`}
          onClick={() => setTab("DELIVERY")}
        >
          픽업 or 배송
        </li>
        <li
          className={`${tab === "COMPLETE" ? "on" : ""}`}
          onClick={() => setTab("COMPLETE")}
        >
          완료
        </li>
      </ul>
      <table>
        {filtered.map(({ description, createdAt }, i) => {
          return (
            <tr key={i.toString()}>
              <td>{description}</td>
              <td className="spoqa">
                {formatTime(createdAt, "YYYY.MM.DD / HH:mm")}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const useStyle = makeStyles({});
