import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import images from "../../libs/images";

export default function ListItem({ children, disabled, onClick }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      row
      className={classes.root}
    >
      <Text className={`${classes.label} ${disabled && classes.labelDis}`}>
        {children}
      </Text>
      {!disabled && <img alt="right" src={images.rightgray} />}
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    "& img": {
      width: "7px",
      height: "12px",
    },
  },
  label: {
    color: colors.text,
    fontSize: "16px",
  },
  labelDis: {
    color: "#999",
  },
});
