import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import StackTopbar from "../../../layouts/basic/StackTopbar";
import MainLayout from "../../../layouts/main/MainLayout";
import { formatTime, numberWithCommas, numFormat } from "../../../libs/utils";
import * as APIS from "../../../libs/apis";
import {
  loadingEnd,
  loadingStart,
} from "../../../redux/loading/LoadingActions";
import ic_ok_green from "../../../assets/images/ic_ok_green.png";
import ic_danger from "../../../assets/images/ic_danger.png";
import ic_phone from "../../../assets/images/ic_phone.png";
import ic_re_ok from "../../../assets/images/ic_re_ok.png";
import ic_re_off from "../../../assets/images/ic_re_off.png";
import re_bar from "../../../assets/images/re_bar.png";
import img_none from "../../../assets/images/img_none.png";
import consts, { SAFE_TRADE_STATUS } from "../../../libs/consts";
import BasicLayout from "../../../layouts/basic/BasicLayout";
import {
  actionError,
  actionOpen,
  actionShow,
} from "../../../redux/action/ActionActions";

export default function MySafeTradeSellDetail({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history?.location?.state || {};
  const [detail, setDetail] = useState({});

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getMySafeTradeItemDetail(state.id)
      .then(({ data: { success, data } }) => {
        if (success) {
          const copied = { ...data };
          for (let i in copied) {
            if (i.indexOf("_image") > -1 && copied[i]) {
              copied[i] = `${consts.s3BaseUrl}/${copied[i]}`;
            }
          }

          setDetail(copied);
        }
      })
      .finally(() => dispatch(loadingEnd));
  }, []);

  return (
    <BasicLayout
      stackTopbar={{ title: "감정 결과 상세보기", border: true }}
      white
    >
      <div className="receipt_wrap">
        <div className="receipt_progress">
          <div className="receipt_info re">
            <h3>안심직거래 감정 결과</h3>
            <table>
              <tr>
                <td>감정가</td>
                <td>
                  <span className="spoqa">
                    {numberWithCommas(detail.auth_price)}
                  </span>
                  원
                </td>
              </tr>
              <tr>
                <td>감정 제품 및 보증서</td>
                <td>
                  <ul>
                    <li>
                      <div className="pd_img">
                        <img src={detail.front_image || img_none} />
                      </div>
                      앞면
                    </li>
                    <li>
                      <div className="pd_img">
                        <img src={detail.side_image || img_none} />
                      </div>
                      옆면
                    </li>
                    <li>
                      <div className="pd_img">
                        <img src={detail.back_image || img_none} />
                      </div>
                      뒷면
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className="pd_img">
                        <img src={detail.item_image || img_none} />
                      </div>
                      모델명
                    </li>
                    <li>
                      <div className="pd_img">
                        <img src={detail.contract_image || img_none} />
                      </div>
                      보증서
                    </li>
                    <li>
                      <div className="pd_img">
                        <img src={detail.parts_image || img_none} />
                      </div>
                      구성품
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          <div className="bar"></div>
          <div className="receipt_info app">
            <h3>감정 내용</h3>
            <table>
              <tr>
                <td>제조사</td>
                <td>{detail.brand}</td>
              </tr>
              <tr>
                <td>제품명</td>
                <td>{detail.name}</td>
              </tr>
              <tr>
                <td>모델번호</td>
                <td className="spoqa">{detail.serial}</td>
              </tr>
              <tr>
                <td>시중 신품가격</td>
                <td>
                  {!!detail.product_price && (
                    <>
                      <span className="spoqa">
                        {numberWithCommas(detail.product_price)}
                      </span>
                      원
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>제조 출시년도</td>
                <td>
                  {!!detail.out_date && (
                    <>
                      <span className="spoqa">
                        {formatTime(detail.out_date, "YYYY")}
                      </span>
                      년
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>보증서</td>
                <td>{detail.is_contract ? "유" : "무"}</td>
              </tr>
              <tr>
                <td>상품 상태</td>
                <td>{detail.state}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}
const useStyle = makeStyles({});
