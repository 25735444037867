import { ButtonBase, makeStyles, Snackbar } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import axios from "axios";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";
import { actionError } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function FormPopup({
  onClose,
  method,
  settype,
  priceType,
  inputprice,
  gram,
  totalprice,
  id,
  onSuccess,
  fee,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  var forminputprice = inputprice;
  var formgram = gram;
  var formtotalprice = totalprice;
  Number.prototype.format = function () {
    if (this == 0) return 0;

    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = this + "";

    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

    return n;
  };

  // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
  String.prototype.format = function () {
    var num = parseFloat(this);
    if (isNaN(num)) return "";

    return num.format();
  };

  inputprice = inputprice.format();
  gram = gram.format();
  totalprice = totalprice.format();

  const lock = useRef(false);
  const tradeOrder = () => {
    if (lock.current) {
      return false;
    }

    lock.current = true;
    dispatch(loadingStart);

    var mothodData = "";
    var priceTypeData = "";
    if (method === "buy") {
      mothodData = "BUY";
    } else {
      mothodData = "SELL";
    }
    if (priceType === "supply") {
      priceTypeData = "LIMITS";
    } else {
      priceTypeData = "MARKETPRICE";
    }

    APIS.postRequestTrade({
      tradeType: mothodData,
      assetType: settype,
      orderPrice: forminputprice,
      orderType: priceTypeData,
      requestGram: formgram,
      requestTotal: formtotalprice * 1,
      userId: id,
    })
      .then((res) => {
        var resData = res.data;
        if (resData.success === false) {
          dispatch(actionError(resData.message));
          return;
        } else {
          setOpen(true);
          let tm = setTimeout(() => {
            clearTimeout(tm);
            onClose();
          }, 2000);
        }
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        dispatch(loadingEnd);
        lock.current = false;
      });
  };

  return (
    <Flex className={classes.root}>
      <Flex className={classes.containter}>
        <Text font={fonts.notoSansKRBold} className={classes.label}>
          {method === "buy" ? "매수주문확인" : "매도주문확인"}
        </Text>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>주문상품</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {settype === "GOLD" ? "금(GOLD)" : "은(SILVER)"}
          </Text>
        </Flex>

        <Flex row className={classes.row}>
          <Text className={classes.hint}>주문방식</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {priceType === "supply" ? "지정가" : "시장가"}
          </Text>
        </Flex>

        <Flex row className={classes.row}>
          <Text className={classes.hint}>
            {method === "buy" ? "매수가격" : "매도가격"}
          </Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {inputprice}원
          </Text>
        </Flex>

        <Flex row className={classes.row}>
          <Text className={classes.hint}>주문중량</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {gram}g
          </Text>
        </Flex>

        <Flex row className={classes.row}>
          <Text className={classes.hint}>주문금액</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {totalprice}원
          </Text>
        </Flex>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>수수료 금액</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {Math.abs(fee).format()}원
          </Text>
        </Flex>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>결제총액</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {(+formtotalprice + fee).format()}원
          </Text>
        </Flex>

        <Flex row className={classes.buttons}>
          <ButtonBase onClick={onClose} className={classes.button1}>
            취소
          </ButtonBase>
          <ButtonBase
            disabled={open}
            onClick={() => {
              tradeOrder();
            }}
            style={{
              backgroundColor:
                method === "buy" ? colors.textRed : colors.textBlue,
            }}
            className={classes.button2}
          >
            {method === "buy" ? "매수" : "매도"}
          </ButtonBase>
        </Flex>
      </Flex>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={() => {
          onSuccess && onSuccess();
          onClose();
        }}
        message={"주문이 완료 되었습니다."}
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    zIndex: 11,
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: "flex-end",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  containter: {
    backgroundColor: colors.white,
    padding: "30px 20px",
    alignItems: "center",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  label: {
    fontSize: "20px",
    marginBottom: "20px",
    color: colors.black,
  },
  row: {
    width: "50%",
    alignItems: "center",
    marginTop: "6px",
  },
  hint: {
    color: "#999999",
    fontSize: "14px",
    flex: 1,
  },
  value: {
    flex: 1,
    textAlign: "right",
    color: "#000",
    fontSize: "14px",
  },
  buttons: {
    alignSelf: "stretch",
    alignItems: "center",
    marginTop: "30px",
  },
  button1: {
    borderRadius: "5px",
    padding: "10px 0px",
    textAlign: "center",
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    flex: 1,
    marginRight: "10px",
    fontSize: "16px",
    backgroundColor: "#999",
  },
  button2: {
    borderRadius: "5px",
    padding: "10px 0px",
    textAlign: "center",
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    flex: 1,
    fontSize: "16px",
  },
});
