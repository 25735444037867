import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import routes from "../../libs/routes";
import Button from "../button/Button";
import NumberFormat from "react-number-format";

import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import { actionError } from "../../redux/action/ActionActions";

export default function PhysicalWithDrawalItem({
  key,
  rows,
  sPrice,
  settype,
  order,
  currentPrice,
  toLocation,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [productData, setproductData] = useState({});
  const [stype, setstype] = useState("GOLD");
  const [setprice, setSetprice] = useState(0);
  const [orderTxt, setorderTxt] = useState("구매요청");

  const [imgPath, setimgPath] = useState("");

  var setPrice = 0;
  var rowData = [];

  if (sPrice === "get") {
    useEffect(() => {
      rowData = rows;
      if (rows.status === "APPROVAL") {
        if (
          rows.shipmentStatus === "MAKE" ||
          rows.shipmentStatus === "SHIPMENT"
        ) {
          setorderTxt("제작 중");
        } else if (rows.shipmentStatus === "DELIVERY") {
          setorderTxt("픽업 or 배송");
        } else {
          setorderTxt("결제완료");
        }
      } else if (rows.status === "TAKEOUT") {
        setorderTxt("인출완료");
      } else if (rows.status === "CONFIRM") {
        setorderTxt("인출확정");
      } else if (rows.status === "CANCEL") {
        setorderTxt("취소");
      } else {
        setorderTxt("결제완료");
      }

      APIS.getGoodsDetail(rows.goodsId).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            setproductData(data);
            setstype(data.goldOrSilver);
          }
        }
      );
    }, [stype]);

    setPrice = currentPrice[stype.toLowerCase()] * productData.gram;

    rowData = productData;
    if (productData.code !== "") {
      if (productData.imageIds?.length > 0) {
        rowData.image = productData.imageIds[0].path;
      }
    }
  } else {
    rowData = rows;
    // setPrice = sPrice * rowData.gram;
    setPrice = rows.totalPrice;
  }

  return (
    <Flex className={classes.root}>
      <ButtonBase
        onClick={() => {
          toLocation
            ? history.push(routes.orderDetail(rows.id, rows.no, rows.goodsId))
            : history.push(routes.physicalWithDrawalPDetail, {
                id: rowData.id,
                sPrice: sPrice,
                settype: settype,
              });
        }}
        className={classes.item}
      >
        <img
          src={
            rowData.image
              ? consts.s3BaseUrl + "/" + rowData.image
              : images.goldbar
          }
          className={classes.sample1}
        />
        <Flex className={classes.main}>
          <Flex row className="row-between" style={{ alignSelf: "stretch" }}>
            <Text className={classes.label1}>{rowData.name}</Text>

            {rows.status === "CANCEL" ? (
              <>
                {order && (
                  <Text
                    className={classes.orderState}
                    font={fonts.notoSansKRMedium}
                  >
                    취소
                  </Text>
                )}
              </>
            ) : (
              <>
                {order && (
                  <Text
                    className={classes.orderState}
                    font={fonts.notoSansKRMedium}
                  >
                    {orderTxt}
                  </Text>
                )}
              </>
            )}
          </Flex>
          {!order && (
            <Text className={classes.label2}>
              <Text font={fonts.notoSansKRMedium}>{rowData.gram}g</Text> ={" "}
              <NumberFormat
                value={setPrice}
                displayType={"text"}
                thousandSeparator={true}
              />
              원
            </Text>
          )}
        </Flex>
      </ButtonBase>
      {order && (
        <Flex row className={classes.buttons}>
          <Button
            label="상품보기"
            onClick={() => {
              if (productData?.visible === "HIDDEN") {
                dispatch(
                  actionError("해당 제품은 현재 인출가능한 상태가 아닙니다.")
                );
              } else {
                history.push(routes.physicalWithDrawalPDetail, {
                  id: rows.goodsId,
                  sPrice: sPrice,
                  settype: settype,
                });
              }
            }}
          />
          <Button
            onClick={() => {
              history.push(routes.orderDetail(rows.id, rows.no, rows.goodsId));
            }}
            label="주문상세"
          />
        </Flex>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  sample1: {
    width: "70px",
    border: "1px solid #eee",
    objectFit: "contain",
    height: "70px",
  },
  root: {
    borderBottom: "1px solid #eee",
  },
  item: {
    padding: "14px 0px",
    margin: "0px 14px",
    alignItems: "center",
    display: "flex",
  },
  main: {
    marginLeft: "16px",
    alignSelf: "stretch",
    alignItems: "flex-start",
    flex: 1,
  },
  label1: { color: colors.gray, fontSize: 12 },
  label2: {
    color: colors.gray,
    fontSize: 12,
    "& span": {
      fontSize: "16px",
      color: "#000",
    },
  },
  buttons: {
    margin: "13px 13px 20px 13px",
    "& button": {
      flex: 1,
      padding: "6px",
      marginLeft: "5px",
      "& span": {
        fontSize: "14px",
      },
      "&:first-child": {
        backgroundColor: "#fff",
        border: "1px solid " + colors.primary,
        "& span": {
          color: colors.primary + " !important",
        },
        marginLeft: "0px",
        marginRight: "5px",
      },
    },
  },
  orderState: { color: colors.primary, fontSize: "12px" },
});
