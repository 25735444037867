import {
  ButtonBase,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AppraiseItem from "../../components/appraise-item/AppraiseItem";
import CheckboxCircle from "../../components/checkbox-circle/CheckboxCircle";
import Flex from "../../components/flex/Flex";
import More from "../../components/more/More";
import Pager from "../../components/pager/Pager";
import PhysicalWithDrawalItem from "../../components/physical-withdrawal-item/PhysicalWithDrawalItem";
import PolicyAgreePopup from "../../components/policy-agree-popup/PolicyAgreePopup";
import ReviewItem from "../../components/review-item/ReviewItem";
import SearchTopbar from "../../components/search-topbar/SearchTopbar";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { actionShow } from "../../redux/action/ActionActions";
import { listShow } from "../../redux/list/ListActions";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import StackTopbar from "../../layouts/basic/StackTopbar";

const GOODS_SORT = [
  { label: "낮은가격순", value: "LOWPRICE" },
  { label: "높은가격순", value: "HIGHPRICE" },
  { label: "최신순", value: "LATEST" },
];
export default function PhysicalWithDrawal({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tab, setTab] = useState(false);
  const [type, setType] = useState("GOLD");
  const [search, setSearch] = useState({
    categoryId: "",
    goods_sort: GOODS_SORT[0]["value"],
    page: 0,
    size: 5,
  });
  const [marketPrice, setMarketPrice] = useState(0);
  const [canMore, setCanMore] = useState(false);

  useEffect(() => {
    APIS.getGoodsCategoryList().then((res) => {
      setCategories(res.data);
      setTab(res.data[0]?.id);
      setSearch({ ...search, categoryId: res.data[0]?.id });
    });
  }, []);

  useEffect(() => {
    APIS.getCurrentClosingMarketPrice(type).then(({ data: { data } }) => {
      const split = data.split(",");
      setMarketPrice(split[0]);
    });
  }, [type]);

  useEffect(() => {
    const { categoryId } = search;

    if (categoryId) {
      APIS.getGoods(categoryId, objToQueryStr(search)).then(
        ({
          data: {
            data: { content, number, totalElements },
          },
        }) => {
          // 첫 페이지

          let addList = [];
          if (number === 0) {
            addList = content;
          } else {
            addList = [...products, ...content];
          }
          setCanMore(!!(addList.length < totalElements));
          setProducts(addList);
        }
      );
    }
  }, [search]);

  return (
    <MainLayout white topbar={<StackTopbar border title="실물인출" />}>
      <Text className={classes.label2}>
        고객님의 자산을 실물로 인출하실 수 있습니다.
      </Text>
      {categories.length > 0 && (
        <Tabs
          value={tab}
          TabIndicatorProps={{ style: { background: "black" } }}
          style={{
            borderBottom: "1px solid #eee",
          }}
          onChange={(e, v) => {
            const select = categories.find(({ id }) => id === v);

            setType(select.goldOrSilver);
            setTab(v);
            setSearch({
              ...search,
              categoryId: v,
              goods_sort: GOODS_SORT[0]["value"],
              page: 0,
            });
          }}
          variant="scrollable"
          scrollButtons="on"
          // allowscrollbuttonsmobile="true"
          aria-label="scrollable force tabs example"
        >
          {categories.map((category) => {
            if (category.deleted) {
              return null;
            } else {
              return (
                <Tab
                  key={category.id}
                  color={colors.gray}
                  label={category.kind}
                  value={category.id}
                />
              );
            }
          })}
        </Tabs>
      )}

      <Flex className={classes.main}>
        <Flex row className={"row-right " + classes.header}>
          <ButtonBase
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            className="row-right"
            style={{ textAlign: "right", margin: "0 0 0 auto" }}
          >
            <Text className={classes.legnth2}>
              {
                (
                  GOODS_SORT.find(({ value }) => value === search.goods_sort) ||
                  {}
                ).label
              }
            </Text>
            <img src={images.down} className={"icon"} />
          </ButtonBase>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {GOODS_SORT.map(({ label, value }) => {
              return (
                <MenuItem
                  key={value}
                  style={{ minHeight: "auto" }}
                  onClick={() => {
                    setAnchorEl(null);
                    setSearch({ ...search, goods_sort: value, page: 0 });
                  }}
                >
                  {label}
                </MenuItem>
              );
            })}
          </Menu>
        </Flex>

        {products.map((x, i) => {
          return (
            <>
              <PhysicalWithDrawalItem
                key={i}
                rows={x}
                sPrice={marketPrice}
                settype={type}
              />
            </>
          );
        })}

        {canMore && (
          <More
            onClick={() => {
              setSearch({ ...search, page: search.page + 1 });
            }}
          />
        )}
      </Flex>
    </MainLayout>
  );
}

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },

  main: {
    flexGrow: 1,
  },
  header: {
    padding: "20px 13px 10px 13px",
    borderBottom: "1px solid #eee",
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: "20px",
    padding: "10px 16px",
    lineHeight: 1,
  },

  buttonRadio: {
    flex: 1,
    backgroundColor: "#dddddd",
    fontSize: "14px",
    fontFamily: fonts.notoSansKRMedium,
    padding: "10px",
    color: "#999",
  },
  buttonRadioSel: {
    backgroundColor: colors.primary,
    color: "#fff",
  },
  label2: { fontSize: "14px", color: "#000", margin: "20px 13px" },
  legnth: {
    marginLeft: "10px",
    fontSize: "12px",
    color: colors.text,
  },
  legnth2: {
    marginRight: "10px",
    fontSize: "12px",
    color: colors.text,
  },
});
