import { ButtonBase, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import RNHeader from "../../components/common/RNHeader";
import Flex from "../../components/flex/Flex";
import Image from "../../components/image/Image";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { getMobileOperatingSystem } from "../../libs/utils";
import { postRequest } from "../../network";
import { messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { userInit, userUpdate } from "../../redux/user/UserActions";
export default function AuthRegisterPassword({}) {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    purpose = "SIGNUP",
    requestId,
    termsTypes,
    recommandShop,
  } = useSelector((s) => s.signUp);

  const [password, setPassword] = useState(["", "", "", "", "", ""]);
  const [prevPassword, setPrevPassword] = useState("");
  const inputRef = useRef([]);

  const isUpdate = purpose === "UPDATE_SECURE_PASSWORD";

  useEffect(() => {
    inputRef.current[0].focus();
  }, []);

  useEffect(() => {
    let isValid = true;

    for (var i of password) {
      if (!i || isNaN(i)) isValid = false;
    }

    if (isValid) {
      if (!prevPassword) {
        setPrevPassword(password.join(""));
        setPassword(["", "", "", "", "", ""]);

        // iOS 14.4 소프트웨어에서 화면 뻗음
        if (getMobileOperatingSystem() !== "iOS") {
          inputRef.current[0].focus();
        }
      } else {
        if (password.join("") === prevPassword) {
          APIS.postSelfVerificationComplete({
            requestId,
            termsTypes,
            recommandShopId: recommandShop.id,
            securePassword: prevPassword,
          }).then(({ data }) => {
            const { code, name } = data;

            const message = isUpdate
              ? "결제 비밀번호 변경이 완료되었습니다."
              : "인증절차가 완료되었습니다.";

            dispatch(
              messageShow(message, () => {
                history.push(
                  `${routes.authGateway}?code=${code}&name=${name}&type=SELF_VERIFICATION_COMPLETED&purpose=${purpose}`
                );
              })
            );
          });
        } else {
          dispatch(
            messageShow(
              "결제 비밀번호가 일치하지 않습니다.\n다시 입력해주세요.",
              () => {
                setPrevPassword("");
                setPassword(["", "", "", "", "", ""]);
                inputRef.current[0].focus();
              }
            )
          );
        }
      }
    }
  }, [password]);

  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "결제 비밀번호",
        onGoBack: isUpdate
          ? () => {
              history.push(routes.main);
            }
          : null,
      }}
    >
      {isUpdate && (
        <RNHeader
          visible={false}
          goBack="CUSTOM"
          onGoBack={() => {
            history.push(routes.main);
          }}
        />
      )}
      <Text font={fonts.notoSansKRMedium} className={classes.text}>
        {!prevPassword
          ? !isUpdate
            ? "결제 비밀번호 등록"
            : "결제 비밀번호 변경"
          : "결제 비밀번호 재입력"}
      </Text>
      <Text className={classes.message}>
        {!prevPassword
          ? "안전한 결제를 위해 결제비밀번호 6자리를 등록하세요."
          : "한번 더 입력해 주세요."}
      </Text>

      <Flex row className={classes.inputs}>
        {password.map((value, i) => {
          return (
            <input
              key={i.toString()}
              type={value ? "text" : "number"}
              maxLength={2}
              ref={(el) => (inputRef.current[i] = el)}
              onChange={({ target: { value } }) => {
                const v = value.substr(value.length - 1);
                if (isNaN(v)) {
                  return false;
                }

                setPassword((password) => {
                  const nextPassword = password.slice();
                  nextPassword[i] = value.substr(value.length - 1);
                  return nextPassword;
                });

                if (value && i < 5) {
                  inputRef.current[i + 1].focus();
                }
              }}
              onKeyDown={(e) => {
                if (
                  !(
                    e.key === "Backspace" ||
                    e.key === "Delete" ||
                    !isNaN(e.key)
                  )
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                }
                if (e.key === "Backspace" && !password[i] && i > 0) {
                  inputRef.current[i - 1].focus();
                }
              }}
              value={value ? "●" : ""}
              className={classes.input}
            />
          );
        })}
      </Flex>
      {/* <Button className={classes.button} label="다음" onClick={() => {}} /> */}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  text: {
    fontSize: 24,
    color: colors.text,
    margin: "100px 30px 0px 30px",
  },
  message: {
    fontSize: 14,
    color: colors.gray,
    margin: "6px 30px 0px 30px",
  },
  button: {
    margin: "50px 30px 0px 30px",
  },
  input: {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px 0px",
    marginRight: "5px",
    textAlign: "center",
    fontSize: 18,
    color: colors.text,
    borderBottom: "3px solid " + colors.text,
    width: "40px",
  },
  inputs: {
    margin: "50px 30px 0px 30px",
  },
});
