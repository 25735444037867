import React, { useState } from "react";
import { postMessage } from "../../libs/utils";
import closewhite from "../../assets/images/closewhite.png";
import { useHistory } from "react-router-dom";
import routes from "../../libs/routes";
import { STORAGE_KEY } from "../../libs/consts";
import { formatTime } from "../../services/utils";

const Coaching = ({ setCoachingVisible }) => {
  const history = useHistory();

  const noCoaching = () => {
    localStorage.setItem(
      STORAGE_KEY.NO_COACHING,
      formatTime(new Date(), "YYYYMMDDHHmmss")
    );
    onClose();
  };
  const onClose = () => {
    setCoachingVisible(false);
  };

  return (
    <div className="coaching_wrap">
      <div className="coaching">
        <div className="none_btn" onClick={noCoaching}>
          다시보지않기
        </div>
        <div className="close_btn" onClick={onClose}>
          <img src={closewhite} alt="X" />
        </div>
      </div>
    </div>
  );
};

export default Coaching;
