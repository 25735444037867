import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function Radio({ label, value, onChange, className }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <ButtonBase
      onClick={() => {
        onChange(!value);
      }}
      className={classes.root + " " + className}
      row
    >
      <Flex
        style={{
          borderRadius: "100px",
          width: "12px",
          height: "12px",
          backgroundColor: value ? colors.black : "#dddddd",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          style={{
            width: 4,
            height: 4,
            borderRadius: 100,
            backgroundColor: "#fff",
          }}
        />
      </Flex>
      <Text className={classes.label}>{label}</Text>
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  root: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
  },
  label: {
    fontSize: 14,
    color: colors.text,
    marginLeft: 7,
  },
});
