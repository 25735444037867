import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import ButtonRadio from "../../components/button-radio/ButtonRadio";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import { phoneFormat } from "../../services/utils";

export default function AppraiseReturn({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const [addressDetail, setAddressDetail] = useState("");
  const state = history.location.state || {};
  const userReducer = useSelector((s) => s.user);

  const method = history.location.state?.method;
  const setMethod = (v) =>
    history.replace(history.location.pathname, { ...state, method: v });

  useEffect(() => {
    if (state?.addressDetail) {
      setAddressDetail(state.addressDetail);
    }
  }, [state]);

  useEffect(() => {
    if (!method) {
      setMethod("shop");
    }
  }, [method]);

  const onReturn = () => {
    try {
      if (method === "delivery") {
        if (!state.address) {
          throw "주소를 검색해 주세요.";
        } else if (!addressDetail) {
          throw "상세주소를 입력해 주세요.";
        }
      } else {
        if (!state.shop) {
          throw "방문하실 매장을 선택해 주세요.";
        }
      }
    } catch (error) {
      return dispatch(messageError(error));
    }

    const params = {
      userId: userReducer.id,
    };

    if (method === "delivery") {
      params.collectAddress = state.address + " " + addressDetail;
    } else {
      params.shopId = state.shop.id;
    }

    dispatch(loadingStart);
    dispatch(
      axiosDispatch(
        {
          method: "put",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/appraisal/Requests/" +
            state.id +
            "/status",
          data:
            method === "delivery"
              ? {
                  returnUserAddressDetail: state.address + " " + addressDetail,
                  returnUserAddressPostcode: state.postCode,
                  returnUserPhone: userReducer.phone,
                  returnUsername: userReducer.name,
                  status: "반송신청",
                }
              : {
                  returnShopId: state.shop.id,
                  status: "반송신청",
                },
        },
        (err, res) => {
          dispatch(loadingEnd);
          if (err) {
            dispatch(messageError(err));
          } else {
            history.replace(routes.appraiseDetail, {
              id: state.id,
            });
            dispatch(toastShow("반송 신청 되었습니다."));
          }
        }
      )
    );
  };

  if (!userReducer.id) {
    return <Redirect to={routes.appraise} />;
  } else if (!state?.id) {
    return <Redirect to={routes.appraise} />;
  }
  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "반송 신청하기",
      }}
    >
      <Text
        style={{
          marginBottom: 20,
        }}
        font={fonts.notoSansKRBold}
        className={classes.label1}
      >
        제품을 교환하지 않고
        <br />
        돌려 받으시겠습니까?
      </Text>
      <ButtonRadio
        onChange={setMethod}
        value={method}
        className="margin"
        data={[
          //     { label: "택배로 회수", value: "delivery" },
          { label: "매장방문 회수", value: "shop" },
        ]}
      />
      {method === "delivery" ? (
        <>
          <Text font={fonts.notoSansKRBold} className={classes.label4}>
            반송지
          </Text>
          <Text className={classes.label2}>
            성함
            <Text font={fonts.notoSansKRMedium}>{userReducer.name}</Text>
            <br />
            연락처
            <Text font={fonts.notoSansKRMedium}>
              {phoneFormat(userReducer.phone)}
            </Text>
          </Text>
          <ButtonMini
            onClick={() => {
              history.push("/address-search", {
                to: history.location.pathname,
                state: {
                  ...history.location.state,
                  method,
                },
              });
            }}
            label="주소검색"
            className={classes.bt + " margin"}
          />
          <Text className={classes.label3}>{state?.address}</Text>
          {Boolean(state?.address) && (
            <InputBase
              value={addressDetail}
              onChange={(e) =>
                e.target.value.length < 200 && setAddressDetail(e.target.value)
              }
              placeholder="상세주소를 입력하세요."
              className={classes.input}
            />
          )}
        </>
      ) : (
        <>
          <Text font={fonts.notoSansKRBold} className={classes.label4}>
            방문하실 매장을 선택해 주세요.
          </Text>
          <ButtonMini
            style={{ marginBottom: state.shop ? 0 : 30 }}
            onClick={() => {
              return dispatch(
                toastShow("반송신청은 주문 접수한 매장에서만 가능합니다.")
              );
              /*
              history.push(routes.authFindShop, {
                from: routes.appraiseReturn,
                state: {
                  ...history.location.state,
                  method,
                },
              });*/
            }}
            label="매장 찾기"
            className={classes.bt + " margin"}
          />
          {Boolean(state?.shop) && (
            <ShopItemSimple
              {...state?.shop}
              shopName={state?.shop.name}
              address={state?.shop.address}
              addressDetail={state?.shop.addressDetail}
              shopPhone={state?.shop.phoneNumber}
              distance={state?.shop.distance}
              className="margin"
            />
          )}
        </>
      )}
      {method === "delivery" ? (
        <Text className={classes.label2}>
          제품 반송 시 택배비는 착불로 배송됩니다.
        </Text>
      ) : (
        <Text className={classes.label2}></Text>
      )}
      <div className={classes.buttons}>
        <ButtonBase onClick={history.goBack}>취소</ButtonBase>
        <ButtonBase onClick={onReturn}>반송신청</ButtonBase>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 30px 120px 30px",
    "& button": {
      flex: 1,
      paddingTop: 13,
      fontSize: 16,
      lineHeight: "18px",
      color: "#fff",
      fontFamily: fonts.notoSansKRBold,
      paddingBottom: 13,
      backgroundColor: colors.primary,
      marginLeft: 7,
      "&:first-child": {
        marginLeft: 0,
        marginRight: 7,
        backgroundColor: "#666666",
      },
      borderRadius: 3,
    },
  },
  label1: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  label4: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 16,
  },
  label2: {
    margin: "5px 30px 10px 30px",
    fontSize: "13px",
    color: colors.gray,
    "& span": {
      color: colors.text,
      marginLeft: "20px",
    },
  },
  label3: {
    margin: "20px 30px 10px 30px",
    fontSize: "13px",
    color: colors.text,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
  input: {
    "& input": {
      fontSize: "14px",
      borderBottom: "1.5px solid #333",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    margin: "0px 30px 0px 30px",
  },
  date: {
    margin: "20px 10px 10px 0px",
  },
  date2: {
    margin: "20px 0px 10px 10px",
    flex: 1,
  },
});
