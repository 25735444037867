import { makeStyles, ButtonBase, Menu, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import NumberFormat from "react-number-format";
import InputSmallBorder from "../../components/input-small-border/InputSmallBorder";
import InputMenu from "../../components/input-menu/InputMenu";
import DataButton from "./components/DataButton";
import images from "../../libs/images";

export default function Information({
  x,
  sPrice,
  setstock,
  stock,
  setseloption,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  var options = x.optionValue.split(",");

  // var sPriceData = sPrice*x.gram;
  var sPriceData = x.price;
  var grams = x.gram * stock;
  //var payPrice = (x.fees*stock)+(sPriceData*stock);
  var payPrice = x.fees * stock;
  return (
    <Flex className={classes.root}>
      {/*<Text className={classes.label1}>{x.outsourcing}</Text>*/}
      <Text className={classes.label2} font={fonts.notoSansKRMedium}>
        {" "}
        {x.name}
      </Text>
      <Text className={classes.label3 + " row-between"}>
        {x.goldOrSilver === "GOLD" ? "금" : "실버"}({x.goldOrSilver})
        <Text>
          {x.gram}g ={" "}
          <NumberFormat
            value={sPriceData}
            displayType={"text"}
            thousandSeparator={true}
          />
          원({x.goldOrSilver})
        </Text>
      </Text>
      <Text className={classes.label3 + " row-between"}>
        인출수수료
        <Text>
          <NumberFormat
            value={x.fees}
            displayType={"text"}
            thousandSeparator={true}
          />
          원
        </Text>
      </Text>
      <Flex row className={classes.form}>
        <InputSmallBorder
          placeholder="0 원"
          value={stock}
          number2={true}
          onChange={(e) => setstock(e)}
          label="수량"
        />
        <DataButton
          image={images.plus}
          onClickfunc={setstock}
          funcdata={stock * 1 + 1}
        />
        <DataButton
          image={images.minus}
          onClickfunc={setstock}
          funcdata={stock * 1 - 1 < 1 ? 0 : stock * 1 - 1}
        />
      </Flex>
      {x.options === true && x.optionValue !== "" && (
        <Flex row className={classes.form} style={{ width: "auto" }}>
          <select
            style={{
              minWidth: 100,
              fontSize: 16,
              padding: "10px",
              borderRadius: "3px",
            }}
            onChange={(e) => {
              setseloption(e.target.value);
            }}
          >
            <option value={""}>{"옵션선택"}</option>
            {options.map((x, i) => {
              x.trim();
              return (
                <option key={i.toString()} value={x}>
                  {x}
                </option>
              );
            })}
          </select>
        </Flex>
      )}

      <Text className={classes.label3 + " row-between"}>
        결제중량
        <Text>
          {x.goldOrSilver === "GOLD" ? "금" : "실버"}({x.goldOrSilver})　{" "}
          <NumberFormat
            value={grams}
            displayType={"text"}
            thousandSeparator={true}
          />
          g
        </Text>
      </Text>
      <Text
        font={fonts.notoSansKRMedium}
        className={classes.label3 + " row-between"}
      >
        결제금액
        <Text font={fonts.notoSansKRMedium}>
          <NumberFormat
            value={payPrice}
            displayType={"text"}
            thousandSeparator={true}
          />
          원
        </Text>
      </Text>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    borderBottom: "1px solid #eee",
    padding: "13px",
    paddingBottom: "20px",
  },
  label1: {
    color: colors.gray,
    fontSize: 12,
  },
  label2: {
    color: "#000",
    fontSize: 16,
    marginBottom: 15,
  },
  label3: {
    lineHeight: 1,
    marginTop: "10px",
    alignSelf: "stretch",
    color: "#666",
    fontSize: "14px",
    "& span": {
      color: "#000",
    },
    "&:last-child": {
      color: "#333",
    },
  },
  form: {
    width: "200px",
    margin: "16px 0px 16px auto",
  },
});
