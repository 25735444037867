import { useDispatch, useSelector } from "react-redux";
import React, { useLayoutEffect, useRef, useState } from "react";
import Login from "./pages/login/Login";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { useHistory } from "react-router";
import routes from "./libs/routes";
import Auth from "./hoc/auth";
import Main from "./pages/main/Main";
import Register from "./pages/register/Register";
import Verify from "./pages/verify/Verify";
import VerifyBank from "./pages/verify-bank/VerifyBank";
import SelectBank from "./pages/select-banks/SelectBanks";
import BankPassword from "./pages/bank-password/BankPassword";
import RegisterFinish from "./pages/register-finish/RegisterFinish";
import Trading from "./pages/trading/Trading";
import myTradeHistory from "./pages/trading/myTradeHistory";
import DepositWithdrawal from "./pages/deposit-withdrawal/DepositWithdrawal";
import Deposit from "./pages/deposit/Deposit";
import VirtualOrder from "./pages/virtualOrder/virtualOrder";
import WithDrawal from "./pages/withdrawal/WithDrawal";
import Message from "./redux-components/message/Message";
import Toast from "./redux-components/toast/Toast";
import MyPage from "./pages/mypage/MyPage";
import Action from "./redux-components/action/Action";
import Menu from "./pages/menu/Menu";
import AssetHistory from "./pages/asset-history/AssetHistory";
import WonHistory from "./pages/won-history/WonHistory";
import MyReview from "./pages/myreview/MyReview";
import ReviewDetail from "./pages/review-detail/ReviewDetail";
import Notice from "./pages/notice/Notice";
import Event from "./pages/event/Event";
import Inquiry from "./pages/inquiry/Inquiry";
import Faq from "./pages/faq/Faq";
import Shops from "./pages/shops/Shops";
import ShopDetail from "./pages/shop-detail/ShopDetail";
import Policy from "./pages/policy/Policy";
import Notification from "./pages/notification/Notification";
import EditProfile from "./pages/edit-profile/EditProfile";
import SetAddress from "./pages/set-address/SetAddress";
import Exit from "./pages/exit/Exit";
import ExitVerify from "./pages/exit/ExitVerify";
import ExitReason from "./pages/exit/ExitReason";
import ExitComplete from "./pages/exit/ExitComplete";
import Appraise from "./pages/appraise/Appraise.js";
import AppraiseRegist from "./pages/appraise-regist/AppraiseRegist";
import Exchange from "./pages/exchange/Exchange";
import FindShop from "./pages/find-shop/FindShop";
import PhysicalWithDrawal from "./pages/physical-withdrawal/PhysicalWithRrawal";
import List from "./redux-components/list/List";
import PhysicalWithdrawalPDetail from "./pages/physical-withdrawal-pdetail/PhysicalWithdrawalPDetail";
import ProductInquiryAdd from "./pages/product-inquiry-add/ProductInquiryAdd";
import Order from "./pages/order/Order";
import MyPhysicalWithDrawal from "./pages/my-physical-withdrawal/MyPhysicalWithDrawal";
import OrderDetail from "./pages/order-detail/OrderDetail";
import DirectTransaction from "./pages/direct-transaction/DirectTransaction";
import DirectTransactionPDetail from "./pages/direct-transcation-pdetail/DirectTransactionPDetail";
import ImageViewer from "./redux-components/image/ImageViewer";
import MyDirectTransaction from "./pages/my-direct-transaction/MyDirectTransaction";
import OrderCompleteWrite from "./pages/order-detail/OrderCompleteWrite";
import AppraiseDetail from "./pages/appraise-detail/AppraiseDetail";
import AppraiseDirect from "./pages/appraise-direct/AppraiseDirect";
import { useEffect } from "react";
import LoginNice from "./pages/login/LoginNice";
import DirectTranscationAdd from "./pages/direct-transaction-add/DirectTransactionAdd";
import DirectTranscationAdd2 from "./pages/direct-transaction-add/DirectTransactionAdd2";
import DirectTransactionAdd3 from "./pages/direct-transaction-add/DirectTransactionAdd3";
import DirectTransactionAdd4 from "./pages/direct-transaction-add/DirectTransactionAdd4";
import RegisterExist from "./pages/register-exist/RegisterExist";
import GlobalLoading from "./redux-components/loading/GlobalLoading";
import RegisterShop from "./pages/register-shop/RegisterShop";
import AddressSearch from "./pages/address-search/AddressSearch";
import LoginId from "./pages/login-id/LoginId";
import VerifyBankCode from "./pages/verify-bank/VerifyBankCode";
import SignIn from "./pages/sign/SignIn";
import AuthGateway from "./pages/auth/AuthGateway";
import SignUp from "./pages/sign/SignUp";
import SelfVerification from "./pages/auth/SelfVerification";
import AuthRegisterShop from "./pages/auth/AuthRegisterShop";
import AcceptTerms from "./pages/auth/AcceptTerms";
import AuthRegisterPassword from "./pages/auth/AuthRegisterPassword";
import AuthFindShop from "./pages/auth/AuthFindShop";
import HtmlViewer from "./pages/common/HtmlViewer";
import { signIn } from "./libs/sign";
import { toastShow } from "./redux/toast/ToastActions";
import AuthBankAccount from "./pages/auth/AuthBankAccount";
import WriteReview from "./pages/write-review/WriteReview";
import AppraiseReturn from "./pages/appraise-return/AppraiseReturn";
import AppraiseReviewDetail from "./pages/appraise-review-detail/AppraiseReviewDetail";
import TermsList from "./pages/terms/TermsList";
import AppraiseRegistMain from "./pages/appraise-regist-main/AppraiseRegistMain";
import SignInId from "./pages/sign/SignInId";
import SignUpId from "./pages/sign/SignUpId";
import TradingGuide from "./pages/main/TradingGuide";
import Intro from "./pages/main/Intro";
import { SERVICE_TYPE, STORAGE_KEY } from "./libs/consts";
import Coaching from "./pages/main/Coaching";
import SafeTradeStart from "./pages/safe-trade/SafeTradeStart";
import SafeTradeStep1 from "./pages/safe-trade/SafeTradeStep1";
import SafeTradeStep2 from "./pages/safe-trade/SafeTradeStep2";
import SafeTradeStep3 from "./pages/safe-trade/SafeTradeStep3";
import SafeTradeStep4 from "./pages/safe-trade/SafeTradeStep4";
import CommonFindShop from "./pages/common/CommonFindShop";
import SafeTradeCreateComplete from "./pages/safe-trade/SafeTradeCreateComplete";
import SafeTradeList from "./pages/safe-trade/SafeTradeList";
import SafeTradeItem from "./pages/safe-trade/SafeTradeItem";
import MySafeTradeList from "./pages/safe-trade/MySafeTradeList";
import MySafeTradeSell from "./pages/safe-trade/sell/MySafeTradeSell";
import MySafeTradeSellDetail from "./pages/safe-trade/sell/MySafeTradeSellDetail";
import MySafeTradeSellReturn from "./pages/safe-trade/sell/MySafeTradeSellReturn";
import SafeTradeBuy from "./pages/safe-trade/SafeTradeBuy";
import MySafeTradeBuy from "./pages/safe-trade/buy/MySafeTradeBuy";
import PasswordSecurity from "./pages/common/PasswordSecurity";
import SafeTradeQna from "./pages/safe-trade/SafeTradeQna";

const InitComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useSelector((s) => s);
  const { isSigned } = auth;

  const originSigned = useRef(isSigned);

  useLayoutEffect(() => {
    // App이 아닌 Web Browser에서 접근할 경우 금방금방.kr 로 리다이렉트
    if (
      !navigator.userAgent.includes("kumbang -") &&
      SERVICE_TYPE === "PRODUCTION"
    ) {
      window.location.href = "https://금방금방.kr/";
    }
  }, []);

  useEffect(() => {
    signIn();
  }, []);

  useEffect(() => {
    if (originSigned.current && !isSigned) {
      // 로그아웃 이벤트 발생

      history.push(routes.main);
      dispatch({ type: "clear" });
      dispatch(toastShow("로그아웃 되었습니다."));
    }

    originSigned.current = isSigned;
  }, [isSigned]);

  return null;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const getIntroVisible = () => {
  return localStorage.getItem(STORAGE_KEY.FIRST_LOAD);
};

const getCoachingVisible = () => {
  return localStorage.getItem(STORAGE_KEY.NO_COACHING);
};
function App() {
  const [introVisible, setIntroVisible] = useState(!getIntroVisible());
  const [coachingVisible, setCoachingVisible] = useState(!getCoachingVisible());
  return (
    <Router>
      <ScrollToTop />
      <InitComponent />
      {introVisible ? (
        <Intro setIntroVisible={setIntroVisible} />
      ) : coachingVisible ? (
        <Coaching setCoachingVisible={setCoachingVisible} />
      ) : (
        <Switch>
          <Route exact path={routes.deposit} component={Deposit} />
          <Route
            exact
            path={routes.virtualOrder}
            component={Auth(VirtualOrder, routes.virtualOrder)}
          />

          <Route
            exact
            path={routes.registerFinish}
            component={RegisterFinish}
          />
          <Route
            exact
            path={routes.depositWithdrawal}
            component={Auth(DepositWithdrawal, routes.depositWithdrawal)}
          />
          <Route
            exact
            path={routes.physicalWithDrawal}
            component={PhysicalWithDrawal}
          />
          <Route
            exact
            path={"/directTransaction/:id"}
            component={DirectTransactionPDetail}
          />
          <Route
            exact
            path={"/directtransactionadd"}
            component={DirectTranscationAdd}
          />

          <Route
            exact
            path={"/directtransactionadd2"}
            component={DirectTranscationAdd2}
          />

          <Route
            exact
            path={"/directtransactionadd3"}
            component={DirectTransactionAdd3}
          />

          <Route
            exact
            path={"/directtransactionadd4"}
            component={DirectTransactionAdd4}
          />

          <Route
            exact
            path={routes.physicalWithDrawalPDetail}
            component={Auth(PhysicalWithdrawalPDetail)}
          />

          {
            //ㅡㅡㅡㅡㅡㅡㅡ감정평가 관련 라우트 시작--------------//
          }

          {
            //감정평가 교환 리뷰 완료 페이지
          }
          <Route
            exact
            path={routes.appraiseReviewDetail}
            component={Auth(AppraiseReviewDetail, routes.appraiseReviewDetail)}
          />

          {
            //감정평가 대시보드
          }
          <Route
            exact
            path={routes.appraise}
            component={Auth(Appraise, routes.appraise)}
          />
          {
            //감정평가 등록
          }
          <Route
            exact
            path={routes.appraiseRegist}
            component={Auth(AppraiseRegist, routes.appraiseRegist)}
          />
          <Route
            exact
            path={routes.appraiseRegistMain}
            component={Auth(AppraiseRegistMain)}
          />
          {
            //현장 감정 접수하기
          }
          <Route
            exact
            path={routes.appraiseDirect}
            component={Auth(AppraiseDirect, routes.appraiseDirect)}
          />
          {
            //감정 주문 상세
          }
          <Route
            exact
            path={routes.appraiseDetail}
            component={Auth(AppraiseDetail, routes.appraiseDetail)}
          />
          {
            //감정 주문 접수
          }
          <Route
            exact
            path={routes.exchange}
            component={Auth(Exchange, routes.exchange)}
          />

          {
            //감정 교환 후기 작성
          }
          <Route
            exact
            path={routes.writeReview}
            component={Auth(WriteReview, routes.writeReview)}
          />

          {
            //감정 돌려받기 로직
          }
          <Route
            exact
            path={routes.appraiseReturn}
            component={Auth(AppraiseReturn, routes.appraiseReturn)}
          />

          {
            //ㅡㅡㅡㅡㅡㅡㅡ감정평가 관련 라우트 끝 --------------//
          }

          <Route exact path={"/shops/:shopId"} component={ShopDetail} />
          <Route exact path={"/notices/:noticeId"} component={Notice} />
          <Route exact path={"/events/:eventId"} component={Event} />
          <Route
            exact
            path={routes.editProfile}
            component={Auth(EditProfile, routes.editProfile)}
          />
          <Route exact path={routes.exitComplete} component={ExitComplete} />
          <Route exact path={routes.exitReason} component={ExitReason} />
          <Route exact path={routes.exitVerify} component={ExitVerify} />
          <Route exact path={routes.faq} component={Faq} />
          <Route exact path={"/address-search"} component={AddressSearch} />
          <Route exact path={routes.exit} component={Exit} />
          <Route
            exact
            path={routes.orderCompleteWrite}
            component={OrderCompleteWrite}
          />
          <Route exact path={routes.inquiry} component={Inquiry} />
          <Route
            exact
            path={routes.myDirectTransaction}
            component={MyDirectTransaction}
          />

          <Route exact path={routes.setAddress} component={SetAddress} />
          <Route exact path={routes.policy} component={Policy} />
          <Route exact path={routes.notification} component={Notification} />
          <Route
            exact
            path={routes.myPhysicalWithDrawal}
            component={MyPhysicalWithDrawal}
          />
          <Route
            exact
            path={routes.directTransaction}
            component={DirectTransaction}
          />
          <Route
            exact
            path={"/orders/:orderId/:orderNo/:goodId"}
            component={OrderDetail}
          />
          <Route exact path={"/loginid"} component={LoginId} />
          <Route exact path={routes.findShop} component={FindShop} />
          <Route exact path={"/reviews/:reviewId"} component={ReviewDetail} />
          <Route exact path={routes.shops} component={Shops} />
          <Route
            exact
            path={routes.withdrawal}
            component={Auth(WithDrawal, routes.withdrawal)}
          />
          <Route
            exact
            path={routes.order}
            component={Auth(Order, routes.order)}
          />
          <Route
            exact
            path={routes.trading}
            component={Auth(Trading, routes.trading)}
          />
          <Route
            exact
            path={routes.mytradehistory}
            component={Auth(myTradeHistory, routes.mytradehistory)}
          />
          <Route
            exact
            path={routes.productInquiryAdd}
            component={ProductInquiryAdd}
          />
          <Route exact path={routes.wonHistory} component={WonHistory} />
          <Route exact path={routes.assetHistory} component={AssetHistory} />
          <Route
            exact
            path={routes.mypage}
            component={Auth(MyPage, routes.mypage)}
          />
          <Route exact path={routes.myreview} component={MyReview} />
          <Route exact path={routes.bankPassword} component={BankPassword} />
          <Route exact path={routes.selectBank} component={SelectBank} />
          <Route exact path={routes.verifyBank} component={VerifyBank} />
          <Route exact path={"/verify-bank-code"} component={VerifyBankCode} />

          <Route exact path={routes.verify + "/nice/1"} component={Verify} />

          <Route exact path={routes.verify + "/nice/0"} component={Verify} />
          <Route exact path={routes.verify} component={Verify} />

          <Route exact path={"/registerexist"} component={RegisterExist} />
          <Route exact path={"/register-shop"} component={RegisterShop} />
          <Route exact path={routes.register} component={Register} />
          <Route
            exact
            path={routes.login + "/callback/naver"}
            component={Login}
          />
          <Route
            exact
            path={routes.login + "/callback/google"}
            component={Login}
          />
          <Route
            exact
            path={routes.login + "/callback/kakaotalk"}
            component={Login}
          />

          <Route
            exact
            path={routes.login + "/callback/apple"}
            component={Login}
          />
          <Route
            exact
            path={routes.login + "/check/nice/:state"}
            component={LoginNice}
          />

          {/* <Route exact path={routes.login + "/check"} component={LoginCheck} />
        <Route exact path={routes.login} component={Auth(Login)} /> */}
          <Route
            exact
            path={routes.login + "/check"}
            component={Auth(SignIn)}
          />
          <Route exact path={routes.login} component={Auth(SignIn)} />

          <Route exact path={routes.signIn} component={Auth(SignIn)} />
          <Route exact path={routes.signUp} component={Auth(SignUp)} />
          <Route exact path={routes.signInId} component={Auth(SignInId)} />
          <Route exact path={routes.signUpId} component={Auth(SignUpId)} />
          <Route
            exact
            path={routes.authGateway}
            component={Auth(AuthGateway)}
          />
          <Route exact path={routes.menu} component={Auth(Menu)} />
          <Route exact path={routes.main} component={Auth(Main)} />
          <Route
            exact
            path={routes.selfVerification}
            component={SelfVerification}
          />
          <Route
            exact
            path={routes.authRegisterShop}
            component={AuthRegisterShop}
          />
          <Route exact path={routes.acceptTerms} component={AcceptTerms} />
          <Route
            exact
            path={routes.authRegisterPassword}
            component={AuthRegisterPassword}
          />
          <Route exact path={routes.authFindShop} component={AuthFindShop} />
          <Route
            exact
            path={routes.authBankAccount}
            component={Auth(AuthBankAccount, routes.authBankAccount)}
          />

          <Route exact path={routes.termsList} component={TermsList} />
          <Route exact path={routes.noticeList} component={Notice} />
          <Route exact path={routes.eventList} component={Event} />
          <Route exact path={routes.htmlViewer} component={HtmlViewer} />
          <Route
            exact
            path={routes.tradingGuide}
            component={Auth(TradingGuide)}
          />

          {/* 공통 */}
          <Route
            exact
            path={routes.commonFindShop}
            component={CommonFindShop}
          />
          <Route
            exact
            path={routes.commonPasswordSecurity}
            component={PasswordSecurity}
          />

          {/* 안심직거래 */}
          <Route
            exact
            path={routes.safeTradeStart}
            component={Auth(SafeTradeStart)}
          />
          <Route
            exact
            path={routes.safeTradeStep1}
            component={Auth(SafeTradeStep1)}
          />
          <Route
            exact
            path={routes.safeTradeStep2}
            component={Auth(SafeTradeStep2)}
          />
          <Route
            exact
            path={routes.safeTradeStep3}
            component={Auth(SafeTradeStep3)}
          />
          <Route
            exact
            path={routes.safeTradeStep4}
            component={Auth(SafeTradeStep4)}
          />
          <Route
            exact
            path={routes.safeTradeCreateComplete}
            component={Auth(SafeTradeCreateComplete)}
          />
          <Route
            exact
            path={routes.safeTradeList}
            component={Auth(SafeTradeList)}
          />
          <Route
            exact
            path={routes.safeTradeItem}
            component={Auth(SafeTradeItem)}
          />
          <Route
            exact
            path={routes.safeTradeBuy}
            component={Auth(SafeTradeBuy)}
          />
          <Route
            exact
            path={routes.safeTradeQna}
            component={Auth(SafeTradeQna)}
          />
          <Route
            exact
            path={routes.mySafeTradeList}
            component={Auth(MySafeTradeList)}
          />
          <Route
            exact
            path={routes.mySafeTradeSell}
            component={Auth(MySafeTradeSell)}
          />
          <Route
            exact
            path={routes.mySafeTradeSellDetail}
            component={Auth(MySafeTradeSellDetail)}
          />
          <Route
            exact
            path={routes.mySafeTradeSellReturn}
            component={Auth(MySafeTradeSellReturn)}
          />
          <Route
            exact
            path={routes.mySafeTradeBuy}
            component={Auth(MySafeTradeBuy)}
          />
          <Redirect to={routes.main} />
        </Switch>
      )}

      <ImageViewer />
      <Toast />
      <List />
      <Action />
      <Message />
      <GlobalLoading />
    </Router>
  );
}

export default App;
