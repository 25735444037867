import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Checkbox from "../../components/checkbox/Checkbox";
import Flex from "../../components/flex/Flex";
import InputShadow from "../../components/input-shadow/InputShadow";
import PurityPopup from "../../components/purity-popup/PurityPopup";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";

export default function AppraiseRegist2({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [gram, setGram] = useState("");
  const [quantity, setQuantity] = useState("");
  const [popup, setPopup] = useState(false);
  const [purity, setPurity] = useState("3");
  const { appraisalItem } = history.location.state || {};

  useEffect(() => {
    if (!appraisalItem) {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    const { gram, quantity, purity } = history.location.state || {};
    if (gram) {
      setGram(gram);
    }
    if (quantity) {
      setQuantity(quantity);
    }
    if (purity) {
      setPurity(purity);
    }
  }, [history.location.state]);

  const next = () => {
    try {
      if (gram === "0" || gram === "") {
        throw "중량을 입력해 주세요.";
      } else if (quantity === "0" || quantity === "") {
        throw "수량을 입력해 주세요.";
      }
    } catch (e) {
      alert(e.message || e);
      return;
    }
    history.replace(routes.appraiseRegist, {
      ...history.location.state,
      gram,
      quantity,
      purity,
    });
    history.push(routes.appraiseRegist, {
      ...history.location.state,
      gram,
      quantity,
      purity,
      step: 3,
    });
  };

  if (!appraisalItem) {
    return null;
  }

  const { type } = appraisalItem;

  return (
    <Flex>
      <Text className={classes.title} font={fonts.notoSansKRBold}>
        제품의 순도와 중량을
        <br />
        선택해 주세요
      </Text>
      <Text className={classes.message}>
        <ButtonBase
          onClick={() => {
            setPopup(true);
          }}
          style={{
            fontFamily: fonts.notoSansKRMedium,
          }}
        >
          제품 순도 및 중량 확인 방법 〉
        </ButtonBase>
      </Text>

      <Checkbox
        className={classes.cb}
        checked
        disabled
        label="순도를 선택하세요."
      />

      <Flex row className={classes.buttons}>
        <span
          onClick={() => {
            setPurity("3");
          }}
          className={(purity === "3" && classes.bl) + " shadow"}
        >
          {type === "GOLD" ? "24K(999)" : "999이상"}
        </span>
        <span
          onClick={() => {
            setPurity("2");
          }}
          className={(purity === "2" && classes.bl) + " shadow"}
        >
          {type === "GOLD" ? "18K(750)" : "925"}
        </span>
        <span
          onClick={() => {
            setPurity("1");
          }}
          className={(purity === "1" && classes.bl) + " shadow"}
        >
          {type === "GOLD" ? "14K(585)" : "기타"}
        </span>
      </Flex>
      <Checkbox
        className={classes.cb}
        checked
        disabled
        label="중량을 입력해 주세요."
      />
      <InputShadow
        value={gram}
        onChange={(t) => {
          if (isNaN(t)) {
            return;
          }
          if (t) {
            if (t.includes(".") && t.split(".").pop().length >= 3) {
              return;
            } else if (!t.includes(".") && t.length > 11) {
              return;
            }
          }
          setGram(t);
        }}
        number
        className={classes.input}
        unit="그램(g)"
        placeholder="숫자를 입력해 주세요. Ex)5.62그램(g)"
        message="중량을 모르실 경우 알고 계신 중량을 입력해 주세요."
      />
      <Checkbox
        className={classes.cb}
        checked
        disabled
        label="수량을 입력해 주세요."
      />
      <InputShadow
        unit="개"
        value={quantity}
        onChange={(t) => {
          if (isNaN(t)) {
            return;
          }
          if (t) {
            if (t.includes(".")) {
              return;
            } else if (!t.includes(".") && t.length > 11) {
              return;
            }
          }
          setQuantity(t);
        }}
        number
        className={classes.input}
        placeholder="숫자를 입력해 주세요. "
        message={`ㆍ수량은 낱개 1개를 기준으로 입력해 주세요. 예) 귀걸이 1쌍은 2개 입력\nㆍ금가락지와 같이 동일한 순도 및 무게를 가지는 제품은 수량만큼 
        입력해 주세요.`}
      />
      <div style={{ height: "50vh" }} />
      <Button
        disabled={!gram || !quantity}
        onClick={next}
        label="다음"
        className={classes.button}
      />
      {popup && (
        <PurityPopup
          type={appraisalItem.type}
          url={appraisalItem.guideUrl}
          onClose={() => {
            setPopup(false);
          }}
        />
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  buttons: {
    alignSelf: "stretch",
    margin: "0px 20px",
    "& span": {
      flex: 1,
      textAlign: "center",
      display: "flex",
      lineHeight: 1,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      fontSize: "14px",
      color: colors.text,
      marginRight: "10px",
      "&:last-child": {
        marginRight: "0px",
      },
      borderRadius: "20px",
      padding: "10px 20px",
    },
  },
  bl: {
    backgroundColor: colors.primary,
    color: "#fff !important",
  },
  title: {
    margin: "35px 30px 0px 30px",
    fontSize: "20px",
    color: colors.text,
  },
  message: {
    borderBottom: "1px solid " + colors.primary,
    alignSelf: "flex-start",
    margin: "5px 30px 30px 30px",
    color: colors.primary,
    fontSize: "14px",
  },
  list: {
    paddingLeft: "10px",
    overflowX: "auto",
  },
  item: {
    width: "30%",
    padding: "20px",
    borderRadius: "5px",
    marginLeft: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginBottom: "30px",
    flexDirection: "column",
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  button: {
    zIndex: 2,
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    borderRadius: "0px",
    width: "100%",
    alignSelf: "stretch",
  },
  image: {
    backgroundColor: "#ddd",
    width: 70,
    height: 70,
    borderRadius: "100px",
  },
  label: {
    marginTop: 25,
    fontSize: 16,
    color: "#000",
  },
  label2: {
    fontSize: "14px",
    coloR: colors.text,
    alignSelf: "center",
  },
  cb: {
    margin: "30px 30px 10px 30px",
  },
  input: {
    margin: "0px 30px",
    "&:last-child": {
      marginBottom: "50px",
    },
  },
});
