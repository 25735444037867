import { ButtonBase, Divider, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import ProductListItem from "../../components/product-list/ProductListItem";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { actionShow } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import { formatTime, objToQueryStr, phoneFormat } from "../../services/utils";
import Cancel from "./Cancel";
import CompleteCard from "./CompleteCard";
import DeliveryState from "./DeliveryState";

export default function AppraiseDetail({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState();
  const state = history.location.state || {};

  useEffect(() => {
    dispatch(loadingStart);
    fetchAppriaisalOrder();
  }, [state, history.location.key]);

  const fetchAppriaisalOrder = () => {
    const { id } = state;

    dispatch(
      axiosDispatch(
        {
          method: "get",
          url:
            consts.apiBaseUrl + "/api/v1/user/market/appraisal/Requests/" + id,
        },
        (err, res) => {
          dispatch(loadingEnd);
          if (err) {
            dispatch(messageError(err));
          } else {
            setData(res.data.data);
          }
        }
      )
    );
  };

  const requestCancel = () => {
    dispatch(
      axiosDispatch(
        {
          method: "delete",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/appraisal/Requests/" +
            data.id,
        },
        (err, res) => {
          if (err) {
            dispatch(messageError(err));
          } else {
            dispatch(toastShow("신청 취소되었습니다."));
            history.goBack();
          }
        }
      )
    );
  };

  let returnProcess =
    data?.appraisalRequestStatus?.filter((x) => x.status.includes("반송"))
      .length > 0;

  if (!data) {
    return (
      <Flex className={classes.root}>
        <Flex
          row
          className="row-between"
          style={{
            margin: "16px 0px",
          }}
        >
          <div />
          <ButtonBase onClick={history.goBack} className={classes.close}>
            <img src={images.close} />
          </ButtonBase>
        </Flex>
      </Flex>
    );
  }

  const { appraiseType = "" } =
    data.appraisalRequestStatus.find((x) => x.status === "감정완료") || {};

  return (
    <Flex key={JSON.stringify(data)} className={classes.root}>
      <Flex row className="row-between">
        {(() => {
          //현장감정,내방,택배
          switch (data.status) {
            case "접수":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  신청하신 제품이 접수되었습니다.
                  <br />
                  관리자 승인 검토 중입니다.
                </Text>
              );
            case "접수승인":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  접수 승인 되었습니다.
                  <br />
                  제품이 배송중입니다.
                </Text>
              );
            case "감정시작":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  감정사가 감정중입니다.
                </Text>
              );
            case "감정반려":
            case "접수반려":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  신청 접수가 반려되었습니다.
                </Text>
              );
            case "감정완료":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  제품이 감정 완료되었습니다.
                  <br />
                  최종 감정가를 확인해 주세요.
                </Text>
              );
            case "교환완료":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  {appraiseType === "SILVER" ? "은(SILVER)" : "금(GOLD)"}으로
                  교환 완료 되었습니다.
                  <br />
                  입/출금 메뉴에서 확인하실 수 있습니다.
                </Text>
              );
            case "반송신청":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  반송신청 접수되었습니다.
                </Text>
              );
            case "반송완료":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  반송 완료되었습니다.
                </Text>
              );
            case "반송반려":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  반송 신청이 반려되었습니다.
                </Text>
              );
          }
        })()}
        <ButtonBase onClick={history.goBack} className={classes.close}>
          <img src={images.close} />
        </ButtonBase>
      </Flex>

      <CompleteCard navigate={navigate} {...data} index={data.index} />

      <Flex row className={classes.card + " shadow row-between"}>
        <Flex>
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            {data.number}
          </Text>
          <Text className={classes.label2}>
            {formatTime(
              data.appraisalRequestStatus[0]?.createdAt,
              "YYYY.MM.DD"
            )}
          </Text>
        </Flex>

        {data.appraisalRequestStatus[data.appraisalRequestStatus?.length - 1]
          ?.status === "접수" && (
          <ButtonBase
            onClick={() => {
              dispatch(
                actionShow(
                  "신청을 취소하시겠습니까?",
                  ["닫기", "신청취소"],
                  [null, requestCancel]
                )
              );
            }}
            className={classes.button}
          >
            신청취소
          </ButtonBase>
        )}
      </Flex>

      {data.status.includes("반려") && <Cancel {...data} />}

      <Flex row>
        <DeliveryState {...data} />

        <Flex className={classes.deliveryDesc}>
          {data.method !== "택배" ? null : (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "접수"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          )}
          {data.appraisalRequestStatus.filter(
            (x) => x.status === (data.method !== "택배" ? "접수" : "접수승인")
          ).length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) =>
                    x.status === (data.method !== "택배" ? "접수" : "접수승인")
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )}
          {data.appraisalRequestStatus.filter((x) => x.status === "감정시작")
            .length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "감정시작"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )}
          {data.appraisalRequestStatus.filter((x) => x.status === "감정완료")
            .length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "감정완료"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )}
          {data.appraisalRequestStatus.filter((x) => x.status === "교환완료")
            .length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "교환완료"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )}
        </Flex>
      </Flex>

      <Divider style={{ height: 3, marginTop: 25 }} />
      {data.method === "현장감정" ? (
        ""
      ) : (
        <>
          <Text className={classes.label3}>
            <Text font={fonts.notoSansKRMedium}>
              {data.appraisalSimples.length}
            </Text>
            개의 제품 교환
          </Text>
          <Divider style={{ margin: "13px" }} />
          {data.appraisalSimples.map((x, i) => {
            return (
              <ProductListItem
                {...x}
                bottom={i + 1 === data.appraisalSimples.length}
                key={i.toString()}
              />
            );
          })}
          <Divider style={{ height: 3, marginTop: 20 }} />
        </>
      )}

      <Flex
        style={{
          marginBottom: data.method === "현장감정" ? 20 : 30,
        }}
        row
        className={classes.bottomHeader + " row-between"}
      >
        <Flex>
          <Text className={classes.label5}>
            제품 접수 정보
            {data.method === "현장감정" ? null : (
              <>
                <br />
                <Text font={fonts.notoSansKRMedium} style={{ lineHeight: 1 }}>
                  {data.method === "내방"
                    ? "직접 내방 접수 예약하셨습니다."
                    : "택배 접수(수거) 예약하셨습니다."}
                  {data.method === "내방" ? (
                    <Text
                      style={{ color: colors.textRed, fontSize: 12 }}
                      font={fonts.notoSansKRMedium}
                    >
                      <br />
                      매장 방문시 본인 외에는 접수가 불가합니다.
                    </Text>
                  ) : (
                    ""
                  )}
                </Text>
              </>
            )}
          </Text>
        </Flex>

        {/*
            {data.method === "현장감정" ? null : data.method === "택배" &&
          data.appraisalRequestStatus.filter((x) => x.status === "receiving").length === 0 ? (
          <ButtonBase className={classes.button2}>정보 변경</ButtonBase>
        ) : null}
          */}
      </Flex>

      <Flex
        className={classes.card + " shadow"}
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <Flex row className={classes.simpleTable}>
          <Text>성함</Text>
          <Text>{data.user.name}</Text>
        </Flex>
        <Flex row className={classes.simpleTable}>
          <Text>연락처</Text>
          <Text>{phoneFormat(data.user.phone)}</Text>
        </Flex>
        {data.method === "택배" ? (
          <Flex row className={classes.simpleTable}>
            <Text>주소</Text>
            <Text>{data.collectAddress}</Text>
          </Flex>
        ) : (
          <Flex row className={classes.simpleTable}>
            <Text>{returnProcess ? "반송매장" : "방문매장"}</Text>
            <Text>
              <Text font={fonts.notoSansKRMedium} style={{ color: "black" }}>
                {data.shop.name}
              </Text>
              <br />
              <Text style={{ fontSize: 12 }}>
                {data.shop.address} {data.shop.addressDetail}
              </Text>
            </Text>
          </Flex>
        )}
        {data.method !== "현장감정" ? (
          <Flex row className={classes.simpleTable}>
            <Text>
              {data.method === "택배" ? "수거예약일시" : "방문예약일시"}
            </Text>
            {data.method === "택배" ? (
              <Text font={fonts.notoSansKRBold}>
                {data.collectDate}, {data.collectTime}
              </Text>
            ) : (
              <Text font={fonts.notoSansKRBold}>
                {data.visitDate}, {data.visitTime}
              </Text>
            )}
          </Flex>
        ) : (
          ""
        )}
      </Flex>

      <Text font={fonts.notoSansKRMedium} className={classes.message}>
        <img
          alt="w"
          src={images.danger}
          style={{
            width: 12,
            height: 12,
            marginRight: "6px",
            objectFit: "contain",
          }}
        />
        확인사항
      </Text>

      {data.method === "택배" ? (
        <>
          <Text className={classes.desc} style={{ marginTop: "5px" }}>
            <Text font={fonts.notoSansKRMedium}>
              ㆍ꼭 박스포장을 해주셔야 수거가 가능합니다.
            </Text>
            <br />
            ㆍ박스 포장이 되지 않은 경우 제품 수거가 거절될 수 있습니다.
            <br />
            ㆍ택배수거일은 택배사 사정에 따라 달라질 수 있습니다.
            <br />
            ㆍ상세감정 결과 총 중량이 1돈(3.75g) 이하일 경우, 교환 금(중량)에서
            택배비가 차감됩니다.
          </Text>
        </>
      ) : (
        <>
          <Text className={classes.desc} style={{ marginTop: "5px" }}>
            <Text font={fonts.notoSansKRMedium}>
              ㆍ매장 방문 시 보증서가 있는 경우 제품과 함께 가져 오셔야 합니다.
            </Text>
          </Text>
        </>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  bottomHeader: {
    margin: "25px 13px 30px 13px",
  },
  desc: {
    color: "#666",
    fontSize: "13px",
    margin: "20px 30px 100px 30px",
    "& span": { color: colors.textRed },
  },
  message: {
    color: "#333",
    fontSize: "14px",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    margin: "30px 30px 0px 30px",
  },
  label3: {
    marginTop: 26,
    margin: "0px 13px",
    fontSize: 14,
    color: "#333",
  },
  root: {
    flexGrow: 1,
    backgroundColor: colors.white,
  },
  close: {
    alignSelf: "flex-start",
    padding: "16px 16px",
    "& img": { width: "16px", height: "16px", objectFit: "contain" },
  },
  title: {
    fontSize: 16,
    color: "#333",
    lineHeight: 1.3,
    margin: "28px 0px 28px 13px",
  },
  card: {
    margin: "0px 13px 20px 13px",
    padding: "16px 20px",
  },
  label1: { fontSize: 18, color: "#333" },
  label2: {
    color: "#666",
    fontSize: 12,
  },
  button: {
    padding: "9px 16px",
    fontSize: 14,
    color: "#fff",
    lineHeight: 1,
    fontFamily: fonts.notoSansKRMedium,
    backgroundColor: colors.text,
    borderRadius: "20px",
  },
  deliveryDesc: {
    justifyContent: "space-between",
    marginRight: 25,
    flex: 1,
    alignItems: "flex-end",
  },
  deliveryLabel1: {
    fontSize: "12px",
    color: "#666",
  },
  deliveryLabel2: {
    fontSize: "12px",
    color: "#333",
  },
  button2: {
    padding: "9px 16px",
    fontSize: 14,
    color: colors.primary,
    lineHeight: 1,
    fontFamily: fonts.notoSansKRMedium,
    border: "1px solid " + colors.primary,
    borderRadius: "20px",
  },
  label5: {
    fontSize: 14,
    color: "#666",
    "& span": { fontSize: 16, color: "#333" },
  },
  simpleTable: {
    alignSelf: "stretch",
    margin: "10px 30px 0px 30px",
    "& span": {
      flex: 5,
      "&:first-child": {
        flex: 2,
        color: "#666",
      },
      fontSize: "14px",
      color: "#333",
    },
  },
});
