import { ButtonBase, Input, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import AppraiseItem from "../../components/appraise-item/AppraiseItem";
import ButtonMini from "../../components/button-mini/ButtonMini";
import ButtonRadio from "../../components/button-radio/ButtonRadio";
import Button from "../../components/button/Button";
import DateSelector from "../../components/date-selector/DateSelector";
import Flex from "../../components/flex/Flex";
import ProductList from "../../components/product-list/ProductList";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import TimeRangeSelectorNew from "../../components/time-range-selector/TimeRangeSelectorNew";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import PaymentPopup from "./PaymentPopup";
import consts from "../../libs/consts";
import { calcDate, formatTime, numFormat } from "../../libs/utils";
import { axiosDispatch } from "../../services/network";
import { actionError } from "../../redux/action/ActionActions";
import PhysicalWithDrawalItemOrder from "../../components/physical-withdrawal-item/PhysicalWithDrawalItemOrder";
import { NoEncryption } from "@material-ui/icons";
import * as APIS from "../../libs/apis";

export default function Order({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [v1, setV1] = useState("2");
  const [payment, setPayment] = useState(false);
  const { id, name, phone } = useSelector((s) => s.user);
  const { userId } = useSelector((s) => s.auth);
  const [mygoldorsilver, setmygoldorsilver] = useState(0);
  const [mysavePrice, setmysavePrice] = useState(0);
  const [mysaveformatPrice, setmysaveformatPrice] = useState("");

  const state = history.location.state;
  const [ChangeDate, setChangeDate] = useState(
    formatTime(calcDate("D", state.detail.days || 0))
  );
  const [ChangeTime, setChangeTime] = useState("");

  if (!state.type) {
    return <Redirect to={routes.main} />;
  }

  var x = state.detail;
  useEffect(() => {
    APIS.getAvailableAmount().then(({ data: { data } }) => {
      setmysavePrice(data["krw"]);
      var fotmatPrice = numFormat(data["krw"]);
      setmysaveformatPrice(fotmatPrice);
      if (x.goldOrSilver === "GOLD") {
        setmygoldorsilver(data["gold"]);
      } else {
        setmygoldorsilver(data["silver"]);
      }
    });
  }, []);

  return (
    <BasicLayout stackTopbar={{ title: "주문 상세", border: true }}>
      {(payment || state?.payment) && (
        <PaymentPopup
          type={"direct-transaction-order"}
          onClose={() => {
            setPayment(false);
          }}
          rows={x}
          sPrice={state.sPrice}
          settype={state.settype}
          stock={state.stock}
          seloption={state.seloption}
          mysavePrice={mysavePrice}
          mysaveformatPrice={mysaveformatPrice}
          mygoldorsilver={mygoldorsilver}
          ChangeDate={ChangeDate}
          ChangeTime={ChangeTime}
          shop={state.shop}
        />
      )}
      <ProductList
        productType={state?.type}
        headerComponent={(() => {
          switch (state.type) {
            case "direct-transaction":
              return (
                <Flex row className="row-between">
                  <Text
                    font={fonts.notoSansKRMedium}
                    className={classes.headerLabel2}
                  >
                    해당 상품은 보유 원화(원)로 만 결제 가능합니다.
                  </Text>
                </Flex>
              );
            default:
              return (
                <Flex row className="row-between">
                  <Text
                    font={fonts.notoSansKRMedium}
                    className={classes.headerLabel1}
                  >
                    해당 상품은 보유 원화(원)로 만
                    <br /> 결제 가능합니다.
                  </Text>
                  <Flex>
                    <Flex row className={classes.simpleTable}>
                      <Text>
                        보유 {x.goldOrSilver === "GOLD" ? "금" : "실버"}(
                        {x.goldOrSilver})
                      </Text>
                      <Text font={fonts.notoSansKRMedium}>
                        {numFormat(mygoldorsilver)}g
                      </Text>
                    </Flex>
                    <Flex row className={classes.simpleTable}>
                      <Text>보유 원화(원)</Text>
                      <Text>{mysaveformatPrice}원</Text>
                    </Flex>
                  </Flex>
                </Flex>
              );
          }
        })()}
      />
      <PhysicalWithDrawalItemOrder
        rows={x}
        sPrice={state.sPrice}
        settype={state.settype}
        stock={state.stock}
        seloption={state.seloption}
      />
      {/* <Text font={fonts.notoSansKRMedium} className={classes.label1}>
        물품 수령 방법을 선택해 주세요.
      </Text>
      <Text className={classes.label2}>
        원하시는 주소로 방문 등기소포를 예약하시거나 가까운 매장으로 방문하여
        물품을 수령하실 수 있습니다.
      </Text> */}
      {/*
      <ButtonRadio
        onChange={setV1}
        value={v1}
        className="margin"
        data={[
          { label: "배송요청", value: "1" },
          { label: "직접 내방 수령", value: "2" },
        ]}
      />
      */}
      {v1 === "1" ? (
        <>
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            수령하실 주소를 입력해 주세요.
          </Text>
          <Text className={classes.label2}>
            성함 <Text font={fonts.notoSansKRMedium}>{name}</Text>
            <br />
            연락처 <Text font={fonts.notoSansKRMedium}>{phone}</Text>
          </Text>
          <ButtonMini label="주소검색" className={classes.bt + " margin"} />
          <Text className={classes.label3}>
            서울시 강남구 테헤란로 12길 232
          </Text>
          <Input
            placeholder="상세주소를 입력하세요."
            className={classes.input}
            onChange={(e) => {
              // console.log(e.target.value);
            }}
          />
        </>
      ) : (
        <>
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            제품 수령을 위해
            <br />
            방문하실 매장을 선택해 주세요.
          </Text>
          <ButtonMini
            onClick={() => {
              history.push(routes.authFindShop, {
                from: routes.order,
                type: "physical-withdrawal",
                detail: x,
                id: state.id,
                sPrice: state.sPrice,
                settype: state.settype,
                stock: state.stock,
                seloption: state.seloption,
              });
            }}
            label="매장 찾기"
            className={classes.bt + " margin"}
          />
          {state.shop && <ShopItemSimple {...state.shop} className="margin" />}
        </>
      )}
      {v1 === "2" && (
        <>
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            {v1 === "1"
              ? "희망하시는 택배수거 일자를 선택하세요."
              : "희망하시는 매장 방문 일자를 선택하세요."}
          </Text>

          <Flex row className="margin">
            <DateSelector
              className={classes.date}
              onChange={(v) => setChangeDate(v)}
              value={ChangeDate}
            />
            <TimeRangeSelectorNew
              className={classes.date2}
              onChange={(v) => setChangeTime(v)}
            />
          </Flex>
        </>
      )}

      <div style={{ height: "100px" }} />
      <Button
        onClick={() => {
          if (!state.shop) {
            dispatch(actionError("방문 매장을 선택해주세요."));
            return;
          }
          if (ChangeDate === "") {
            dispatch(actionError("방문일자를 선택 또는 입력해주세요"));
            return;
          }
          if (ChangeTime === "") {
            dispatch(actionError("방문 시간을 선택해주세요."));
            return;
          }

          var date = new Date();
          date.setDate(date.getDate() + x.days);
          var dateformat = formatTime(date, "YYYY-MM-DD");
          //console.log(dateformat);
          if (ChangeDate < dateformat) {
            dispatch(
              actionError(dateformat + "일부터 방문날짜를 설정할수있습니다.")
            );
            return;
          }

          setPayment(true);
        }}
        label="결제하기"
        className="fixed-button"
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label1: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  label3: {
    margin: "20px 30px 10px 30px",
    fontSize: "13px",
    color: colors.text,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
  input: {
    "& input": {
      fontSize: "14px",
      borderBottom: "1.5px solid #333",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    margin: "0px 30px 0px 30px",
  },
  date: {
    margin: "20px 10px 10px 0px",
    background: "none",
  },
  date2: {
    margin: "20px 0px 10px 10px",
    flex: 1,
    background: "none",
  },
  label2: {
    margin: "5px 30px 10px 30px",
    fontSize: "13px",
    color: colors.gray,
    "& span": { color: colors.text, marginLeft: "20px" },
  },
  headerLabel1: { fontSize: "12px", color: "#333" },
  headerLabel2: { fontSize: "14px", color: "#333" },
  simpleTable: {
    marginTop: "3px",
    "& span": {
      "&:first-child": {
        marginRight: "30px",
        color: colors.text,
        fontSize: "12px",
      },
      "&:last-child": {
        fontSize: "14px",
        color: "#000",
      },
    },
  },
});
