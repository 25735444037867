import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Pager from "../../components/pager/Pager";
import ReviewItem from "../../components/review-item/ReviewItem";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";

export default function MyReview({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [tab, setTab] = useState(0);
  return (
    <BasicLayout
      stackTopbar={{
        title: "후기",
        border: true,
      }}
    >
      <Tabs
        value={tab}
        TabIndicatorProps={{ style: { background: "black" } }}
        style={{
          borderBottom: "1px solid #eee",
        }}
        onChange={(e, v) => {
          setTab(v);
        }}
        indicatorColor="primary"
        textColor={colors.text}
        variant="fullWidth"
        className={classes.tabs}
      >
        <Tab
          // value="appraise"
          color={colors.gray}
          label="간편감정"
        />
        <Tab
          color={colors.gray}
          //value="real"
          label="실물인출"
        />
        <Tab
          color={colors.gray}
          //value="direct"
          label="안심직거래"
        />
      </Tabs>

      <Text className={classes.label}>
        <Text font={fonts.notoSansKRBold}>1</Text> 후기
      </Text>

      {[{}, {}, {}].map((x, i) => {
        return <ReviewItem {...x} key={i.toString()} />;
      })}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },
});
