import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import BasicLayout from "../../layouts/basic/BasicLayout";
import fonts from "../../libs/fonts";
import { actionShow } from "../../redux/action/ActionActions";
import NotificationItem from "./NotificationItem";

export default function Notification({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <BasicLayout
      stackTopbar={{
        title: "알림",
        border: true,
        option: (
          <ButtonBase
            onClick={() => {
              dispatch(
                actionShow("알림을 전체 삭제하시겠습니까?", ["취소", "삭제"])
              );
            }}
            className={classes.delete}
          >
            전체삭제
          </ButtonBase>
        ),
      }}
    >
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  delete: {
    borderBottom: "1px solid #333",
    fontSize: "14px",
    padding: "6px 0px",
    color: "#333",
    fontFamily: fonts.notoSansKRMedium,
    marginRight: "20px",
  },
});
