import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import images from "../../libs/images";

export default function ClosePopup({ onClose, children, title = "" }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex className={classes.root}>
      <Flex className={classes.header} row>
        <Text style={{ margin: "0 auto" }}>{title}</Text>
        <ButtonBase
          onClick={onClose}
          style={{ position: "absolute", right: 0 }}
        >
          <img alt="close " src={images.close} className={classes.close} />
        </ButtonBase>
      </Flex>
      <Flex className={classes.content}>{children}</Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  content: {
    overflowY: "auto",
  },
  root: {
    height: "calc(100%-60px)",
    backgroundColor: colors.white,
    position: "fixed",
    zIndex: 10001,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  header: {
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
    borderBottom: "1px solid #eee",
  },
  close: {
    margin: "13px 16px",
    width: "16px",
    height: "16px",
  },
});
