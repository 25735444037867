import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { formatTime, numFormat } from "../../libs/utils";

export default function HistoryItem(state) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const onCancel = state.onCancel;
  const onHistory = state.onHistory;
  const rows = state.rows;
  const orderDate = formatTime(rows.created_at, "YYYY-MM-DD HH:mm");
  const historyOrderPrice = numFormat(rows.orderPrice);
  const historyRequestGram = numFormat(rows.requestGram);
  const remainGram = numFormat(rows.remainGram);

  const feeRate =
    rows.tradeType === "SELL" ? 1 - rows.feeRate : 1 + rows.feeRate;

  const totalPrice = numFormat(
    parseInt(rows.orderPrice * rows.requestGram)
  );

  return (
    <Flex className={classes.root + " shadow"}>
      <Flex row className={classes.header}>
        <Flex>
          <Text
            font={fonts.notoSansKRMedium}
            className={
              rows.tradeType === "SELL" ? classes.methodBlue : classes.method
            }
          >
            지정가 {rows.tradeType === "SELL" ? "매도" : "매수"}{" "}
            <Text
              style={{ color: "#000" }}
              font={fonts.notoSansKRMedium}
              className={classes.method}
            >
              {rows.assetType === "SILVER" ? "은" : "금"}({rows.assetType})
            </Text>
          </Text>
          <Text className={classes.date}>{orderDate}</Text>
        </Flex>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.hint}>
          {rows.tradeType === "SELL" ? "매도" : "매수"}가격
        </Text>
        <Text className={classes.value} font={fonts.notoSansKRMedium}>
          {historyOrderPrice}원
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.hint}>주문금액</Text>
        <Text className={classes.value} font={fonts.notoSansKRMedium}>
          {totalPrice}원
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.hint}>주문중량</Text>
        <Text className={classes.value} font={fonts.notoSansKRMedium}>
          {historyRequestGram}g{" "}
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.hint}>미체결중량</Text>
        <Text className={classes.value} font={fonts.notoSansKRMedium}>
          {remainGram}g
        </Text>
      </Flex>
      {/*
      <Flex row className={classes.row}>
        <Text className={classes.hint}>수익률</Text>
        <Text
          style={{
            color: state === "complete" ? colors.textRed : colors.textBlue,
          }}
          className={classes.value}
          font={fonts.notoSansKRMedium}
        >
          {state === "complete" ? "+2.04%" : "-2.04%"}
        </Text>
      </Flex>
      */}

      <Flex row className={classes.buttons}>
        <ButtonBase
          disabled={state === "complete"}
          onClick={() => onCancel(rows.id)}
          className={classes.button1}
          style={{
            ...(state === "complete" && {
              backgroundColor: "#dddddd",
            }),
          }}
        >
          주문취소
        </ButtonBase>
        <ButtonBase
          onClick={() => onHistory(rows.id)}
          className={classes.button2}
        >
          체결내역
        </ButtonBase>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    borderRadius: "5px",
    margin: "13px 13px 0px 13px",
    boxSizing: "border-box",
    padding: "15px 12px",
  },

  buttons: {
    alignSelf: "stretch",
    alignItems: "center",
    marginTop: "20px",
  },
  button1: {
    borderRadius: "5px",
    padding: "10px 0px",
    textAlign: "center",
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    flex: 1,
    marginRight: "10px",
    fontSize: "16px",
    backgroundColor: "#999",
  },
  button2: {
    borderRadius: "5px",
    padding: "10px 0px",
    textAlign: "center",
    backgroundColor: "black",
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    flex: 1,
    fontSize: "16px",
  },

  row: {
    alignSelf: "stretch",
    alignItems: "center",
    marginTop: "6px",
    boxSizing: "border-box",
    padding: "0px 10px",
  },
  hint: {
    color: "#999999",
    fontSize: "14px",
    flex: 1,
  },
  value: {
    flex: 1,
    textAlign: "right",
    color: "#000",
    fontSize: "14px",
  },
  header: {
    alignItems: "center",
    margin: "0px 10px",
    marginBottom: "10px",
    boxSizing: "border-box",
    borderBottom: "1px solid #dddddd",
    padding: "16px 0px",
    paddingTop: "0px",
    justifyContent: "space-between",
  },
  date: { color: colors.gray, fontSize: "13px" },
  method: {
    color: colors.textRed,
    fontSize: "16px",
  },
  methodBlue: {
    color: colors.textBlue,
    fontSize: "16px",
  },
});
