import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { actionShow } from "../redux/action/ActionActions";
import { toastShow } from "../redux/toast/ToastActions";
import routes, { mapRouteToRole, ROLE } from "../libs/routes";
import { STORAGE_KEY } from "../libs/consts";

export default (SpecificComponent, path = "") => {
  const AuthenticateCheck = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
      role: { LEVEL: userLevel },
    } = useSelector((s) => s.auth);

    const { LEVEL: pageLevel } = mapRouteToRole[path] || {};

    const navigate = (path, param) => {
      const { LEVEL: accessLevel } = mapRouteToRole[path] || {};
      if (userLevel < accessLevel) {
        const needLevel = userLevel + 1;

        switch (needLevel) {
          case ROLE.ROLE_TEMP.LEVEL:
            dispatch(
              actionShow(
                "로그인 후 이용 가능합니다",
                ["닫기", "로그인"],
                [
                  null,
                  () => {
                    history.push(routes.signIn);
                  },
                ]
              )
            );
            break;

          case ROLE.ROLE_SELF_VERIFICATION.LEVEL:
            history.push(routes.selfVerification, { purpose: "SIGNUP" });
            break;

          case ROLE.ROLE_BANKACCOUNT_VERIFICATION.LEVEL:
            history.push(routes.authBankAccount, { nextPage: { path, param } });
            break;

          default:
            dispatch(toastShow("접근 할 수 없는 화면입니다."));
        }
      } else {
        history.push(path, param);
      }
    };

    useEffect(() => {
      if (userLevel < pageLevel) {
        const needLevel = userLevel + 1;

        let param = history?.location?.state || {};

        switch (needLevel) {
          case ROLE.ROLE_SELF_VERIFICATION.LEVEL:
            history.replace(routes.selfVerification, { purpose: "SIGNUP" });
            break;

          case ROLE.ROLE_BANKACCOUNT_VERIFICATION.LEVEL:
            history.replace(routes.authBankAccount, {
              nextPage: { path, param },
            });
            break;

          default:
            dispatch(toastShow("접근 할 수 없는 화면입니다."));
            history.goBack();
        }
      }
    }, [userLevel]);

    if (userLevel < pageLevel) {
      return null;
    } else {
      return <SpecificComponent {...props} navigate={navigate} />;
    }
  };

  return AuthenticateCheck;
};
