import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ButtonChecked from "../../components/button-checked/ButtonChecked";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import images from "../../libs/images";
import InputShadow from "../../components/input-shadow/InputShadow";
import axios from "axios";
import { useDispatch } from "react-redux";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { signIn } from "../../libs/sign";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function AuthBankAccount({}) {
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "계좌인증" }}>
      <AuthStep1 />
      <AuthStep2 />
    </BasicLayout>
  );
}

const AuthStep1 = () => {
  const classes = useStyle();
  const history = useHistory();
  const state = history.location.state || {};
  const { step = 1, purpose = "NEW" } = state;

  if (step !== 1) {
    return null;
  }

  return (
    <>
      <Text className={classes.label}>
        {purpose === "UPDATE" ? "변경을 위한" : "출금을 위한"}
        <br />
        <Text font={fonts.notoSansKRBold}>계좌 인증</Text>이 필요합니다.
      </Text>
      <Button
        onClick={() => {
          history.replace(location.pathname, { ...state, step: 2 });
        }}
        className={classes.button}
        label="계좌 인증"
      />
    </>
  );
};
const AuthStep2 = () => {
  const classes = useStyle2();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = history.location.state || {};
  const { step = 1, nextPage = {}, purpose = "NEW" } = state;
  const [banks, setBanks] = useState([]);

  const [bankCode, setBankCode] = useState("");
  const [number, setNumber] = useState("");

  useEffect(() => {
    APIS.getBanks().then(({ data: { success, data } }) => {
      success && setBanks(data);
    });
  }, []);

  const handleClickConfirm = () => {
    if (!bankCode) {
      dispatch(messageError("은행을 선택해주세요."));
      return;
    }

    if (!number) {
      dispatch(messageError("계좌번호를 확인해주세요."));
      return;
    }

    dispatch(loadingStart);
    APIS.authBankAccount({ bankCode, number })
      .then(({ data: { success, data, message } }) => {
        if (success) {
          saveAuthInfo(data.code);
        } else {
          dispatch(messageError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const saveAuthInfo = (code) => {
    const { path, param } = nextPage;

    dispatch(loadingStart);
    APIS.getAuthToken(code)
      .then(({ data: { authToken } }) => {
        signIn(authToken);

        dispatch(
          messageShow(
            purpose === "UPDATE"
              ? "변경이 완료되었습니다."
              : "계좌 인증이 완료되었습니다.",
            () => {
              if (path) {
                history.replace(path, param);
              } else {
                history.goBack();
              }
            }
          )
        );
      })
      .finally(() => dispatch(loadingEnd));
  };

  if (step !== 2) {
    return null;
  }

  return (
    <>
      <Text className={classes.label} font={fonts.notoSansKRMedium}>
        계좌인증
      </Text>
      <Text className={classes.label2}>본인의 계좌만 등록 가능합니다.</Text>

      <div className={classes.bank + " shadow"}>
        <select
          value={bankCode}
          onChange={({ target: { value } }) => {
            setBankCode(value);
          }}
        >
          <option value="">은행 선택</option>
          {banks.map((x, i) => {
            return (
              <option value={x.code} key={x.code}>
                {x.name}
              </option>
            );
          })}
        </select>
        <img alt="bottom" src={images.down} className={classes.down} />
      </div>

      {/* <InputShadow
        value={""}
        disabled={history.location.state?.bankVerify}
        onChange={() => {}}
        placeholder="예금주 입력"
        className={classes.input}
      /> */}
      <InputShadow
        value={number}
        number
        onChange={setNumber}
        placeholder="계좌번호 입력"
        className={classes.input}
      />
      <Button
        onClick={handleClickConfirm}
        className={classes.button}
        label="확인"
      />
    </>
  );
};

const useStyle = makeStyles({
  label: {
    fontSize: 24,
    margin: "50px 30px 30px 30px",
    lineHeight: 1.5,
    color: colors.text,
  },
  button: {
    position: "absolute",
    bottom: 50,
    width: "calc(100% - 60px)",
    left: 30,
  },
});

const useStyle2 = makeStyles({
  label: {
    fontSize: 24,
    margin: "70px 30px 0px 30px",
    lineHeight: 1.5,
    color: colors.text,
  },
  button: {
    position: "absolute",
    bottom: 50,
    width: "calc(100% - 60px)",
    left: 30,
  },
  label2: {
    color: colors.gray,
    fontSize: 15,
    margin: "0px 30px",
  },
  input: { margin: "50px 30px 0px 30px", marginTop: 30 },
  inputmenu: {
    margin: "40px 30px 0px 30px",
  },
  error: {
    color: colors.textRed,
    textAlign: "center",
    marginTop: "10px",
    fontSize: "14px",
  },
  success: {
    color: colors.text,
    textAlign: "center",
    marginTop: "10px",
    fontSize: "14px",
    marginBottom: "30px",
  },
  name: {
    margin: "10px 30px 0px 30px",
    backgroundColor: colors.dddddd,
    color: colors.text,
    borderRadius: "3px",
    padding: "13px 16px 13px 30px",
    fontSize: "15px",
  },
  bank: {
    "& select": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      width: "100%",
      padding: "13px 0px 13px 30px",
      "font-size": "15px",
      border: "none",
      minHeight: 56,
      color: "#333333",
    },
    display: "flex",
    "background-color": "#ffffff",
    padding: "0px 0px 0px 0px",
    alignItems: "center",
    margin: "40px 30px 0px 30px",
    "border-radius": "3px",
    "justify-content": "space-between",
    "font-family": "NotoSansKR-Regular",
    position: "relative",
    "line-height": 2,
  },
  down: {
    position: "absolute",
    right: 16,
    width: "16px",
    height: "10px",
  },
});
