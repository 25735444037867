import { makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import BasicLayout from "../../layouts/basic/BasicLayout";
import consts from "../../libs/consts";
import routes from "../../libs/routes";
import { numberWithCommas } from "../../libs/utils";

export default function SafeTradeCreateComplete({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const user = useSelector((state) => state.user) || {};

  const handleClickNext = () => {
    history.push(routes.main);
  };
  
  return (
    <BasicLayout
      stackTopbar={{
        title: "상품 등록",
        close: true,
        onGoBack: () => history.push(routes.main),
      }}
    >
      <div className="trade_wrap">
        <div className="store_lst">
          <h3 className="store_tit">제품 판매 접수가 예약 되었습니다.</h3>
          <div className="pd_info">
            <table>
              <tr>
                <td className="info1">접수자</td>
                <td className="info2 bd">
                  {user.name}
                  <span className="spoqa">({user.phone})</span>
                </td>
              </tr>
              <tr>
                <td className="info1">접수번호</td>
                <td className="info2">
                  <span className="spoqa">{state.order_id}</span>
                  <div className="eximg">
                    <img
                      src={`${consts.s3BaseUrl}/${state.image_path}`}
                      alt=""
                    />
                    <div className="info3">
                      <ul>
                        <li>{state.category}</li>
                        <li>{state.name}</li>
                        <li>
                          판매희망가:
                          <span className="spoqa">
                            {numberWithCommas(state.price)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr></tr>
              <tr>
                <td className="info1">접수 지점</td>
                <td className="info2 bd lh">
                  {state.shop_name}
                  <br />
                  <span className="info2_ad">{state.shop_address}</span>
                </td>
              </tr>
              <tr>
                <td className="info1">날짜</td>
                <td className="info2">
                  {/* <span className="spoqa"> 2022.01.01</span>.월 */}
                  <span className="spoqa"> {state.date}</span>
                </td>
              </tr>
              <tr>
                <td className="info1">시간</td>
                <td className="info2 bd">
                  {/* 오후 <span className="spoqa">5</span>시-
                  <span className="spoqa">6</span>시 */}
                  <span className="spoqa">{state.time}</span>
                </td>
              </tr>
            </table>
          </div>
          <div className="pd_notice">
            <div className="pd_noti_tit">안내사항</div>
            <ul>
              <li>
                등록하신 내용은 관리자가 승인 후 판매신청 접수가 완료 됩니다.
              </li>
              <li>
                등록하신 내용이 부적절하거나 오류가 있을 경우 판매 신청이 반려될
                수 있습니다.
              </li>
              <li>
                제품 판매 등록은 전문 감정사의 감정 후 고객님의 선택에 따라
                등록됩니다.
              </li>
              <li>
                마이 &gt; 안심직거래 상세정보에서 실시간 진행사항을 조회 하실 수
                있습니다.
              </li>
            </ul>
          </div>
        </div>
        <div className="ok_btn on" onClick={handleClickNext}>
          확인
        </div>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({});
