import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import ButtonRadio from "../../components/button-radio/ButtonRadio";
import Button from "../../components/button/Button";
import DateSelector from "../../components/date-selector/DateSelector";
import Flex from "../../components/flex/Flex";
import ProductList from "../../components/product-list/ProductList";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import TimeRangeSelectorNew from "../../components/time-range-selector/TimeRangeSelectorNew";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";
import { objToQueryStr, phoneFormat } from "../../services/utils";
import ExchangeDeliveryResult from "./ExchangeDeliveryResult";
import * as APIS from "../../libs/apis";
import { formatTime } from "../../libs/utils";

export default function Exchange({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [products, setProducts] = useState([]);

  //delivery-------
  const [addressDetail, setAddressDetail] = useState("");
  //-------delivery
  const [date, setDate] = useState(formatTime());
  const [time, setTime] = useState("");
  //----
  const state = history.location.state || {};

  const requestMode =
    "appraisal" === state.action && state.request && state.appraisalSimpleIds;
  const userReducer = useSelector((s) => s.user);

  const method = history.location.state?.method;
  const setMethod = (v) =>
    history.replace(history.location.pathname, { ...state, method: v });

  useEffect(() => {
    if (state?.addressDetail) {
      setAddressDetail(state.addressDetail);
    }
    if (state?.date) {
      setDate(state.date);
    }
    if (state?.time) {
      setTime(state.time);
    }
  }, [state]);
  useEffect(() => {
    if (requestMode) {
      const getGoldGramPrice = () => {
        return new Promise((resolve, reject) => {
          APIS.getCurrentMarketPrice("GOLD")
            .then(({ data: { data } }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      };
      const getSilverGramPrice = () => {
        return new Promise((resolve, reject) => {
          APIS.getCurrentMarketPrice("SILVER")
            .then(({ data: { data } }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      };

      const fetchData1 = () => {
        if (!userReducer.id) {
          return;
        }
        dispatch(
          axiosDispatch(
            {
              method: "get",
              url:
                consts.apiBaseUrl +
                "/api/v1/user/market/appraisal/Simples" +
                objToQueryStr({
                  status: "접수",
                  userId: userReducer.id,
                }),
            },
            (err, res) => {
              if (err) {
                dispatch(messageError(err));
              } else {
                const list = res.data.data.filter((x) =>
                  state.appraisalSimpleIds.includes(x.id)
                );
                if (!list.length) {
                  dispatch(
                    messageShow("교환 가능한 제품이 없습니다.", () => {
                      history.replace(routes.appraise);
                    })
                  );
                  return;
                }
                dispatch(
                  axiosDispatch(
                    {
                      method: "get",
                      url:
                        consts.apiBaseUrl +
                        "/api/v1/user/market/appraisal/appraisalWeightRates",
                    },
                    (err2, res2) => {
                      if (err2) {
                        dispatch(messageError(err2));
                      } else {
                        let func =
                          list[0].appraisalProduct.type === "GOLD"
                            ? getGoldGramPrice
                            : getSilverGramPrice;
                        func()
                          .then((price) => {
                            setProducts([
                              ...list.map((x, i) => {
                                x.calc =
                                  x.weightGram *
                                  (res2.data.data.filter(
                                    (y) =>
                                      y.type === x.appraisalProduct.type &&
                                      x.purity === y.purity
                                  )[0]?.weightRatePct /
                                    100) *
                                  (x.appraisalProduct.weightRatePct / 100) *
                                  x.quantity;
                                x.price = price * x.calc;
                                return x;
                              }),
                            ]);
                          })
                          .catch((e) => {
                            dispatch(messageError(e));
                          });
                      }
                    }
                  )
                );
              }
            }
          )
        );
      };

      fetchData1();
    }
  }, [requestMode]);

  const requestExchange = () => {
    try {
      if (method === "delivery") {
        if (!state.address) {
          throw "주소를 검색해 주세요.";
        } else if (!addressDetail) {
          throw "상세주소를 입력해 주세요.";
        } else if (!date || !time) {
          throw "희망하시는 택배수거 일자를 입력해 주세요.";
        }
      } else {
        if (!state.shop) {
          throw "방문하실 매장을 선택해 주세요.";
        } else if (!date) {
          throw "희망하시는 매장 방문 일자를 입력해 주세요.";
        } else if (!time) {
          throw "희망하시는 매장 방문 시간을 입력해 주세요.";
        }
      }
      if (!state.appraisalSimpleIds.length) {
        history.replace(routes.appraise);
        throw "교환하실 제품을 선턱해 주세요.";
      }
    } catch (error) {
      dispatch(messageError(error));
      return;
    }

    const params = {
      acceptTermsYn: "Y",
      appraisalSimpleIds: state.appraisalSimpleIds.join(","),
      type: products[0]?.appraisalProduct.type,
      userId: userReducer.id,
    };

    if (method === "delivery") {
      params.collectAddress = state.address + " " + addressDetail;
      params.collectDate = date;
      params.collectTime = time;
      params.method = "택배";
    } else {
      params.method = "내방";
      params.visitDate = date;
      params.visitTime = time;
      params.shopId = state.shop.id;
    }
    dispatch(loadingStart);
    dispatch(
      axiosDispatch(
        {
          method: "post",
          url: consts.apiBaseUrl + "/api/v1/user/market/appraisal/Requests",
          data: params,
        },
        (err, res) => {
          dispatch(loadingEnd);
          if (err) {
            dispatch(messageError(err));
          } else {
            const appraisalRequestId = res.data.data.id;
            history.replace(routes.exchange, {
              deliveryComplete: true,
              appraisalRequestId: appraisalRequestId,
            });
          }
        }
      )
    );
  };

  if (!state?.method) {
    return (
      <Redirect
        to={{
          pathname: history.location.pathname,
          state: {
            ...history.location.state,
            method: "shop",
          },
        }}
      />
    );
  } else if (!userReducer.id) {
    return <Redirect to={routes.appraise} />;
  } else if (state?.deliveryComplete) {
    if (!state.appraisalRequestId) {
      return <Redirect to={routes.appraise} />;
    }
    return (
      <BasicLayout
        backgroundGray
        stackTopbar={{
          close: true,
          option: (
            <ButtonBase
              onClick={() => {
                history.replace(routes.main);
              }}
            >
              <img
                src={images.home}
                style={{ width: 20, height: 20, marginRight: "16px" }}
              />
            </ButtonBase>
          ),
        }}
      >
        <ExchangeDeliveryResult appraisalRequestId={state.appraisalRequestId} />
      </BasicLayout>
    );
  } else if (requestMode) {
    return (
      <BasicLayout
        stackTopbar={{
          title: "교환 신청",
          border: true,
        }}
      >
        <ProductList data={products} />

        {/*
   <Text font={fonts.notoSansKRMedium} className={classes.label1}>
          접수 방법을 선택해 주세요.
        </Text>
        <Text className={classes.label2}>
          원하시는 주소로 방문 등기소포를 예약하시거나 <br />
          직접 매장으로 가져오실 수 있습니다.
        </Text>
        <ButtonRadio
          onChange={setMethod}
          value={method}
          className="margin"
          data={[
         { label: "택배 접수(수거) 예약", value: "delivery" },
            { label: "직접 내방 접수 예약", value: "shop" },
          ]}
        />
  
  */}
        <Text font={fonts.notoSansKRMedium} className={classes.label1}>
          접수 방법
        </Text>
        <Text className={classes.label2}>
          직접 매장으로 가져오실 수 있습니다.
        </Text>
        <ButtonRadio
          onChange={setMethod}
          value={method}
          className="margin"
          data={[
            //    { label: "택배 접수(수거) 예약", value: "delivery" },
            { label: "직접 내방 접수 예약", value: "shop" },
          ]}
        />
        {method === "delivery" ? (
          <>
            <Text font={fonts.notoSansKRMedium} className={classes.label1}>
              수거 장소를 입력해 주세요.
            </Text>
            <Text className={classes.label2}>
              성함
              <Text font={fonts.notoSansKRMedium}>{userReducer.name}</Text>
              <br />
              연락처
              <Text font={fonts.notoSansKRMedium}>
                {phoneFormat(userReducer.phone)}
              </Text>
            </Text>
            <ButtonMini
              onClick={() => {
                history.push("/address-search", {
                  to: history.location.pathname,
                  state: {
                    ...history.location.state,
                    method,
                    date,
                    time,
                  },
                });
              }}
              label="주소검색"
              className={classes.bt + " margin"}
            />
            <Text className={classes.label3}>{state?.address}</Text>
            {Boolean(state?.address) && (
              <InputBase
                value={addressDetail}
                onChange={(e) =>
                  e.target.value.length < 200 &&
                  setAddressDetail(e.target.value)
                }
                placeholder="상세주소를 입력하세요."
                className={classes.input}
              />
            )}
          </>
        ) : (
          <>
            <Text font={fonts.notoSansKRMedium} className={classes.label1}>
              방문하실 매장을 선택해 주세요.
            </Text>
            <ButtonMini
              style={{ marginBottom: state.shop ? 0 : 30 }}
              onClick={() => {
                history.push(routes.authFindShop, {
                  from: routes.exchange,
                  state: {
                    ...history.location.state,
                    method,
                    date,
                    time,
                  },
                });
              }}
              label="매장 찾기"
              className={classes.bt + " margin"}
            />
            {Boolean(state?.shop) && (
              <ShopItemSimple
                {...state.shop}
                shopName={state?.shop.name}
                address={state?.shop.address}
                addressDetail={state?.shop.addressDetail}
                shopPhone={state?.shop.phoneNumber}
                distance={state?.shop.distance}
                className="margin"
              />
            )}
          </>
        )}

        <Text font={fonts.notoSansKRMedium} className={classes.label1}>
          {method === "delivery"
            ? "희망하시는 택배수거 일자를 선택하세요."
            : "희망하시는 매장 방문 일자를 선택하세요."}
        </Text>

        <Flex row className="margin">
          <DateSelector
            value={date}
            onChange={setDate}
            className={classes.date}
          />
          <TimeRangeSelectorNew
            className={classes.date2}
            value={time}
            onChange={setTime}
          />
        </Flex>
        {method === "delivery" ? (
          <Text className={classes.label2}>
            ㆍ택배수거일은 택배사 사정에 따라 달라질 수 있습니다. <br />
            ㆍ상세감정 결과 총 중량이 1돈(3.75g) 이하일 경우, 교환{" "}
            {products[0]?.appraisalProduct.type === "GOLD" ? "금" : "은"}
            (중량)에서 택배비가 차감됩니다.
          </Text>
        ) : (
          <Text className={classes.label2}>
            ㆍ해당 매장의 영업시간을 확인 후 예약하시기 바랍니다.
            <br />
            ㆍ방문을 예약한 매장은 마이&gt;감정평가 접수내역에서 변경이
            가능합니다. 제품에 따라 감정료가 부과될 수 있습니다.
          </Text>
        )}

        <div style={{ height: "100px" }} />
        <Button
          onClick={requestExchange}
          label="예약하기"
          className="fixed-button"
        />
      </BasicLayout>
    );
  } else {
    return <Redirect to={routes.appraise} />;
  }
}

const useStyle = makeStyles({
  label1: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  label2: {
    margin: "5px 30px 10px 30px",
    fontSize: "13px",
    color: colors.gray,
    "& span": {
      color: colors.text,
      marginLeft: "20px",
    },
  },
  label3: {
    margin: "20px 30px 10px 30px",
    fontSize: "13px",
    color: colors.text,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
  input: {
    "& input": {
      fontSize: "14px",
      borderBottom: "1.5px solid #333",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    margin: "0px 30px 0px 30px",
  },
  date: {
    margin: "20px 10px 10px 0px",
  },
  date2: {
    margin: "20px 0px 10px 10px",
    flex: 1,
  },
});
