import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import info_img from "../../assets/images/info_img.png";
import ic_close from "../../assets/images/ic_close.png";
import ic_place_white from "../../assets/images/ic_place_white.png";
import ic_price from "../../assets/images/ic_price.png";
import ic_phone from "../../assets/images/ic_phone.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import images from "../../libs/images";
import routes from "../../libs/routes";
import consts from "../../libs/consts";
import {
  encodeFileToBase64,
  formatTime,
  numberWithCommas,
} from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError, actionShow } from "../../redux/action/ActionActions";
import {
  initSafeTradeOrder,
  updateSafeTradeOrder,
} from "../../redux/safe-trade/safeTradeOrder";
import { initFindShop } from "../../redux/common/findShop";
import AspectRatioBox from "../../components/common/AspectRatioBox";
import { Swiper, SwiperSlide } from "swiper/react";
import TimeRangeSelector from "../../components/common/TimeRangeSelector";

export default function SafeTradeBuy({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const itemId = state?.id;
  const findShop = useSelector((state) => state.findShop);
  const { reservation, reservation_time, shop } = state;

  const [acceptableKrw, setAcceptableKrw] = useState(0);

  // 상품 정보
  const [item, setItem] = useState({});

  const [orderBookId, setOrderBookId] = useState("");

  const [timeSelectorVisible, setTimeSelectorVisible] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  useEffect(() => {
    if (!itemId) {
      history.goBack();
      return false;
    }

    if (findShop.id) {
      handleChangeValue("shop", findShop);
      dispatch(initFindShop());
    }

    init();
  }, []);

  const init = async () => {
    dispatch(loadingStart);

    const getItem = APIS.getSafeTradeItem(itemId).then(
      ({ data: { success, data } }) => success && setItem(data)
    );

    const getAsset = APIS.getUserHoldingsInfo().then(
      ({ data: { success, data } }) =>
        success && setAcceptableKrw(data?.acceptableKrw)
    );

    Promise.all([getItem, getAsset]).finally(() => dispatch(loadingEnd));
  };

  const handleChangeValue = (key, value) => {
    history.replace(routes.safeTradeBuy, { ...state, [key]: value });
  };

  const handleClickPayment = () => {
    if (!shop?.id) {
      dispatch(actionError("방문하실 매장을 선택해 주세요."));
      return false;
    }
    if (!reservation) {
      dispatch(actionError("매장 방문 일자를 선택하세요."));
      return false;
    }
    if (!reservation_time) {
      dispatch(actionError("매장 방문 시간을 선택하세요."));
      return false;
    }

    setOrderBookId("");

    dispatch(loadingStart);
    APIS.postSafeTradeBuy(itemId, {
      shop_id: shop.id,
      reservation,
      reservation_time,
    })
      .then(({ data: { success, message, data } }) => {
        if (success) {
          setOrderBookId(data.order_book_id);
          setPaymentModalVisible(true);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const { image, brand, name, price, fee } = item;
  const canNext = shop?.id && reservation && reservation_time;
  const totalPrice = +price + +fee;
  return (
    <BasicLayout stackTopbar={{ title: "주문 상세" }}>
      <div className="store_visit_top">
        <h3>
          해당 상품은 <span>원화(KRW)</span>로만 <span>결제</span> 가능합니다.
        </h3>
      </div>
      <div className="store_visit_top_sub">
        <img src={ic_price} alt="" />
        <p>
          사용 가능 금액{" "}
          <span className="spoqa">{numberWithCommas(acceptableKrw)}</span>원
        </p>
      </div>
      <div className="order_wrap">
        <div className="store_visit">
          <div className="store_visit_pd">
            <div className="visit_pd_left">
              <img src={`${consts.s3BaseUrl}/${image}`} />
            </div>
            <div className="visit_pd_right">
              <table>
                <tr>
                  <td className="ft14">
                    <h3>{brand}</h3>
                  </td>
                  <td className="ft10 clgr">
                    <span className="spoqa">No.{itemId}</span>
                  </td>
                </tr>
                <tr className="bdbottom">
                  <td className="ft14">{name}</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="ft10">거래 가격</td>
                  <td className="ft11">
                    <span className="spoqa">{numberWithCommas(price)}</span>원
                  </td>
                </tr>
                <tr>
                  <td className="ft10">구매 수수료</td>
                  <td className="ft11">
                    <span className="spoqa">{numberWithCommas(fee)}</span>원
                  </td>
                </tr>
                <tr>
                  <td className="ft12">구매 금액</td>
                  <td className="ft13">
                    <h3 className="spoqa">{numberWithCommas(totalPrice)}</h3>원
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <h3 className="store_visit_tit">
            제품 수령을 위해 방문하실 매장을 선택해 주세요.
          </h3>
          <div
            className="store_visit_find"
            onClick={() => {
              history.push(routes.commonFindShop);
            }}
          >
            매장찾기
          </div>

          {!!shop?.id && (
            <>
              <div className="store_visit_sub_tit">방문 매장</div>
              <div className="store_visit_re">
                <img src={ic_place_white} alt="" className="ic_place" />
                <span>{shop.name}</span>{" "}
                {!!shop.distance && (
                  <>
                    <span className="spoqa">
                      {numberWithCommas(shop.distance)}
                    </span>
                    km
                  </>
                )}
                <div>
                  <p>{shop.address}</p>
                  <span className="spoqa orgbt">
                    <img src={ic_phone} />
                    {shop.phoneNumber}
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="store_visit_sub_tit">
            희망하시는 매장 방문 일자를 선택하세요.
          </div>
          <div className={`store_visit_date ${reservation ? "spoqa" : ""}`}>
            {reservation || "선택"}
            <input
              type="date"
              className={classes.inputDate}
              value={reservation || ""}
              onChange={(e) => handleChangeValue("reservation", e.target.value)}
            />
          </div>
          <div className="store_visit_sub_tit">
            희망하시는 매장 방문 시간을 선택하세요.
          </div>
          <div
            className="store_visit_time"
            onClick={() => setTimeSelectorVisible(true)}
          >
            {reservation_time || "선택"}
          </div>
        </div>
      </div>
      <div
        className={`ok_btn ${canNext ? "on" : "off"}`}
        onClick={handleClickPayment}
        style={{ zIndex: 1 }}
      >
        결제하기
      </div>
      <TimeRangeSelector
        visible={timeSelectorVisible}
        value={reservation_time}
        onChange={(value) => handleChangeValue("reservation_time", value)}
        onClose={() => setTimeSelectorVisible(false)}
      />

      {paymentModalVisible && (
        <PaymentModal
          acceptableKrw={acceptableKrw}
          totalPrice={totalPrice}
          onClose={() => setPaymentModalVisible(false)}
          onConfirm={() => {
            if (acceptableKrw < totalPrice) {
              dispatch(
                actionShow(
                  "사용가능 원화가 부족합니다.",
                  ["닫기", "입급하기"],
                  [
                    () => {},
                    () => {
                      history.push(routes.depositWithdrawal);
                    },
                  ]
                )
              );
            }
            history.push(routes.commonPasswordSecurity, {
              type: "SAFE_TRADE_BUY",
              payload: { itemId, orderBookId },
            });
          }}
        />
      )}
    </BasicLayout>
  );
}

const PaymentModal = ({
  acceptableKrw = 0,
  totalPrice = 0,
  onClose,
  onConfirm,
}) => {
  return (
    <div
      className="trade_wrap_bk"
      style={{ zIndex: 1, position: "fixed", top: 0 }}
    >
      <div className="pd_pay">
        <div className="pay_tit">
          <h3>결제하기</h3>
        </div>
        <div className="pay_list">
          <ul>
            <li>보유잔고</li>
            <li>
              <span className="spoqa">{numberWithCommas(acceptableKrw)}</span>원
            </li>
          </ul>
          <ul className="ulbd">
            <li>결제 금액</li>
            <li>
              <span className="spoqa">{numberWithCommas(totalPrice)}</span>원
            </li>
          </ul>
          <div className="pay_btn">
            <button className="cancel" onClick={onClose}>
              취소
            </button>
            <button className="ok" onClick={onConfirm}>
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  inputDate: {
    opacity: 0,
    position: "absolute !important",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100% !important",
    zIndex: 1,
  },
});
