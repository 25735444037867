import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function ShopItemSimple({
  onClick,
  list,
  name,
  address,
  shopId,
  addressDetail,
  shopPhone,
  distance,
  onCheckedChange,
  className,
  checked,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex className={classes.root + " " + className} row>
      <Flex style={{ flex: 1 }}>
        <Flex row className="row-between">
          <Text font={fonts.notoSansKRMedium} className={classes.label}>
            {name}
          </Text>
          {!!distance && (
            <Flex row style={{ alignItems: "center" }}>
              <img
                src={images.location}
                style={{
                  width: "14px",
                  height: "14px",
                  marginRight: "6px",
                  objectFit: "contain",
                }}
              />
              <Text className={classes.distance}>{parseFloat(distance)}km</Text>
            </Flex>
          )}
        </Flex>
        <Text className={classes.address}>
          {address} {addressDetail}
        </Text>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    padding: "18px 0px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    color: "#333",
    fontSize: "18px",
  },
  distance: {
    color: "#333",
    fontSize: "12px",
  },
  address: {
    fontSize: "14px",
    color: colors.text,
    marginTop: "5px",
  },
});
