import { ButtonBase, Divider, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Rating from "../../components/rating/Rating";
import images from "../../libs/images";
import Images from "../../components/images/Images";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import routes from "../../libs/routes";
import { numFormat, objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
export default function WriteReview({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [desc, setDesc] = useState("");
  const state = history.location.state;
  const [dimages, setDImages] = useState([]);
  const [rating, setRating] = useState(0);
  const [appraiseData, setAppraisData] = useState({});
  const userReducer = useSelector((s) => s.user);

  useEffect(() => {
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/appraisal/Requests/" +
            state.id,
        },
        (err, res) => {
          if (err) {
            dispatch(messageError(err));
          } else {
            const data = res.data.data;

            const list = data.appraisalRequestStatus;

            const finish = list.filter((x) => x.status === "감정완료")[0];

            if (!finish) {
              return;
            }
            APIS.getCurrentMarketPrice(finish.appraiseType)
              .then(({ data: { data } }) => {
                setAppraisData({
                  gram: finish.appraiseGram,
                  type: finish.appraiseType,
                  price: parseFloat(data) * parseFloat(finish.appraiseGram),
                });
              })
              .catch((err) => dispatch(messageError(err)));
          }
        }
      )
    );
  }, [state]);

  const handleSave = () => {
    const id = state?.id;
    if (!id) {
      return dispatch(toastShow("올바르지 않은 접근입니다."));
    } else if (!rating) {
      return dispatch(toastShow("평점을 선택해 주세요."));
    } else if (!desc) {
      return dispatch(toastShow("내용을 입력해 주세요."));
    }

    dispatch(
      axiosDispatch(
        {
          method: "post",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/appraisal/appraisalFeedbacks",
          data: {
            content: desc,
            rating: rating,
            appraisalRequestId: id,
            userId: userReducer.id,
          },
        },
        async (err, res) => {
          if (err) return dispatch(messageError(err));
          const feedbackId = res.data.data.id;

          const addImage = (file) => {
            const form = new FormData();
            form.append("image", file);

            return new Promise((resolve, reject) => {
              dispatch(
                axiosDispatch(
                  {
                    method: "put",
                    url:
                      consts.apiBaseUrl +
                      "/api/v1/user/market/appraisal/appraisalFeedbacks/" +
                      feedbackId +
                      "/image",
                    file: true,
                    data: form,
                  },
                  (err, res) => {
                    if (err) return reject(err);
                    resolve();
                  }
                )
              );
            });
          };
          if (dimages.length) {
            let i = 1;
            try {
              for await (let image of dimages) {
                await addImage(image.file);
              }
            } catch (error) {
              dispatch(
                axiosDispatch({
                  method: "delete",
                  url:
                    consts.apiBaseUrl +
                    "/api/v1/user/market/appraisal/appraisalFeedbacks/" +
                    feedbackId,
                })
              );

              return dispatch(
                messageShow(
                  `이미지를 업로드중 오류가 발생했습니다. 다시 등록해 주세요.`
                )
              );
            }
          }

          history.replace(routes.appraiseReviewDetail, {
            id: feedbackId,
            requestId: state.id,
          });
        }
      )
    );
  };
  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "교환 완료",
      }}
    >
      <span className={classes.label1}>
        {appraiseData.type === "GOLD" ? "금(GOLD)" : "은(SILVER)"}으로 교환이
        완료되었습니다.
      </span>
      <span className={classes.label2}>
        {appraiseData.type === "GOLD" ? "금(GOLD)" : "은(SILVER)"}{" "}
        {appraiseData.gram}g
      </span>
      <span className={classes.label3}>
        = {numFormat(appraiseData.price)}원
      </span>
      <ButtonBase className={classes.button} onClick={history.goBack}>
        거래내역확인
      </ButtonBase>

      <span className={classes.label4}>후기를 남겨주세요.</span>
      <Rating
        editMode
        rating={rating}
        onChange={setRating}
        size={30}
        className={classes.rating}
      />

      <div className={classes.ipwrap + " shadow2"}>
        <InputBase
          placeholder="내용을 입력해 주세요.(200자 이내)"
          value={desc}
          multiline
          fullWidth
          onChange={(e) => {
            if (e.target.value.length > 200) {
              return;
            }
            setDesc(e.target.value);
          }}
        />
      </div>
      <Images
        data={dimages}
        setData={setDImages}
        max={1}
        className={classes.images}
      />
      <ButtonBase onClick={handleSave} className={classes.button2}>
        등록
      </ButtonBase>
      <img src={images.simpleinspect2} className={classes.banner} />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  rating: {
    alignSelf: "center !important",
    marginBottom: "20px",
  },
  label1: {
    fontSize: 14,
    marginTop: 40,
    fontFamily: fonts.notoSansKRLight,
    color: "#333333",
    alignSelf: "center",
  },
  label2: {
    fontSize: 24,
    color: "#333333",
    marginTop: 14,
    fontFamily: fonts.notoSansKRBold,
    alignSelf: "center",
  },
  label3: {
    fontSize: 16,
    color: "#333",
    fontFamily: fonts.notoSansKRLight,
    alignSelf: "center",
  },
  button: {
    borderRadius: 20,
    border: "1px solid #ff6e0e",
    padding: "9px 30px",
    backgroundColor: "#fff",
    color: colors.primary,
    fontSize: 13,
    alignSelf: "center",
    marginTop: 16,
    fontFamily: fonts.notoSansKRBold,
  },
  label4: {
    fontSize: 14,
    color: "#333333",
    fontFamily: fonts.notoSansKRMedium,
    alignSelf: "center",
    marginTop: 38,
    marginBottom: 14,
  },
  ipwrap: {
    margin: "20px",
    backgroundColor: "#fff",
    borderRadius: 3,
    padding: 18,
    "& textarea": {
      fontSize: 13,
      flex: 1,
      maxHeight: "200px",
    },
  },
  images: {
    marginLeft: "28px",
    marginRight: "28px",
    marginBottom: 24,
  },
  button2: {
    backgroundColor: colors.primary,
    borderRadius: 3,
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 14,
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
  },
  banner: {
    width: "100%",
    backgroundColor: "#fec729",
    marginTop: 100,
  },
});
