import { InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";
import routes from "../../libs/routes";

import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import Password from "./Password";
import NumberFormat from "react-number-format";
import * as APIS from "../../libs/apis";
import { numberWithCommas } from "../../libs/utils";
import { objToQueryStr } from "../../services/utils";

export default function WithDrawal({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const history = useHistory();
  const { signed, id } = useSelector((s) => s.user);
  const { userId } = useSelector((s) => s.auth);
  const [account, setAccount] = useState([]);
  const [inputprice, setinputprice] = useState(0);
  const [fee, setFee] = useState(0);
  const [autoWithdraw, setAutoWithdraw] = useState(0);
  /*
  useEffect(() => {
    //  dispatch(toastShow("출금요청이 완료되었습니다."));
    //     dispatch(messageShow("출금요청이 완료되었습니다.", history.goBack));
  }, []);
  */

  useEffect(() => {
    APIS.getWithdrawalAccount(objToQueryStr({ userId })).then(({ data }) => {
      setAccount(data?.data || "");
    });

    APIS.getSharedCodeTrade("WITHDRAW_FEE_KRW").then(({ data }) => {
      const fee = data?.data?.value;
      setFee(fee || 0);
    });

    APIS.getSharedCodeTrade("AUTO_WITHDRAW_LIMIT").then(({ data }) => {
      const autoWithdraw = data?.data?.value;
      setAutoWithdraw(autoWithdraw / 10000 || 0);
    });
  }, []);

  const expayOrder = () => {
    if (isNaN(inputprice) || +inputprice <= 0) {
      dispatch(toastShow("출금 금액을 입력하세요."));
      return;
    }
    const check = /^[0-9]+$/;
    if (!check.test(inputprice)) {
      dispatch(toastShow("숫자만 입력 가능합니다."));
    }

    // if (inputprice < +fee) {
    //   dispatch(
    //     toastShow(`출금 최소 금액은 ${numberWithCommas(fee)}원 입니다.`)
    //   );
    //   return;
    // }

    if (account.availWons < +inputprice + +fee) {
      dispatch(toastShow(`이체가능금액을 초과하였습니다.`));
      return;
    }
    setPopup(true);
  };
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "출금하기" }}>
      <Flex row className={classes.row}>
        <Text className={classes.label}>등록계좌</Text>
        <Text className={classes.value}>
          {account.accountNumber} {account.bankName}/{account.accountHolder}
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.label}>이체가능금액</Text>
        <Text className={classes.value}>
          <NumberFormat
            value={account.availWons}
            displayType={"text"}
            thousandSeparator={true}
            className="spoqa"
          />{" "}
          원
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.label}>출금금액</Text>
        <InputBase
          // type="number"
          inputMode="numeric"
          value={numberWithCommas(inputprice) || ""}
          onChange={(e) => {
            let values = e.target.value;
            values = values.replace(/,/gi, "");
            let regexp = /^[0-9]*$/;
            if (!regexp.test(values)) {
              return;
            } else {
              values = values.replace(/,/gi, "");
            }
            setinputprice(values);
          }}
          className={classes.input}
        />
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.label}>수수료</Text>
        <Text className={classes.value}>
          <NumberFormat
            value={fee}
            displayType="text"
            thousandSeparator={true}
            className="spoqa"
          />{" "}
          원
        </Text>
      </Flex>

      <Button
        onClick={() => expayOrder()}
        className={classes.button}
        label="출금 요청"
      />
      <Text className={classes.message}>
        <Text className={classes.message2} font={fonts.notoSansKRMedium}>
          등록계좌로 출금 방법
          <br />
        </Text>
        1. 실 계좌 정보를 확인합니다.
        <br />
        2. 현재 이체 가능 금액을 확인합니다.
        <br />
        3. 출금할 금액을 입력합니다.
        <br />
        4. [출금 요청] 버튼을 클릭합니다.
        <br />
        <br />
        <Text className={classes.message2} font={fonts.notoSansKRMedium}>
          등록계좌로 출금시 유의사항
        </Text>
        <br />
        1. 지급 이체 수수료는{" "}
        <NumberFormat
          value={fee}
          displayType="text"
          thousandSeparator={true}
          className="spoqa"
        />
        원 입니다.
        <br />
        2. 지연 출금안내
        <br />ㆍ{" "}
        <NumberFormat
          value={autoWithdraw}
          displayType="text"
          thousandSeparator={true}
          className="spoqa"
        />
        만원 이하 : 즉시 출금 처리
        <br />ㆍ{" "}
        <NumberFormat
          value={autoWithdraw}
          displayType="text"
          thousandSeparator={true}
          className="spoqa"
        />
        만원 이상 : 운영자 확인 후 출금
        <br />
        ㆍ 확인 업무시간 : 09:00~19:00
        <br />
        (19시 이후{" "}
        <NumberFormat
          value={autoWithdraw}
          displayType="text"
          thousandSeparator={true}
          className="spoqa"
        />
        만원 이상 출금 요청 건은 익일 9시
        <br />
        부터 확인 후 순차적으로 출금처리 됩니다.)
      </Text>

      {popup && (
        <Password
          onClose={() => {
            setPopup(false);
          }}
          onConfirm={() => {
            dispatch(
              messageShow(
                "출금 요청이 완료되었습니다.",
                history.push(routes.main)
              )
            );
          }}
          inputprice={inputprice}
        />
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  message: {
    lineHeight: 1.6,
    fontSize: "13px",
    margin: "20px 30px",
    color: colors.gray,
  },
  message2: {
    fontSize: "15px",
    color: colors.text,
  },
  button: {
    margin: "20px 30px 0px 30px",
  },
  row: {
    alignItems: "center",
    margin: "20px 30px 0px 30px",
    justifyContent: "space-between",
  },
  label: {
    color: colors.gray,
    fontSize: 14,
  },
  value: {
    textAlign: "right",
    color: colors.text,
    fontSize: "14px",
  },
  input: {
    backgroundColor: colors.white,
    height: "40px",
    fontSize: "17px",
    padding: "10px",
    boxSizing: "border-box",
    fontFamily: fonts.notoSansKRBold,
    color: colors.black,
    width: "50%",
    borderRadius: "4px",
    textAlign: "right",
    textAlignLast: "right",
  },
});
