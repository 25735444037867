import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";

export default function DeliveryState({ index, status, shipmentStatus }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  // APPROVAL, // 결제완료
  // MAKE, // 제작중
  // SHIPMENT, // 출고중
  // DELIVERY, // 출고완료
  // TAKEOUT, // 인출완료
  // CONFIRM, // 인출확정
  // CANCEL, // 결제취소

  let currentStep = 0;

  if (status === "APPROVAL") {
    currentStep = 1;
  } else if (status === "TAKEOUT" || status === "CONFIRM") {
    currentStep = 4;
  }

  if (shipmentStatus === "MAKE" || shipmentStatus === "SHIPMENT") {
    if (status === "TAKEOUT" || status === "CONFIRM") {
      currentStep = 4;
    } else {
      currentStep = 2;
    }
  } else if (shipmentStatus === "DELIVERY") {
    if (status === "TAKEOUT" || status === "CONFIRM") {
      currentStep = 4;
    } else {
      currentStep = 3;
    }
  }

  // switch (status) {
  //   case "APPROVAL":
  //     // 결제완료
  //     currentStep = 1;
  //     break;

  //   case "TAKEOUT":
  //   case "CONFIRM":
  //     // 인출완료
  //     currentStep = 4;
  //     break;
  // }

  // switch (shipmentStatus) {
  //   case "MAKE":
  //   case "SHIPMENT":
  //     // 제작 중
  //     currentStep = 2;
  //     break;

  //   case "DELIVERY":
  //     // 픽업or배송
  //     currentStep = 3;
  //     break;
  // }

  const STEPS = [
    { step: 1, label: "결제완료" },
    { step: 2, label: "제작 중" },
    { step: 3, label: "픽업 or 배송" },
    { step: 4, label: "인출완료" },
  ];
  return (
    <Flex row className={classes.root}>
      <Flex className={classes.navigator}>
        <Flex className={classes.divider} />
        <Flex className={classes.navigatorBalls}>
          {STEPS.map(({ step, label }) => {
            let backgroundColor = "#ddd";
            if (currentStep === step) {
              backgroundColor = colors.primary;
            } else if (currentStep > step) {
              backgroundColor = "black";
            }

            return (
              <div
                key={step}
                style={{ backgroundColor }}
                className={classes.ball}
              />
            );
          })}
        </Flex>
      </Flex>
      <Flex className={classes.labels}>
        {STEPS.map(({ step, label }) => {
          return (
            <Text
              key={step}
              font={
                step === currentStep
                  ? fonts.notoSansKRMedium
                  : fonts.notoSansKRRegular
              }
              style={{ ...(step === currentStep && { color: "#333" }) }}
              className={classes.label}
            >
              {label}
            </Text>
          );
        })}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  navigator: {
    width: "30px",
    position: "relative",
  },
  divider: {
    flex: 1,
    alignSelf: "center",
    width: "15%",
    backgroundColor: "#ddd",
  },
  root: { flex: 1, minHeight: "80px", marginLeft: "10px" },
  navigatorBalls: {
    zIndex: 2,
    left: 0,
    right: 0,
    alignItems: "center",
    top: 0,
    bottom: 0,
    position: "absolute",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ball: {
    width: "14px",
    height: "14px",
    backgroundColor: "#ddd",
    borderRadius: "100px",
    border: "2px solid #fff",
  },
  labels: {
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  label: { fontSize: "14px", color: "#999", textAlign: "left" },
});
