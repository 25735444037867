import React, { useEffect, useState } from "react";
import ic_check_white from "../../assets/images/ic_check_white.png";
import ic_close from "../../assets/images/ic_close.png";
import * as APIS from "../../libs/apis";

const TimeRangeSelector = ({
  visible = false,
  value,
  onChange = () => {},
  onClose = () => {},
}) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setSelected(value);
  }, [visible, value]);

  const init = async () => {
    let openTime = await APIS.getSharedCodeTrade("SHOP_OPEN_TIME").then(
      ({ data: { data } }) => {
        return data.value;
      }
    );

    let closeTime = await APIS.getSharedCodeTrade("SHOP_CLOSE_TIME").then(
      ({ data: { data } }) => {
        return data.value;
      }
    );

    const timeArray = [];
    while (openTime < closeTime) {
      if (!openTime || !closeTime) {
        break;
      }
      let open = +openTime;
      let close = open + 200;
      if (close > +closeTime) {
        close = +closeTime;
      }

      timeArray.push({ open, close });
      openTime = close;
    }

    const convertedArray = timeArray.map(({ open, close }) => {
      let openStr = open < 1000 ? "0" + open : "" + open;
      openStr = `${openStr.substring(0, 2)}:${openStr.substring(2)}`;

      let closeStr = close < 1000 ? "0" + close : "" + close;
      closeStr = `${closeStr.substring(0, 2)}:${closeStr.substring(2)}`;

      return `${openStr}~${closeStr}`;
    });
    setOptions(convertedArray);

    if (!value) {
      onChange(convertedArray[0]);
    }
  };

  return (
    <div
      className="trade_wrap_bk"
      style={{
        display: visible ? "block" : "none",
        position: "fixed",
        top: 0,
        zIndex: 10000,
      }}
    >
      <div className="pd_select">
        <div className="select_tit">
          <h3>시간 선택</h3>
          <img src={ic_close} alt="X" onClick={onClose} />
        </div>
        <div className="select_list">
          <ul className="spoqa">
            {options.map((time, key) => (
              <li
                key={key.toString()}
                className={`${selected === time && "on"}`}
                onClick={() => setSelected(time)}
              >
                <div className="ck_cir">
                  <img src={ic_check_white} alt="" />
                </div>
                {time}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="ok_btn on"
          onClick={() => {
            onChange(selected);
            onClose();
          }}
        >
          확인
        </div>
      </div>
    </div>
  );
};

export default TimeRangeSelector;
