import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";


import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";

import routes from "../../libs/routes";
import { messageShow } from "../../redux/message/MessageActions";


export default function VirtualOrder({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useSelector((s) => s.user);
  const [account, setAccount] = useState([]);

  useEffect(() => {
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url: consts.apiBaseUrl + "/api/v1/user/market/withdrawal/account?userId="+ id, 
        },
        (err, res) => {
          if (err) {
            //dispatch(actionError(err));
          } else {
            //console.log(res);
            setAccount(res.data);
          }
        }
      )
    );


  }, []);

  const virtualOrderSubmit = () => {
    dispatch(
        axiosDispatch(
          {
            method: "put",
            //https://dev-api.kumbang.co.kr/api/v1/user/asset/virtual 
            //url: consts.apiBaseUrl + "/api/v1/user/virtualacct/"+ id +"/assgin", 
            url: consts.apiBaseUrl + "/api/v1/user/asset/virtual", 
          },
          (err, res) => {
            // console.log(err);
            // console.log(res);
            
            if (err) {
              //dispatch(actionError(err));
            } else {
              if(res.data.success === false){
                dispatch(messageShow(res.data.message, history.push(routes.depositWithdrawal)));
              } else {
                dispatch(messageShow("가상계좌가 발급되었습니다.", history.push(routes.depositWithdrawal)));
              }
              /*
              if(res.data === -2){
                dispatch(messageShow("이미 가상계좌가 할당되었습니다.", history.push(routes.depositWithdrawal)));
              } else {
                dispatch(messageShow("가상계좌가 발급되었습니다.", history.push(routes.depositWithdrawal)));
              }
              */
            }
          }
        )
    );
  }
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "가상계좌 발급 신청" }}>
      <Text className={classes.label}>
        입출금 서비스를 위해<br />
        가상계좌 발급 신청을 해주세요.
      </Text>

      {account.accountHolder &&
        <Flex row className={classes.card + " shadow"}>
            <Text className={classes.cardLabel}>등록계좌</Text>
            <Text className={classes.cardValue}>
            {account.bankName} | {account.accountHolder}
            <br />
            <Text font={fonts.notoSansKRMedium} style={{ fontSize: "16px" }}>
                {account.accountNumber}
            </Text>
            </Text>
        </Flex>
      }
      <Button label="가상계좌 발급 신청" className={classes.button} onClick={virtualOrderSubmit} />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  card: {
    margin: "10px 30px 20px 30px",
    alignItems: "center",
    padding: "16px 0px",
  },
  label: {
    margin: "50px 80px 20px 30px",
    color: colors.text,
    fontSize: "18px",
  },
  row: {
    alignItems: "center",
    margin: "0px 40px",
    marginBottom: "5px",
  },
  hint: {
    fontSize: "14px",
    lineHeight: 1,
    marginLeft: "10px",
    color: colors.text,
  },
  down: {
    width: "15px",
    height: "20px",
  },
  button: {
    margin: "0px 30px",
  },
  placeholder: {
    fontSize: "12px",
    color: colors.gray,
    margin: "20px 30px",
    alignSelf: "center",
    textAlign: "center",
  },
  cardLabel: {
    color: colors.gray,
    flex: 1,
    textAlign: "center",
    fontSize: "13px",
  },
  cardValue: {
    flex: 3,
    fontSize: "14px",
    color: colors.text,
  },
});
