import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import Label from "./Label";
import ListItem from "./ListItem";
import Topbar from "./Topbar";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

const LOGIN_TYPE = {
  NON: "NON_LOGIN",
  LOGIN: "LOGIN",
};

export default function Menu({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    role: { LEVEL },
  } = useSelector((s) => s.auth);

  const isSigned = LEVEL;

  const handleClickDeposit = () => {
    dispatch(loadingStart);
    APIS.getVirtualAccount()
      .then(({ data: { data } }) => {
        const canDeposit = !!data;
        if (canDeposit) {
          navigate(routes.deposit);
        } else {
          navigate(routes.virtualOrder);
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const MENU_LIST = [
    {
      isLabel: true,
      title: "회원가입 및 로그인",
      loginType: LOGIN_TYPE.NON,
      top: true,
    },
    { title: "회원가입", loginType: LOGIN_TYPE.NON, path: routes.signIn },
    { title: "로그인", loginType: LOGIN_TYPE.NON, path: routes.signIn },
    { isLabel: true, title: "매수/매도", top: true },
    { title: "금(GOLD) 매수/매도", path: routes.trading },
    {
      title: "은(SILVER) 매수/매도",
      path: routes.trading,
      param: { type: "silver" },
    },
    { isLabel: true, title: "서비스" },
    { title: "간편감정", path: routes.appraiseRegistMain, param: { step: 1 } },
    { title: "현장 감정 접수하기", path: routes.appraiseDirect },
    { title: "실물인출", path: routes.physicalWithDrawal },
    { title: "안심직거래", path: routes.safeTradeList },
    // { title: "후기" },
    { isLabel: true, title: "입/출금", loginType: LOGIN_TYPE.LOGIN },
    { title: "출금하기", loginType: LOGIN_TYPE.LOGIN, path: routes.withdrawal },
    {
      title: "입금하기",
      loginType: LOGIN_TYPE.LOGIN,
      onClick: handleClickDeposit,
    },
    {
      title: "계좌변경하기",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.authBankAccount,
      param: { purpose: "UPDATE" },
    },
    { isLabel: true, title: "마이", loginType: LOGIN_TYPE.LOGIN },
    { title: "내 자산 현황", loginType: LOGIN_TYPE.LOGIN, path: routes.mypage },
    {
      title: "매수/매도 체결내역",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.mytradehistory,
    },
    {
      title: "내 간편감정",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.appraise,
    },
    // {
    //   title: "내 안심직거래",
    //   loginType: LOGIN_TYPE.LOGIN,
    //   path: routes.myDirectTransaction,
    // },
    {
      title: "내 실물인출",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.myPhysicalWithDrawal,
    },
    { isLabel: true, title: "고객센터" },
    { title: "공지사항", path: routes.noticeList },
    { title: "이벤트", path: routes.eventList },
    {
      title: "이용가이드",
      onClick: () => {
        window.location.href = "https://guide.kumbang.co.kr/";
      },
    },
    { title: "자주하는 질문", path: routes.faqList },
    { title: "1:1 문의하기", loginType: LOGIN_TYPE.LOGIN, path: routes.inquiry,},
    { title: "이용약관", path: routes.termsList },
    {
      title: "내정보 수정",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.editProfile,
    },
    // { title: "회사소개" },
  ];

  const filteredMenu = MENU_LIST.filter(({ loginType }) => {
    return (
      !loginType ||
      (isSigned && loginType === LOGIN_TYPE.LOGIN) ||
      (!isSigned && loginType === LOGIN_TYPE.NON)
    );
  });

  return (
    <MainLayout className={classes.root} white>
      <Topbar />
      {filteredMenu.map(({ isLabel, title, top, path, param, onClick }, i) => {
        if (isLabel) {
          return <Label top={i === 0}>{title}</Label>;
        } else {
          return (
            <ListItem
              onClick={() => {
                onClick ? onClick() : navigate(path, param);
              }}
            >
              {title}
            </ListItem>
          );
        }
      })}

      {/* 
      {isSigned ? (
        <>
          <Label top>마이</Label>
          <ListItem
            onClick={() => {
              navigate(routes.editProfile);
              // history.push(routes.editProfile);
            }}
          >
            회원정보 수정
          </ListItem>
          <ListItem>내 자산 현황</ListItem>
          <ListItem>매수/매도 체결내역</ListItem>
          <ListItem
            onClick={() => {
              navigate(routes.appraise);
            }}
          >
            내 간편감정
          </ListItem>
          <ListItem
            onClick={() => {
              history.push(routes.myDirectTransaction);
            }}
          >
            내 안심직거래
          </ListItem>
          <ListItem
            onClick={() => {
              history.push(routes.myPhysicalWithDrawal);
            }}
          >
            내 실물인출
          </ListItem>
        </>
      ) : (
        <>
          <Label top>회원가입 및 로그인</Label>
          <ListItem
            onClick={() => {
              history.push(routes.signIn);
            }}
          >
            로그인
          </ListItem>
          <ListItem
            onClick={() => {
              history.push(routes.signIn);
            }}
          >
            회원가입
          </ListItem>
        </>
      )}

      <Label>매수/매도</Label>

      <ListItem disabled={!isSigned}>금(GOLD)매수/매도</ListItem>

      <ListItem disabled={!isSigned}>은(SILVER)매수/매도</ListItem>
      {!!isSigned && (
        <>
          <Label>입/출금</Label>
          <ListItem>출금하기</ListItem>
          <ListItem>입금하기</ListItem>
          <ListItem>계좌변경하기</ListItem>
        </>
      )}

      <Label>서비스</Label>
      <ListItem
        onClick={() => {
          navigate(routes.appraiseRegist, { step: 1 });
        }}
      >
        간편감정
      </ListItem>
      <ListItem
        onClick={() => {
          navigate(routes.appraiseDirect);
        }}
      >
        현장 감정 접수하기
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.physicalWithDrawal);
        }}
      >
        실물인출
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.directTransaction);
        }}
      >
        안심직거래
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.myreview);
        }}
      >
        후기
      </ListItem>
      <Label>고객센터</Label>
      <ListItem
        onClick={() => {
          history.push(routes.notice);
        }}
      >
        공지사항
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.inquiry);
        }}
      >
        문의하기
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.faq);
        }}
      >
        자주하는 질문
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.policy, {
            type: "service",
          });
        }}
      >
        이용약관
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.policy, {
            type: "privacy",
          });
        }}
      >
        개인정보 처리방침
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.shops);
        }}
      >
        회사소개
      </ListItem> */}
    </MainLayout>
  );
}

const useStyle = makeStyles({
  root: { paddingTop: 60 },
});
