import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";

import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { toastShow } from "../../redux/toast/ToastActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import NumberFormat from "react-number-format";

export default function Deposit({}) {
  const classes = useStyle();
  const dispatch = useDispatch();

  const [depositMinKrw, setDepositMinKrw] = useState(0);
  const [virtualList, setvirtualList] = useState([]);
  const [virtualData, setvirtualData] = useState({});

  useEffect(() => {
    dispatch(loadingStart);

    // 가상 계좌 조회

    const didMountApis = [
      // 가상 계좌 조회
      APIS.getVirtualAccount().then((res) => {
        setvirtualList(res.data);
        setvirtualData(res.data.data);
      }),

      // 최소 입금  금액 조회
      APIS.getSharedCodeTrade("DEPOSIT_MIN_KRW").then(
        ({ data: { success, data } }) => {
          if (success) {
            setDepositMinKrw(data.value);
          }
        }
      ),
    ];

    Promise.all(didMountApis).finally(() => dispatch(loadingEnd));

    // 최소 입금 금액 조회
  }, []);
  const copyToClipboard = (text) => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    dispatch(toastShow("가상계좌가 복사되었습니다."));
  };

  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "입금하기" }}>
      <Text className={classes.label}>
        고객님의 가상계좌로 입금하시기 바랍니다.
      </Text>

      {!!depositMinKrw && (
        <Text className={classes.label} style={{ marginTop: 0, fontSize: 16 }}>
          최소 입금금액은{" "}
          <NumberFormat
            value={depositMinKrw}
            displayType={"text"}
            thousandSeparator={true}
          />
          원 입니다. 최소 입금금액 미만 입금시 &quot;수취불가&quot; 로
          표시됩니다.
        </Text>
      )}
      {/*
      <Flex row className={classes.card + " shadow"}>
        <Text className={classes.cardLabel}>등록계좌</Text>
        <Text className={classes.cardValue}>
          {account.bankName} | {account.accountHolder}
          <br />
          <Text font={fonts.notoSansKRMedium} style={{ fontSize: "16px" }}>
            {account.accountNumber}
          </Text>
        </Text>
      </Flex>
      
      <Flex row className={classes.row}>
        <img alt="down" src={images.arrowdown} className={classes.down} />
        <Text className={classes.hint}>계좌이체</Text>
      </Flex>
      */}
      <Flex row className={classes.card + " shadow"}>
        <Text className={classes.cardLabel}>가상계좌</Text>
        <Text className={classes.cardValue}>
          KEB 하나은행(구 외환은행)
          <br />
          <Text font={fonts.notoSansKRMedium} style={{ fontSize: "16px" }}>
            {virtualList.success === false
              ? "가상계좌 발급을 신청해주세요."
              : virtualData.virtualAccountNumber}
          </Text>
        </Text>
      </Flex>

      <Button
        label="가상계좌번호 복사"
        onClick={() => copyToClipboard(virtualData.virtualAccountNumber)}
        className={classes.button}
      />
      <Text className={classes.placeholder}>
        본 가상계좌는 고객님 전용 계좌입니다.
      </Text>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  card: {
    margin: "10px 30px 20px 30px",
    alignItems: "center",
    padding: "16px 0px",
  },
  label: {
    margin: "50px 30px 20px 30px",
    color: colors.text,
    fontSize: "18px",
    wordBreak: "break-word",
  },
  row: {
    alignItems: "center",
    margin: "0px 40px",
    marginBottom: "5px",
  },
  hint: {
    fontSize: "14px",
    lineHeight: 1,
    marginLeft: "10px",
    color: colors.text,
  },
  down: {
    width: "15px",
    height: "20px",
  },
  button: {
    margin: "0px 30px",
  },
  placeholder: {
    fontSize: "12px",
    color: colors.gray,
    margin: "20px 30px",
    alignSelf: "center",
    textAlign: "center",
  },
  cardLabel: {
    color: colors.gray,
    flex: 1,
    textAlign: "center",
    fontSize: "13px",
  },
  cardValue: {
    flex: 3,
    fontSize: "14px",
    color: colors.text,
  },
});
