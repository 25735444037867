import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import StackTopbar from "../../../layouts/basic/StackTopbar";
import MainLayout from "../../../layouts/main/MainLayout";
import { formatTime, numberWithCommas, numFormat } from "../../../libs/utils";
import * as APIS from "../../../libs/apis";
import {
  loadingEnd,
  loadingStart,
} from "../../../redux/loading/LoadingActions";
import ic_ok_green from "../../../assets/images/ic_ok_green.png";
import ic_danger from "../../../assets/images/ic_danger.png";
import ic_phone from "../../../assets/images/ic_phone.png";
import ic_re_return from "../../../assets/images/ic_re_return.png";
import img_none from "../../../assets/images/img_none.png";
import { SAFE_TRADE_STATUS } from "../../../libs/consts";
import BasicLayout from "../../../layouts/basic/BasicLayout";
import {
  actionError,
  actionOpen,
  actionShow,
} from "../../../redux/action/ActionActions";
import { messageShow } from "../../../redux/message/MessageActions";

export default function MySafeTradeSellReturn({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((s) => s.user);
  const state = history?.location?.state || {};
  const { order = {} } = state;

  const handleClickReturn = () => {
    dispatch(
      actionShow(
        "반송 신청하시겠습니까?",
        ["닫기", "반송신청"],
        [
          () => {},
          () => {
            dispatch(loadingStart);
            APIS.deleteMySafeTrade(order.item_id)
              .then(({ data: { success, message } }) => {
                if (success) {
                  dispatch(
                    messageShow("반송 신청되었습니다.", () => history.goBack())
                  );
                } else dispatch(actionError(message));
              })
              .catch(() =>
                dispatch(actionError("알 수 없는 오류가 발생했습니다."))
              )
              .finally(() => dispatch(loadingEnd));
          },
        ]
      )
    );
  };

  const {
    category_name,
    brand,
    name,
    price,
    shop_name,
    shop_address,
    shop_phone,
    penalty,
  } = order;
  return (
    <BasicLayout stackTopbar={{ title: "반송 신청하기", border: true }} white>
      <div className="receipt_wrap">
        <div className="receipt_progress">
          <div className="receipt return">
            <img src={ic_re_return} alt="" />
            <p>
              제품을 판매하지 않고
              <br />
              돌려받으시겠습니까?
            </p>
            <span>
              반송신청을 하시는 경우 감정, 운송, 보관 기타
              <br />
              비용 상당의 반송 수수료가 부과됩니다.
            </span>
            <span className="return_price">
              반송 수수료{" "}
              <span className="spoqa">{numberWithCommas(penalty)}</span>원
            </span>
          </div>
          <div className="bar"></div>
          <div className="receipt_info">
            <h3>제품 접수 정보</h3>
            <table>
              <tr>
                <td>카테고리</td>
                <td>
                  {category_name} &gt; {brand}
                </td>
              </tr>
              <tr>
                <td>제품명</td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>판매 희망가</td>
                <td className="spoqa">{numberWithCommas(price)}</td>
              </tr>
            </table>
          </div>
          <div className="bar"></div>
          <div className="receipt_info">
            <h3>반송지</h3>
            <table>
              <tr>
                <td>성함</td>
                <td>{user.name}</td>
              </tr>
              <tr>
                <td>연락처</td>
                <td>{user.phone}</td>
              </tr>
              <tr>
                <td>반송매장</td>
                <td>
                  {shop_name}
                  <p className="grad">{shop_address}</p>
                  <span className="spoqa orgbt">
                    <img src={ic_phone} />
                    {shop_phone}
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div className="bar"></div>
          <div className="return_btn">
            <p>제품은 접수 매장으로 반송됩니다.</p>
            <div className="return_btn_wrap">
              <button
                onClick={() => {
                  history.goBack();
                }}
              >
                취소
              </button>
              <button onClick={handleClickReturn}>반송 신청</button>
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}
const useStyle = makeStyles({});
