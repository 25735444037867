import React, { useEffect } from "react";
import { postMessage } from "../../libs/utils";

const RNHeader = ({
  visible = true,
  goBack = "DEFAULT",
  title = "",
  onGoBack,
  onload,
}) => {
  //goBack {DEFAULT, EXIT_APP, CUSTOM}

  useEffect(() => {
    postMessage({
      command: "SET_HEADER",
      param: { visible, goBack: onGoBack ? "CUSTOM" : goBack, title },
    });

    if (onGoBack) {
      const listener = (event) => {
        if (event.data === "CUSTOM_GO_BACK") {
          onGoBack();
        }
      };

      if (window.ReactNativeWebView) {
        document.addEventListener("message", listener);
        window.addEventListener("message", listener);

        return () => {
          document.removeEventListener("message", listener);
          window.removeEventListener("message", listener);
        };
      }
    }
  }, [onload]);

  return null;
};

export default RNHeader;
