import { ButtonBase, makeStyles, Snackbar } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { messageShow } from "../../redux/message/MessageActions";
import Password from "./Password";
import NumberFormat from "react-number-format";
import { actionError } from "../../redux/action/ActionActions";
import axios from "axios";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function PaymentPopup({
  onClose,
  method,
  type,
  rows,
  sPrice,
  settype,
  stock,
  seloption,
  mysavePrice,
  mysaveformatPrice,
  mygoldorsilver,
  ChangeDate,
  ChangeTime,
  shop,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [noGold, setNoGold] = useState(false);
  const [noAsset, setNoAsset] = useState(type === "direct-transaction");
  const [noWon, setNoWon] = useState(false);
  const [password, setPassword] = useState(false);
  const history = useHistory();
  const { id, name, phone, securityPassword } = useSelector((s) => s.user);
  const { userId } = useSelector((s) => s.auth);

  //useSelector((s) => console.log(s.user))
  //console.log(shop);

  /*
  useEffect(() => {
    if (history.location.state?.password) {
      dispatch(messageShow("결제가 완료되었습니다.", history.goBack));
    }
  }, [history.location.state?.password]);
  */

  useEffect(() => {
    if (open) {
      let tm = setTimeout(() => {
        clearTimeout(tm);
        onClose();
      }, 1000);
    }
  }, [open]);

  const rock = useRef(false);

  const payFinish = (pass) => {
    if (rock.current) return false;
    rock.current = true;
    dispatch(loadingStart);

    var shopAddress = shop.address + " " + shop.addressDetail;
    var params = {
      deliveryAddress: shopAddress,
      optionValue: seloption,
      qty: stock,
      shopId: shop.id,
      visitDate: ChangeDate,
      visitTime: ChangeTime,
      finPassword: pass,
    };

    APIS.putgoodwithdrawalOrder(params, rows.id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            messageShow(
              "결제가 완료되었습니다.",
              history.push(routes.physicalWithDrawal)
            )
          );
        } else {
          dispatch(messageShow(message));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
        rock.current = false;
      });
  };

  return (
    <Flex className={classes.root}>
      <Flex className={classes.containter}>
        <Text font={fonts.notoSansKRBold} className={classes.label}>
          결제하기
        </Text>
        {(() => {
          switch (type) {
            case "direct-transaction":
              return (
                <>
                  <Flex row className="center">
                    <Text className={classes.hint3}>보유 잔고</Text>
                    <Text
                      className={classes.value2}
                      font={fonts.notoSansKRBold}
                    >
                      9,812.000원
                    </Text>
                  </Flex>
                  <Text font={fonts.notoSansKRBold} className={classes.price}>
                    = 82,890,000원
                  </Text>
                </>
              );
            case "direct-transaction-order":
              return (
                <>
                  <Flex row className={classes.row}>
                    <Text className={classes.hint}>
                      {rows.goldOrSilver === "GOLD"
                        ? "보유 금(GOLD)"
                        : "보유 은(SILVER)"}
                    </Text>
                    <Text
                      className={classes.value}
                      font={fonts.notoSansKRMedium}
                    >
                      {mygoldorsilver}g
                    </Text>
                  </Flex>
                  <Flex row className={classes.row}>
                    <Text className={classes.hint}>보유 원화</Text>
                    <Text
                      className={classes.value}
                      font={fonts.notoSansKRMedium}
                    >
                      {mysaveformatPrice}원
                    </Text>
                  </Flex>
                  <Flex className={classes.divider} />

                  <Flex row className={classes.row}>
                    <Text className={classes.hint2}>
                      {rows.goldOrSilver === "GOLD"
                        ? "결제 금(GOLD)"
                        : "결제 은(SILVER)"}
                    </Text>
                    <Text
                      className={classes.value}
                      font={fonts.notoSansKRMedium}
                    >
                      {rows.gram * stock}g
                    </Text>
                  </Flex>
                  <Flex row className={classes.row}>
                    <Text className={classes.hint2}>인출수수료</Text>
                    <Text
                      className={classes.value}
                      font={fonts.notoSansKRMedium}
                    >
                      <NumberFormat
                        value={rows.fees * stock}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      원
                    </Text>
                  </Flex>
                </>
              );
            default:
              return (
                <>
                  <Flex row className={classes.row}>
                    <Text className={classes.hint}>보유 금(GOLD)</Text>
                    <Text
                      className={classes.value}
                      font={fonts.notoSansKRMedium}
                    >
                      9,812.000g
                    </Text>
                  </Flex>
                  <Flex row className={classes.row}>
                    <Text className={classes.hint}>보유 원화</Text>
                    <Text
                      className={classes.value}
                      font={fonts.notoSansKRMedium}
                    >
                      1,000,000원
                    </Text>
                  </Flex>
                  <Flex className={classes.divider} />

                  <Flex row className={classes.row}>
                    <Text className={classes.hint2}>결제 금(GOLD)</Text>
                    <Text
                      className={classes.value}
                      font={fonts.notoSansKRMedium}
                    >
                      2.02g
                    </Text>
                  </Flex>
                  <Flex row className={classes.row}>
                    <Text className={classes.hint2}>수수료(공임)</Text>
                    <Text
                      className={classes.value}
                      font={fonts.notoSansKRMedium}
                    >
                      50,000원
                    </Text>
                  </Flex>
                </>
              );
          }
        })()}
        {noAsset && (
          <Text font={fonts.notoSansKRMedium} className={classes.message}>
            보유 잔고가 부족합니다.
          </Text>
        )}
        {noGold && (
          <Text font={fonts.notoSansKRMedium} className={classes.message}>
            보유 금(Gold)이 부족합니다.
          </Text>
        )}
        {noWon && (
          <Text font={fonts.notoSansKRMedium} className={classes.message}>
            보유 원화(원)가 부족합니다.
            <br />
            수수료는 원화로 결제해야 합니다.
          </Text>
        )}
        <Flex row className={classes.buttons}>
          <ButtonBase onClick={onClose} className={classes.button1}>
            취소
          </ButtonBase>
          <ButtonBase
            disabled={open}
            onClick={() => {
              if (
                type === "direct-transaction" ||
                type === "direct-transaction-order"
              ) {
                setPassword(true);
                return;
              }
              if (!noWon && !noGold) {
                setNoGold(true);
              } else if (!noWon) {
                setNoWon(true);
                setNoGold(false);
              } else if (noWon) {
                history.push(routes.mypage);
              } else {
                setOpen(true);
              }
            }}
            className={classes.button2}
          >
            {noGold ? "금 매수하기" : noWon ? "충전하기" : "결제"}
          </ButtonBase>
        </Flex>
      </Flex>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={onClose}
        message={"주문이 취소 되었습니다."}
      />
      {password && (
        <Password
          onClose={() => {
            setPassword(false);
          }}
          onClick={(pass) => payFinish(pass)}
        />
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    zIndex: 11,
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: "flex-end",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  containter: {
    backgroundColor: colors.white,
    padding: "30px 20px",
    alignItems: "center",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  label: {
    fontSize: "20px",
    marginBottom: "20px",
    color: colors.black,
  },
  row: {
    alignSelf: "center",
    whiteSpace: "nowrap",
    alignItems: "center",
    minWidth: "70%",
    marginTop: "6px",
  },
  hint: {
    color: "#999999",
    fontSize: "14px",
    flex: 1,
  },
  hint3: {
    color: "#999999",
    fontSize: "14px",
  },
  hint2: {
    color: "#333",
    fontSize: "14px",
    flex: 1,
  },
  divider: {
    minWidth: "70%",
    alignSelf: "center",
    marginTop: "15px",
    marginBottom: "10px",
    height: "1px",
    backgroundColor: "#eee",
  },
  value: {
    flex: 1,
    textAlign: "right",
    color: "#000",
    fontSize: "14px",
  },
  value2: {
    textAlign: "right",
    color: "#000",
    fontSize: "14px",
    marginLeft: "3px",
  },
  buttons: {
    alignSelf: "stretch",
    alignItems: "center",
    marginTop: "30px",
  },
  button1: {
    borderRadius: "5px",
    padding: "10px 0px",
    textAlign: "center",
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    flex: 1,
    marginRight: "10px",
    fontSize: "16px",
    backgroundColor: "#999",
  },
  button2: {
    borderRadius: "5px",
    padding: "10px 0px",
    textAlign: "center",
    backgroundColor: colors.primary,
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    flex: 1,
    fontSize: "16px",
  },
  message: {
    fontSize: "13px",
    textAlign: "center",
    color: colors.textRed,
    marginTop: "16px",
  },
  price: {
    fontSize: 20,
    color: "#333",
  },
});
