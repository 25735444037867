import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import ImageButton from "../../components/image-button/ImageButton";
import Image from "../../components/image/Image";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import queryString from "query-string";
import { objToQueryStr } from "../../services/utils";
import routes from "../../libs/routes";
import AppleLogin from "react-apple-login";
import consts from "../../libs/consts";
import jwtDecode from "jwt-decode";
import qs from "qs";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { postMessage } from "../../libs/utils";

export default function SignIn({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const [authUrls, setAuthUrls] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getOauthUrls()
      .then(({ data }) => {
        setAuthUrls(data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => dispatch(loadingEnd));
  }, []);

  const handleClickSingIn = (type) => {
    return () => {
      if (authUrls[type]) {
        window.location.href = authUrls[type];

        postMessage({
          command: "SET_HEADER",
          param: { visible: true, title: type },
        });
      }
    };
  };

  const handleClickClose = () => {
    history.goBack();
  };

  return (
    <Flex className={classes.root}>
      <ImageButton
        onClick={handleClickClose}
        className={classes.close}
        align="right"
        src={images.close}
      />
      <Flex className={classes.top}>
        <Text font={fonts.notoSansKRLight} className={classes.label}>
          온라인 금거래 서비스
        </Text>
        <Image src={images.logo} width="50vw" />
      </Flex>
      <Flex className={classes.bottom}>
        <Text font={fonts.notoSansKRLight} className={classes.sns}>
          SNS간편로그인으로 금방금방 서비스를 이용하세요.
        </Text>
        {/* <div className={classes.social2} id="naverIdLogin"></div> */}
        <ButtonBase
          onClick={handleClickSingIn("NAVER")}
          style={{ backgroundColor: "#21c603" }}
          className={classes.social}
        >
          <Image src={images.naver} className={classes.socialIcon} />
          <Text
            className={classes.socialLabel}
            font={fonts.notoSansKRMedium}
            style={{ color: "#fff" }}
          >
            네이버로 시작하기
          </Text>
        </ButtonBase>
        <ButtonBase
          onClick={handleClickSingIn("KAKAO")}
          style={{ backgroundColor: "#f9e000" }}
          className={classes.social}
        >
          <Image src={images.kakaotalk} className={classes.socialIcon} />
          <Text
            className={classes.socialLabel}
            font={fonts.notoSansKRMedium}
            style={{ olor: "#3b1c1c" }}
          >
            카카오톡으로 시작하기
          </Text>
        </ButtonBase>

        <ButtonBase
          onClick={handleClickSingIn("GOOGLE")}
          //   disabled={props.disabled}
          style={{ backgroundColor: "#fff" }}
          className={classes.social}
        >
          <Image src={images.google} className={classes.socialIcon} />
          <Text
            className={classes.socialLabel}
            font={fonts.notoSansKRMedium}
            style={{ color: "#000" }}
          >
            구글로 시작하기
          </Text>
        </ButtonBase>

        <ButtonBase
          onClick={handleClickSingIn("APPLE")}
          style={{ backgroundColor: "#000000" }}
          className={classes.social}
        >
          <Image src={images.apple} className={classes.socialIcon} />
          <Text
            className={classes.socialLabel}
            font={fonts.notoSansKRMedium}
            style={{ color: "#fff" }}
          >
            Apple로 시작하기
          </Text>
        </ButtonBase>

        <ButtonBase
          onClick={() => {
            history.replace(routes.signInId);
          }}
          style={{ backgroundColor: colors.primary }}
          className={classes.social}
        >
          <Flex className={classes.socialIcon} />
          <Text
            className={classes.socialLabel}
            font={fonts.notoSansKRMedium}
            style={{ color: "#fff" }}
          >
            아이디로 시작하기
          </Text>
        </ButtonBase>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    flex: 1,
    backgroundColor: "rgb(240,240,240)",
    // position: "fixed",
    // left: 0,
    // top: 0,
    // right: 0,
    // bottom: 0,
  },
  close: {
    padding: "20px 13px",
  },
  top: { alignItems: "center", paddingTop: 60 },
  bottom: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    paddingBottom: 60,
  },
  label: {
    color: colors.black,
    fontSize: "4.5vw",
    marginBottom: 10,
  },
  sns: {
    color: colors.text,
    marginTop: "16px",
    fontSize: "3vw",
  },
  social2: {
    //marginTop: "10px",
    //margin: "0px 36px",
    width: "calc(100% - 72px)",
    display: "none",
  },
  social: {
    alignSelf: "stretch",
    padding: "12px 17px",
    display: "flex",
    alignItems: "center",
    margin: "0px 36px",
    marginTop: "10px",
    textAlign: "center",
    borderRadius: 5,
  },
  idb: {
    marginLeft: 36,
    marginRight: 36,
    backgroundColor: colors.primary,
    width: "calc(100% - 72px)",
    padding: "12px 17px",
    color: "#fff",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: 5,
  },
  closeText: {
    margin: "26px 0px",
    alignSelf: "center",
    borderBottom: "1px solid " + colors.text,
    padding: "4px 0px",
  },

  socialLabel: {
    fontSize: "3.3vw",
    flex: 1,
  },
  socialIcon: { width: "5vw", position: "absolute", left: 17 },
});
