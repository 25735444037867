// Actions
const INIT = "safe-trade/order/INIT";
const UPDATE = "safe-trade/order/UPDATE";

const INIT_STATE = {
  category: "", // 카테고리 ID
  category_detail: "", // 카테고리 상세 ID
  name: "", // 제품명(모델명)
  out_date: "", // 제품출시년도
  buy_price: "", // 구매 가격
  place: "", // 구매처
  buy_date: "", // 구입년도
  status: "새상품", // 제품 상태 (새상품, 매우 좋음, 좋음, 보통)
  price: "", // 판매 희망가
  front: null, // 정면 이미지
  side: null, // 좌측면 이미지
  back: null, // 우측면 이미지
  model: null, // 모델명 이미지
  is_contract: false, // 보증서 유무
  is_parts: false, // 구성품 유무
  contract: null, // 보증서 이미지
  parts: null, // 구성품 이미지
  memo: "",
  shop: null, // 판매 접수 매장
  reservation: "", // 방문 희망일
  reservation_time: "", // 방문 희망시간
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case INIT:
      return INIT_STATE;
    case UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// Action Creator
export function initSafeTradeOrder() {
  return { type: INIT };
}

export function updateSafeTradeOrder(payload) {
  return { type: UPDATE, payload };
}
