import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Text from "../../components/text/Text";
import ClosePopup from "../../layouts/close-popup/ClosePopup";
import sample1 from "../../assets/images/sample1.png";
import fonts from "../../libs/fonts";
import colors from "../../libs/colors";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function NoticeDetail({ onClose }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  const [itemData, setItemData] = useState({});
  const { serviceImages = [] } = itemData;
  const imageUrl = serviceImages.find((item) => item.imageType === "DETAIL");

  const convertDateFormat = (date) => {
    if (date === "-") {
      return "-";
    } else {
      const convertDate = date?.replace(/-/g, "/");
      const parseDate = new Date(convertDate);
      var year = parseDate.getFullYear();
      var month = parseDate.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      var day = parseDate.getDate();
      day = day >= 10 ? day : "0" + day;
      return parseDate ? [year, month, day].join("-") : "-";
    }
  };

  useEffect(() => {
    dispatch(loadingStart);
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url: consts.apiBaseUrl + `/api/v1/user/notice/${state.id}`,
        },
        (err, res) => {
          dispatch(loadingEnd);
          if (err) {
            dispatch(messageError(err));
          } else {
            const data = res.data.data;
            setItemData(data);
          }
        }
      )
    );
  }, []);
  return (
    <ClosePopup title="공지사항 상세" onClose={onClose}>
      <span
        style={{
          background: "#dfe6ef",
          height: "auto",
          paddingLeft: 20,
          paddingBottom: 10,
          paddingRight: 20,
          paddingTop: 10,
        }}
      >
        <Text
          font={fonts.notoSansKRMedium}
          className={classes.title}
          style={{ color: "#001b8e", fontWeight: "bold" }}
        >
          {itemData.subject}
        </Text>
        <br />
        <Text className={classes.date}>{convertDateFormat(itemData.startDate)}</Text>
      </span>
      <Text className={classes.desc}>
        <span
          className="notice_content"
          dangerouslySetInnerHTML={{
            __html: (itemData.content || "").replace(/\n/g, "<br />"),
          }}
        />
      </Text>
      {serviceImages.length > 0 && imageUrl && (
        <div>
          <img
            className={classes.noticeImage}
            src={
              serviceImages.length > 0 &&
              imageUrl &&
              `${consts.s3BaseUrl}${imageUrl.path}`
            }
            alt="공지사항이미지"
          />
        </div>
      )}
    </ClosePopup>
  );
}

const useStyle = makeStyles({
  sample: {
    backgroundColor: "#f1f1f1",
    width: "100%",
    marginTop: "20px",
    height: "250px",
  },
  title: {
    fontSize: "15px",
    color: colors.text,
  },
  date: {
    color: "#999",
    fontSize: "13px",
  },
  desc: {
    color: colors.text,
    margin: "10px 10px 0px 10px",
    fontSize: "14px",
    widtth: "100%",
    img: {
      width: "100%",
    },
  },
  noticeImage: {
    width: "100%",
    padding: "5px 10px 0px 10px",
  },
});
