var BaseURL;
if (process.env.NODE_ENV === "development") {
  BaseURL = "http://localhost:8181";
} else {
  BaseURL = "http://52.78.242.118:8181";
}

BaseURL = process.env.REACT_APP_API_URL;

export default {
  token: "gumtoken",
  drawerMinWidth: 250,
  accessToken: "goldmarket_accessToken",
  apiBaseUrl: BaseURL,
  s3BaseUrl: process.env.REACT_APP_S3_BASE_URL,
};

export const SERVICE_TYPE = process.env.REACT_APP_SERVICE_TYPE;

const STORAGE_KEY_PREFIX = "kumbang.";
export const STORAGE_KEY = {
  ACCESS_TOKEN: STORAGE_KEY_PREFIX + "accessToken",
  FIRST_LOAD: STORAGE_KEY_PREFIX + "firstLoad",
  NO_COACHING: STORAGE_KEY_PREFIX + "noCoaching",
};

export const SAFE_TRADE_STATUS = {
  INBOUND: ["INBOUND_WAIT", "INBOUND_COMPLETE", "INBOUND_CANCEL"],
  VERIFY: ["VERIFY_WAIT", "VERIFY_COMPLETE"],
  SELL: ["RETURN"],
};
