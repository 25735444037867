import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ic_secret from "../../assets/images/ic_secret.png";
import text_ic_ok from "../../assets/images/text_ic_ok.png";
import text_ic_none from "../../assets/images/text_ic_none.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import images from "../../libs/images";
import routes from "../../libs/routes";
import consts from "../../libs/consts";
import {
  encodeFileToBase64,
  formatTime,
  numberWithCommas,
} from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import {
  initSafeTradeOrder,
  updateSafeTradeOrder,
} from "../../redux/safe-trade/safeTradeOrder";
import { initFindShop } from "../../redux/common/findShop";
import AspectRatioBox from "../../components/common/AspectRatioBox";
import { Swiper, SwiperSlide } from "swiper/react";

export default function SafeTradeItem({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const itemId = state?.id;

  // tab touch 시 자동 focus 기능을 사용하기 위함
  const tabRef = useRef(null);

  // 상품 이미지
  const [itemImages, setItemImages] = useState([]);

  // 상품 정보
  const [item, setItem] = useState({});

  // 상품 상세 설명
  const [detail, setDetail] = useState("");

  // 상품 기본 정보
  const [infos, setInfos] = useState([]);

  const [swiperIndex, setSwiperIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (!itemId) {
      history.goBack();
      return false;
    }

    dispatch(loadingStart);

    const getImage = APIS.getSafeTradeItemImage(itemId).then(
      ({ data: { success, data } }) => success && setItemImages(data)
    );
    const getItem = APIS.getSafeTradeItem(itemId).then(
      ({ data: { success, data } }) => success && setItem(data)
    );
    const getDetail = APIS.getSafeTradeItemDetail(itemId).then(
      ({ data: { success, data } }) =>
        success && setDetail(data?.product_detail || "")
    );
    const getInfo = APIS.getSafeTradeItemInfo(itemId).then(
      ({ data: { success, data } }) => success && setInfos(data)
    );

    Promise.all([getImage, getItem, getDetail, getInfo]).finally(() =>
      dispatch(loadingEnd)
    );
  }, []);

  const {
    created_at,
    price,
    brand,
    name,
    serial,
    out_date,
    state: itemState,
    product_price,
    is_closed,
    fee,
  } = item;

  return (
    <BasicLayout stackTopbar={{ title: "상품 상세" }}>
      <div className="detail_wrap">
        <AspectRatioBox>
          <Swiper
            className="product_img"
            onRealIndexChange={(swiper) => setSwiperIndex(swiper.realIndex)}
          >
            {itemImages.map(({ image }, i) => (
              <SwiperSlide key={i.toString()}>
                <img src={`${consts.s3BaseUrl}/${image}`} />
              </SwiperSlide>
            ))}
            {itemImages?.length > 1 && (
              <div className="ing_bar" style={{ zIndex: 1 }}>
                <div
                  className="per33"
                  style={{
                    width: `${100 / itemImages.length}%`,
                    left: `${(100 / itemImages.length) * swiperIndex}%`,
                  }}
                />
              </div>
            )}
          </Swiper>
        </AspectRatioBox>

        <div className="detail_info">
          <div className="detail_info_wrap">
            <h3 className="info_brand">{brand}</h3>
            <p className="info_code spoqa">No.{itemId}</p>
            <p className="info_name_e"></p>
            <p className="info_name_f">
              {name}
              <span className="info_price">
                <span className="pdnm">판매가</span>
                <span className="spoqa">{numberWithCommas(price)}</span>원
              </span>
            </p>

            <p className="info_date">
              등록{" "}
              <span className="spoqa">
                {formatTime(created_at, "YYYY.MM.DD")}
              </span>
              <span className="info_price">
                <span className="pdnm">수수료</span>
                <span className="spoqa">{numberWithCommas(fee)}</span>원
              </span>
            </p>
            <p className="info_price2">
              <span className="pdnm">구매 금액</span>
              <span className="spoqa">{numberWithCommas(price + fee)}</span>원
            </p>
          </div>
          <div className="info_noti">
            한국금거래소의 감정평가를 통해 직거래되는 상품입니다.
          </div>
          <div className="info_tb">
            <div className="info_tb_tit">상품정보</div>
            <table>
              <tr>
                <td>제조사</td>
                <td>{brand}</td>
              </tr>
              <tr>
                <td>제품명</td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>모델번호</td>
                <td className="spoqa">{serial}</td>
              </tr>
              <tr>
                <td>제조 출시년도</td>
                <td>{!!out_date && formatTime(out_date, "YYYY년")}</td>
              </tr>
              <tr>
                <td>상품 상태</td>
                <td>{itemState}</td>
              </tr>
              <tr>
                <td>시중 신품가격</td>
                <td>
                  {product_price && (
                    <>
                      <span className="spoqa">
                        {numberWithCommas(product_price)}
                      </span>
                      원
                    </>
                  )}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          ref={tabRef}
          className="product_detail"
          style={{ minHeight: window.innerHeight - 48 }}
        >
          <ul
            className="detail_menu2"
            onClick={() => {
              tabRef.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <li
              className={tabIndex === 0 ? "on" : ""}
              onClick={() => setTabIndex(0)}
            >
              상세설명
            </li>
            <li
              className={tabIndex === 1 ? "on" : ""}
              onClick={() => setTabIndex(1)}
            >
              기본정보
            </li>
            <li
              className={tabIndex === 2 ? "on" : ""}
              onClick={() => setTabIndex(2)}
            >
              상품문의
            </li>
          </ul>
          {(() => {
            switch (tabIndex) {
              case 0:
                return (
                  <div className="detail_img">
                    {detail.split("").map((s) => (s === "\n" ? <br /> : s))}
                  </div>
                );

              case 1:
                return (
                  <div className="detail_data">
                    <table>
                      {infos.map(({ key, value }, i) => (
                        <tr key={i.toString()}>
                          <td>{key}</td>
                          <td>{value}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                );
              case 2:
                return <QnASection itemId={itemId} navigate={navigate} />;
            }
          })()}
        </div>

        {is_closed ? (
          <div className="pur_btn ok">판매완료</div>
        ) : (
          <div className="pur_btn">
            <ul>
              {/* <li>미리보고 구매하기</li> */}
              <li
                className="on"
                style={{ width: "auto", flex: 1 }}
                onClick={() => navigate(routes.safeTradeBuy, { id: itemId })}
              >
                구매하기
              </li>
            </ul>
          </div>
        )}
      </div>
    </BasicLayout>
  );
}

const QnASection = ({ itemId, navigate }) => {
  const history = useHistory();

  const [qnaList, setQnaList] = useState([]);

  useEffect(() => {
    APIS.getSafeTradeQna(itemId).then(({ data: { success, data } }) => {
      if (success) {
        setQnaList(data);
      }
    });
  }, []);
  console.log(qnaList);
  return (
    <div className="inquiry_wrap">
      <div className="inquiry_board">
        <h3>상품 문의 게시판 입니다.</h3>
        <p>문의하신 내용은 상담사가 확인 후 답변 드립니다.</p>
        <button onClick={() => navigate(routes.safeTradeQna, { itemId })}>
          문의하기 작성
        </button>
      </div>
      <div className="bar"></div>
      <div className="receipt_info acco">
        <h3>문의 내용</h3>

        {qnaList.map(
          (
            { created_at, is_answered, is_my_qna, is_secret, question, answer },
            i
          ) => {
            const canRead = is_my_qna || !is_secret;
            return (
              <div className="accordion vertical" key={i.toString()}>
                <ul>
                  <li>
                    {canRead && <div className="btn_cx_down" />}

                    <input
                      type="checkbox"
                      id={`checkbox-${i}`}
                      name="checkbox-accordion"
                    />
                    <label htmlFor={`checkbox-${i}`}>
                      <div className="text_icon">
                        <img
                          src={is_answered ? text_ic_ok : text_ic_none}
                          alt=""
                        />
                        <br />
                        {is_answered ? "답변" : "미답변"}
                      </div>
                      <div className={`iquiry_tit ${is_secret ? "sec" : ""}`}>
                        <h3>
                          {is_secret && <img src={ic_secret} />}
                          {!is_my_qna && is_secret
                            ? "비밀글 입니다."
                            : question}
                        </h3>
                        <p className="spoqa">
                          {formatTime(created_at, "YYYY.MM.DD / HH:mm")}
                        </p>
                      </div>
                    </label>
                    {canRead && (
                      <div className="content">
                        <div className="btn_cx_up" />
                        <div className="iquiry_text">
                          <div className="iquiry_qs">{question}</div>
                          {is_answered ? (
                            <div className="iquiry_as">
                              <span className="answer">답변</span>
                              <br />
                              {answer}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
const useStyle = makeStyles({});
