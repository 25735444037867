import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import ListItem from "./ListItem";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../libs/apis";
import { parsePhoneNumber } from "../../libs/utils";
import Modal from "../../components/modal/Modal";
import { PhoneInTalkOutlined } from "@material-ui/icons";

export default function EditProfile({}) {
  const classes = useStyle();
  const history = useHistory();

  const { name: authName } = useSelector((s) => s.auth);
  const { name, email = "", phone = "" } = useSelector((s) => s.user);

  const [mainAddress, setMainAddress] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [resignDays, setResignDays] = useState(30);
  const { address } = mainAddress;

  useEffect(() => {
    APIS.getUserAddress().then(({ data = [] }) => {
      const userMainAddress = data.find(({ main }) => main);
      userMainAddress && setMainAddress(userMainAddress);
    });

    APIS.getSharedCodeConfig("USER_RESIGNUP_DAYS").then(
      ({ data: { success, data } }) => {
        success && setResignDays(data?.value);
      }
    );
  }, []);
  return (
    <BasicLayout
      stackTopbar={{
        title: "회원정보 수정",
        border: true,
      }}
    >
      <Flex className={classes.header}>
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          {name || authName}
          <Text>님</Text>
        </Text>
        {email && <Text className={classes.email}>{email}</Text>}
      </Flex>
      <ListItem
        onClick={() => {
          history.push(routes.setAddress, { mainAddress });
        }}
        sub={address}
      >
        주소(배송지)
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.selfVerification, {
            purpose: "UPDATE_PHONENUMBER",
          });
        }}
        sub={parsePhoneNumber(phone, "-")}
      >
        휴대폰번호
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.authBankAccount, { purpose: "UPDATE" });
        }}
      >
        계좌 변경하기
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.selfVerification, {
            purpose: "UPDATE_SECURE_PASSWORD",
          });
        }}
      >
        결제 비밀번호 변경
      </ListItem>
      <ListItem onClick={() => setModalVisible(true)}>탈퇴하기</ListItem>
      {modalVisible && (
        <WithdrawalModal
          onClose={() => setModalVisible(false)}
          resignDays={resignDays}
        />
      )}
    </BasicLayout>
  );
}

const WithdrawalModal = ({ onClose, resignDays }) => {
  const classes = useStyle();
  return (
    <Modal onClickBackground={onClose}>
      <Flex
        className={classes.withdrawalWrap}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Text style={{ fontSize: 24 }}>탈퇴하기</Text>
        <Text style={{ fontSize: 16, color: "gray", marginTop: 10 }}>
          ※ 탈퇴 후 {resignDays}일간 재가입이 불가능합니다.
        </Text>
        <Text style={{ fontSize: 16, color: "gray", marginTop: 5 }}>
          ※ 탈퇴 처리는 고객센터로 연락하시면 익일 내 처리됩니다.
        </Text>
        <Text style={{ fontSize: 16, color: "gray", marginTop: 20 }}>
          금방금방 고객센터
        </Text>
        <Text style={{ fontSize: 26, fontWeight: "bold" }}>1670-8978</Text>
        <ButtonBase
          className={classes.btnCall}
          onClick={() => {
            window.location.href = "tel:16708978";
          }}
        >
          <PhoneInTalkOutlined fontSize="large" />
          <Text style={{ marginLeft: 8 }}>전화연결</Text>
        </ButtonBase>
      </Flex>
    </Modal>
  );
};

const useStyle = makeStyles({
  header: { padding: "28px 20px", borderBottom: "3px solid #eee" },
  label: {
    lineHeight: 1,
    color: colors.text,
    fontSize: "19px",
    " & span": {
      color: colors.gray,
      fontSize: "12px",
    },
  },
  email: {
    fontSize: 14,
    color: colors.text,
    marginTop: 6,
  },
  withdrawalWrap: {
    flex: 1,
    alignSelf: "flex-end",
    boxSizing: "border-box",
    position: "relative",
    backgroundColor: "#fff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: "30px 0",
    alignItems: "center",
  },
  btnCall: {
    width: 200,
    backgroundColor: "#FC5710",
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    padding: 5,
    borderRadius: 5,
    marginTop: 10,
  },
});
