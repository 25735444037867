import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ic_check_white from "../../assets/images/ic_check_white.png";
import img_ex_all from "../../assets/images/img ex_all.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { initSafeTradeOrder } from "../../redux/safe-trade/safeTradeOrder";
import * as APIS from "../../libs/apis";
import { actionError } from "../../redux/action/ActionActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function SafeTradeQna({ navigate }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const locationState = history.location.state || {};

  const { itemId } = locationState;
  const [content, setContent] = useState("");
  const [isSecret, setIsSecret] = useState(false);

  const strToByte = (str) => {
    let byte = 0;

    for (let i = 0; i < str.length; i++) {
      if (encodeURIComponent(str.charAt(i)).length > 4) byte += 2;
      else byte++;
    }

    return byte;
  };

  const handleClickRegister = () => {
    if (!content) {
      dispatch(actionError("내용을 입력해주세요."));
    } else {
      dispatch(loadingStart);
      APIS.postSafeTradeQna(itemId, {
        question: content,
        is_secret: isSecret,
      })
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(toastShow("문의 글이 등록 되었습니다."));
            history.goBack();
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  return (
    <BasicLayout stackTopbar={{ title: "문의하기", border: true }}>
      <div className="inquiry_wrap textarea">
        <h3>문의 내용을 남겨주세요.</h3>
        <textarea
          placeholder="문의하실 내용을 입력해 주세요.(1000자 이하)"
          className={classes.textarea}
          style={{ color: "#444444" }}
          value={content}
          onChange={(e) =>
            strToByte(e.target.value) <= 1000 && setContent(e.target.value)
          }
        />
        <div>
          <p
            onClick={() => setIsSecret(!isSecret)}
            style={{ color: isSecret ? "#404040" : "#909090" }}
          >
            <input type="checkbox" checked={isSecret} readOnly /> 비밀글
          </p>
          <span>
            <span className="spoqa">{strToByte(content)}/1000</span>bytes
          </span>
        </div>
      </div>
      <div className="iquiry_btn">
        <button className="cancel" onClick={() => history.goBack()}>
          취소
        </button>
        <button className="okay" onClick={handleClickRegister}>
          확인
        </button>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  textarea: { "&::placeholder": { color: "#909090", fontSize: 14 } },
});
