import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";

import { useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import ListItem from "./ListItem";
import Topbar from "./Topbar";
import NumberFormat from "react-number-format";
import { actionError } from "../../redux/action/ActionActions";
import { formatTime } from "../../libs/utils";

export default function DepositWithdrawal({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { signed, id } = useSelector((s) => s.user);
  const { userId } = useSelector((s) => s.auth);
  const [account, setAccount] = useState({
    success: false,
  });

  const [accountList, setaccountList] = useState([]);
  const [virtualList, setvirtualList] = useState([]);
  const [virtualData, setvirtualData] = useState({});

  useEffect(() => {
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/withdrawal/account?userId=" +
            userId,
        },
        (err, res) => {
          if (err) {
            //dispatch(actionError(err));
          } else {
            setAccount(res.data);
          }
        }
      )
    );

    dispatch(
      axiosDispatch(
        {
          method: "get",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/withdrawal/list?userId=" +
            userId,
        },
        (err, res) => {
          if (err) {
            //dispatch(actionError(err));
          } else {
            setaccountList(res.data);
          }
        }
      )
    );

    dispatch(
      axiosDispatch(
        {
          method: "get",
          url: consts.apiBaseUrl + "/api/v1/user/asset/virtual",
        },
        (err, res) => {
          if (err) {
            //dispatch(actionError(err));
          } else {
            setvirtualList(res.data);
            setvirtualData(res.data.data);
          }
        }
      )
    );
  }, []);

  const isBankCheck = () => {
    const current = formatTime(new Date(), "HHmm");
    return current < "0100" || current > "2350";
  };

  return (
    <MainLayout topbar={<Topbar />} white>
      <Flex className={classes.card + " shadow"}>
        <Flex row className={classes.cardHeader}>
          <Text className={classes.label1}>
            보유 잔고
            <br />
            <Text className={classes.label2} font={fonts.notoSansKRBold}>
              <NumberFormat
                value={account.success === true ? account.data.wons : 0}
                displayType={"text"}
                thousandSeparator={true}
              />
              원
            </Text>
          </Text>
          <Flex row>
            {account.success === false ? (
              <ButtonMini
                onClick={() => {
                  dispatch(actionError("계좌등록을 먼저 해주세요."));
                }}
                label="출금"
              />
            ) : (
              <>
                <ButtonMini
                  onClick={() => {
                    if (isBankCheck()) {
                      dispatch(
                        actionError(
                          "출금 가능시간 내에(01:00 ~ 23:50) 이용하실 수 있습니다."
                        )
                      );
                    } else {
                      history.push(routes.withdrawal);
                    }
                  }}
                  label="출금"
                />
              </>
            )}
            {virtualList.success === false ? (
              <ButtonMini
                onClick={() => {
                  dispatch(actionError("가상계좌를 먼저 발급받아주세요."));
                }}
                border
                className={classes.de}
                label="입금"
              />
            ) : (
              <ButtonMini
                onClick={() => {
                  history.push(routes.deposit);
                }}
                border
                className={classes.de}
                label="입금"
              />
            )}
          </Flex>
        </Flex>
        <Flex row className={classes.cardHeader}>
          <Text className={classes.label1}>
            사용 가능　
            <Text className={classes.label2} font={fonts.notoSansKRBold}>
              <NumberFormat
                value={account.success === true ? account.data.availWons : 0}
                displayType={"text"}
                thousandSeparator={true}
              />
              원
            </Text>
          </Text>
        </Flex>

        <Flex row className={classes.row}>
          <Text className={classes.rowLabel}>출금계좌</Text>
          <Text font={fonts.notoSansKRMedium} className={classes.bank}>
            {account.success === false ? (
              "출금계좌를 등록해 주세요."
            ) : (
              <>
                {account.data.bankName} {account.data.accountNumber}
              </>
            )}
          </Text>
        </Flex>
        {account.success === false && (
          <ButtonBase
            className={classes.change}
            onClick={() => {
              history.push(routes.authBankAccount, { purpose: "UPDATE" });
            }}
          >
            계좌등록
          </ButtonBase>
        )}

        <Flex row className={classes.row}>
          <Text className={classes.rowLabel}>입금 가상 계좌</Text>
          <Text font={fonts.notoSansKRMedium} className={classes.bank}>
            {virtualList.success === false
              ? "가상계좌 발급을 신청해주세요."
              : "KEB 하나은행(구 외환은행) " + virtualData.virtualAccountNumber}
          </Text>
        </Flex>
        {virtualList.success === false && (
          <ButtonBase
            className={classes.change}
            onClick={() => {
              history.push(routes.virtualOrder);
            }}
          >
            계좌발급
          </ButtonBase>
        )}
      </Flex>

      <Flex className={classes.listHeader}>
        <Text className={classes.value2}>
          총{" "}
          <Text font={fonts.notoSansKRMedium} className={classes.value}>
            <NumberFormat
              value={accountList.length}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Text>
          건
        </Text>
      </Flex>
      {accountList.map((row, i) => {
        return (
          <>
            {row.depositOrWithdraw === "WITHDRAW" ? (
              <ListItem rows={row} />
            ) : (
              <ListItem rows={row} />
            )}
          </>
        );
      })}
    </MainLayout>
  );
}

const useStyle = makeStyles({
  card: {
    padding: "20px 16px",
    margin: "30px 16px 16px 16px",
  },
  de: {
    marginLeft: "5px",
  },
  label1: { color: colors.gray, fontSize: "12px" },
  label2: { color: colors.text, fontSize: "20px" },
  cardHeader: {
    alignItems: "center",
    marginBottom: 25,
    justifyContent: "space-between",
  },
  row: {
    alignItems: "center",
    marginTop: "5px",
  },
  rowLabel: {
    fontSize: "13px",
    color: colors.gray,
  },
  bank: {
    color: colors.text,
    fontSize: "13px",
    marginLeft: "6px",
  },
  change: {
    fontFamily: fonts.notoSansKRMedium,
    borderBottom: "1px solid #333",
    color: colors.text,
    fontSize: "13px",
    marginTop: "-25px",
    padding: "4px 0px",
    alignSelf: "flex-end",
  },
  listHeader: {
    padding: "8px 30px ",
    borderBottom: "1px solid #eeeeee",
  },
  value: {
    color: colors.text,
    fontSize: "13px",
  },
  value2: {
    fontSize: "13px",
    color: colors.gray,
  },
});
