import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import images from "../../libs/images";

export default function StackTopbar({
  onGoBack,
  close,
  option,
  title,
  border,
  style = {},
}) {
  const classes = useStyle();
  const history = useHistory();
  return (
    <Flex
      className={`${classes.root}`}
      style={{
        borderBottom: border ? "1px solid #eee" : "",
        background: "#fff",
        ...style,
      }}
      row
    >
      <ButtonBase
        onClick={() => {
          if (onGoBack) {
            onGoBack();
          } else {
            history.goBack();
          }
        }}
        className={classes.backButton}
      >
        <img
          alt="back"
          src={close ? images.close : images.back}
          className={classes.back}
          style={{
            width: close ? "18px" : "8px",
          }}
        />
      </ButtonBase>
      <Text className={classes.title}>{title}</Text>

      {option}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    height: "60px",
    alignItems: "center",
  },
  back: {
    width: "8px",
    height: "18px",
    objectFit: "contain",
  },
  backButton: {
    padding: "18px 16px",
  },
  title: {
    flex: 1,
    color: "#000",
    fontSize: 18,
    lineHeight: 1,
  },
});
