import { ButtonBase, makeStyles } from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ic_check_white from "../../assets/images/ic_check_white.png";
import ic_close from "../../assets/images/ic_close.png";
import menu_bar from "../../assets/images/menu_bar.png";
import menu_gallery from "../../assets/images/menu_gallery.png";
import menu_list from "../../assets/images/menu_list.png";
import ic_search from "../../assets/images/search.png";
import updown_gr from "../../assets/images/updown_gr.png";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import * as APIS from "../../libs/apis";
import consts from "../../libs/consts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { formatTime, numberWithCommas } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

const SORT_TYPE = [
  { name: "최신순", value: "new" },
  { name: "높은 가격순", value: "up" },
  { name: "낮은 가격순", value: "down" },
];
const INIT_MODAL_STATE = {
  visible: false,
  data: [],
  onChange: () => {},
};
export default function SafeTradeList({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const user = useSelector((state) => state.user) || {};

  const [categories, setCategories] = useState([{ name: "전체", value: "" }]);
  const [filter, setFilter] = useState({
    category_id: "",
    sort: "new",
    keyword: "",
    page: 0,
    isLast: false,
    isClosed: false,
  });

  const [isGallery, setIsGallery] = useState(false);

  const [modalState, setModalState] = useState(INIT_MODAL_STATE);

  const [items, setItems] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const loader = useRef(null);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getSafeTradeCategory()
      .then(({ data: { success, data = [] } }) => {
        success &&
          setCategories([
            { name: "전체", value: "" },
            ...data
              .sort((a, b) => a.sort - b.sort)
              .map(({ name, id }) => ({ name, value: id })),
          ]);
      })
      .finally(() => dispatch(loadingEnd));
  }, []);

  useEffect(() => {
    !filter.isLast && searchList();
  }, [filter.category_id, filter.sort, filter.keyword, filter.page]);

  const filteredItems = useMemo(
    () =>
      items.filter(({ is_closed }) => (filter.isClosed ? !is_closed : true)),
    [items, filter.isClosed]
  );

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        if (filteredItems.length) {
          searchMore();
        }
      }
    },
    [filteredItems.length]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => observer.disconnect();
  }, [handleObserver]);

  const searchMore = () => {
    if (!filter.isLast) {
      setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const searchList = () => {
    dispatch(loadingStart);
    APIS.getSafeTradeList(filter)
      .then(({ data: { success, data } }) => {
        if (success) {
          if (filter.page === 0) {
            setItems(data.content);
          } else {
            setItems([...items, ...data.content]);
          }
          setFilter({ ...filter, isLast: data.last });
          setTotalElements(data.totalElements);
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const openCategoryFilter = () => {
    setModalState({
      visible: true,
      data: categories,
      onChange: (category_id) => {
        if (filter.category_id !== category_id) {
          setFilter({ ...filter, category_id, page: 0, isLast: false });
        }
      },
      value: filter.category_id,
    });
  };
  const openSortFilter = () => {
    setModalState({
      visible: true,
      data: SORT_TYPE,
      onChange: (sort) => {
        if (filter.sort !== sort) {
          setFilter({ ...filter, sort, page: 0, isLast: false });
        }
      },
      value: filter.sort,
    });
  };
  const closeModal = () => setModalState(INIT_MODAL_STATE);

  const selectedFilter = {
    category: (
      categories.find(({ value }) => value === filter.category_id) || {}
    ).name,
    sort: (SORT_TYPE.find(({ value }) => value === filter.sort) || {}).name,
  };

  const handleClickItem = (id) => {
    history.push(routes.safeTradeItem, { id });
  };
  return (
    <MainLayout
      white
      topbar={
        <SearchTopBar
          value={filter.keyword}
          onChange={(keyword) => {
            if (keyword || filter.keyword) {
              setFilter({ ...filter, keyword, page: 0, isLast: false });
            }
          }}
        />
      }
    >
      <div className="list_wrap" style={{ height: "auto" }}>
        <div className="trade_pd_wrap">
          <p>
            금방금방의 실제 감정평가를 통해
            <br />
            보증 및 판매되는 안심직거래 입니다.
          </p>
          <button
            className="input_btn"
            onClick={() => {
              navigate(routes.safeTradeStart);
            }}
          >
            판매 접수하기
          </button>
        </div>
        <div className="menu_bar">
          <div className="bar_list1" onClick={openCategoryFilter}>
            <img src={menu_bar} />
          </div>
          <div className="bar_num nextbar">
            {selectedFilter.category}{" "}
            <span className="spoqa">{numberWithCommas(totalElements)}</span>개
          </div>
          <div
            className="bar_check nextbar"
            onClick={(e) => {
              e.stopPropagation();
              setFilter({ ...filter, isClosed: !filter.isClosed });
            }}
          >
            <input type="checkbox" checked={filter.isClosed} readOnly />
            판매완료 제외
          </div>
          <div className="bar_new nextbar" onClick={openSortFilter}>
            {selectedFilter.sort} <img src={updown_gr} />
          </div>
          <div className="bar_list2" onClick={() => setIsGallery(!isGallery)}>
            {isGallery ? <img src={menu_gallery} /> : <img src={menu_list} />}
          </div>
        </div>
        <div className="product_wrap">
          {filteredItems.length ? (
            isGallery ? (
              <GalleryLayout
                data={filteredItems}
                onItemClick={handleClickItem}
              />
            ) : (
              <ListLayout data={filteredItems} onItemClick={handleClickItem} />
            )
          ) : (
            <div className="product_none">검색 결과가 없습니다.</div>
          )}
          <div ref={loader} />
        </div>
      </div>

      <SelectFilter {...modalState} onClose={closeModal} />
    </MainLayout>
  );
}

const ListLayout = ({ data = [], onItemClick }) => {
  return (
    <div className="product_list">
      {data.map(
        (
          {
            item_id,
            image,
            brand,
            name,
            price,
            buy_date,
            state,
            created_at,
            is_closed,
          },
          i
        ) => (
          <>
            <div
              className="list_box"
              key={i.toString()}
              onClick={() => onItemClick(item_id)}
            >
              <div className="list_box_l">
                <img src={consts.s3BaseUrl + "/" + image} alt="" />
                {is_closed && <div className="sell_success">판매완료</div>}
              </div>
              <div className="list_box_r">
                <h3 className="list_box_brand">{brand}</h3>
                <p className="list_box_name">{name}</p>
                <span className="list_box_price">
                  <span className="spoqa">{numberWithCommas(price)}</span>원
                </span>
                <p className="list_box_etc">
                  {buy_date && (
                    <>
                      구매일 <span className="spoqa">{buy_date}</span>
                    </>
                  )}
                  상태 <span>{state}</span>
                </p>
                <p className="list_box_date">
                  {formatTime(created_at, "YYYY.MM.DD")}
                </p>
              </div>
            </div>
            <div className="bar" />
          </>
        )
      )}
    </div>
  );
};

const GalleryLayout = ({ data = [], onItemClick }) => {
  const division = (arr, n) => {
    const copied = [...arr];
    const len = arr.length;
    const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
    const res = [];

    for (let i = 0; i < cnt; i++) {
      res.push(copied.splice(0, n));
    }

    return res;
  };

  // 2개씩 나눈 이중배열
  const divisionData = useMemo(() => division(data, 2), [data]);

  return (
    <>
      {divisionData.map((data, i) => {
        return (
          <div key={i.toString()} className="product_gallery">
            {data.map(
              ({ item_id, image, brand, name, price, is_closed }, j) => (
                <div
                  key={j.toString()}
                  className="gallery_box"
                  onClick={() => onItemClick(item_id)}
                >
                  <div className="gallery_box_img">
                    <img src={consts.s3BaseUrl + "/" + image} alt="" />
                    {is_closed && <div className="sell_success">판매완료</div>}
                  </div>
                  <h3 className="gallery_box_brand">{brand}</h3>
                  <p className="gallery_box_name">{name}</p>
                  <span className="gallery_box_price">
                    <span className="spoqa">{numberWithCommas(price)}</span>원
                  </span>
                </div>
              )
            )}
          </div>
        );
      })}
    </>
  );
};

const SelectFilter = ({ visible, data, onChange, value, onClose }) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
      setSelected(value);
    } else {
      document.body.style.overflow = "unset";
    }
  }, [visible]);

  return (
    <div
      className="trade_wrap_bk"
      style={{
        display: visible ? "block" : "none",
        position: "fixed",
        top: 0,
        zIndex: 11,
      }}
    >
      <div className="pd_list_select">
        <div className="select_tit">
          <h3>필터</h3>
          <img src={ic_close} alt="X" onClick={onClose} />
        </div>
        <div className="select_list">
          <ul>
            {data.map(({ name, value }, i) => (
              <li
                key={i.toString()}
                className={`${value === selected && "on"}`}
                onClick={() => setSelected(value)}
              >
                {name}
                <div className="ck_cir">
                  <img src={ic_check_white} alt="" />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div
          className="ok_btn on"
          onClick={() => {
            onChange(selected);
            onClose();
          }}
        >
          확인
        </div>
      </div>
    </div>
  );
};

const SearchTopBar = ({ onChange = () => {}, value = "" }) => {
  const classes = useStyle();
  const history = useHistory();

  const [active, setActive] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    setKeyword(value);
  }, [active]);

  return (
    <Flex row className={classes.header}>
      {!active ? (
        <>
          <ButtonBase onClick={() => history.goBack()} className={classes.back}>
            <img alt="back" src={images.back} className={classes.backImage} />
          </ButtonBase>
          <Text className={classes.title}>안심직거래</Text>
          <ButtonBase onClick={() => setActive(true)}>
            <img src={ic_search} className={classes.searchImage} />
          </ButtonBase>
        </>
      ) : (
        <>
          <Flex className={classes.inputArea}>
            <Flex style={{ height: "100%", position: "relative" }}>
              <input
                type="text"
                className={classes.input}
                placeholder="브랜드, 제품명"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <ButtonBase
                onClick={() => onChange(keyword)}
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  width: 50,
                  height: "100%",
                }}
              >
                <img src={ic_search} style={{ width: 20, height: 20 }} />
              </ButtonBase>
            </Flex>
          </Flex>
          <ButtonBase
            onClick={() => {
              onChange("");
              setActive(false);
            }}
          >
            <Text className={classes.cancelButton}>취소</Text>
          </ButtonBase>
        </>
      )}
    </Flex>
  );
};

const useStyle = makeStyles({
  header: { height: 60, borderBottom: "1px solid #eee", alignItems: "center" },
  back: { padding: "18px 16px" },
  backImage: { width: 8, height: 18, objectFit: "contain" },
  title: { flex: 1, color: "#000", fontSize: 18, lineHeight: 1 },
  searchImage: { width: 20, height: 20, marginRight: 16 },
  cancelButton: { marginRight: 16 },
  inputArea: { flex: 1, height: "100%", padding: "12px 12px" },
  input: { height: "100%", paddingLeft: 5 },
});
