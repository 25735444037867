import { Dialog, makeStyles, Modal } from "@material-ui/core";
import React, { useEffect } from "react";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";
import { messageError } from "../../redux/message/MessageActions";
import { useHistory } from "react-router";
import BasicLayout from "../../layouts/basic/BasicLayout";
import { toastShow } from "../../redux/toast/ToastActions";

export default function AddressSearch({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    if (!data.zonecode) {
      return dispatch(toastShow("올바르지 않은 주소입니다."));
    }
    if (fullAddress) {
      history.push(history.location.state.to, {
        ...history.location.state.state,
        address: fullAddress,
        postCode: data.zonecode,
      });
    } else {
      dispatch(messageError("등록 불가능한 주소 입니다."));
    }
  };

  return (
    <BasicLayout
      white
      stackTopbar={{
        title: "주소 검색",
        border: true,
      }}
    >
      <DaumPostcode
        autoClose
        animation
        height="calc(100vh - 60px)"
        width="100%"
        onComplete={handleComplete}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  root: {},
});
