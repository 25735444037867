import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import moment from "moment-timezone";
import Text from "../text/Text";
import {
  formatTime,
  getProductSummaryName,
  getStateColor,
} from "../../services/utils";
export default function AppraiseItemProgress({
  onClick,
  number,
  appraisalSimples,
  collectDate,
  appraisalRequestStatus,
  status,
  collectTime,
  visitDate,
  visitTime,
  method,
}) {
  const classes = useStyle();
  return (
    <Flex className={classes.root + " " + "shadow"}>
      <Flex row className={classes.header}>
        <Text className={classes.date}>
          {formatTime(
            appraisalRequestStatus[appraisalRequestStatus.length - 1]
              ?.createdAt,
            "YYYY.MM.DD"
          )}
        </Text>
        <Text
          font={fonts.notoSansKRMedium}
          className={classes.progress}
          style={{ color: getStateColor(status) }}
        >
          {(status === "감정시작" || status === "승인대기") ? "감정중" : status === "반송대기" ? "교환완료" : status}
        </Text>
      </Flex>

      <ButtonBase onClick={onClick} row className={classes.main}>
        {method === "현장감정" ? null : (
          <img
            src={appraisalSimples[0]?.topImageUrl}
            className={classes.image}
          />
        )}
        <Flex
          style={{ alignItems: "flex-start", justifyContent: "flex-start" }}
        >
          {method !== "현장감정" && (
            <Text
              className={classes.desc}
              font={fonts.notoSansKRMedium}
              style={{ color: "black" }}
            >
              {method === "택배" ? "택배 접수" : "내방 접수"}
            </Text>
          )}
          {method === "현장감정" ? (
            <Text
              className={classes.desc}
              font={fonts.notoSansKRMedium}
              style={{ color: "black" }}
            >
              현장감정
            </Text>
          ) : (
            <Text className={classes.desc}>
              감정제품
              <Text font={fonts.notoSansKRMedium} style={{ color: "black" }}>
                {method === "현장감정"
                  ? "등록 예정"
                  : getProductSummaryName(
                      appraisalSimples
                        .map((x) => x.appraisalProduct.name)
                        .join(",")
                    )}
              </Text>
            </Text>
          )}

          <Text className={classes.desc}>
            접수번호
            <Text>{number}</Text>
          </Text>
          <Text className={classes.desc}>
            {method === "현장감정" ? "접수일" : "예약일"}
            <Text>
              {method === "현장감정"
                ? formatTime(
                    appraisalRequestStatus.filter((x) => x.status === "접수")[0]
                      ?.createdAt,
                    "YYYY.MM.DD"
                  )
                : moment(
                    method === "내방" ? visitDate : collectDate,
                    "YYYY-MM-DD"
                  ).format("MM/DD") +
                  " " +
                  (method === "내방" ? visitTime : collectTime)}
            </Text>
          </Text>
        </Flex>
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    margin: "13px",
    padding: "16px",
  },
  name: {
    color: colors.text,
    fontSize: "14px",
  },
  checke: {
    width: "14px",
    height: "10px",
    marginRight: "10px",
  },
  check: { alignItems: "center", display: "flex", alignSelf: "flex-start" },
  header: {
    alignItems: "center",
    borderBottom: "1px solid #eee",
    paddingBottom: 16,
    justifyContent: "space-between",
  },
  main: {
    alignItems: "center",
    marginTop: "13px",
    display: "flex",
    justifyContent: "flex-start",
  },
  image: {
    marginRight: "13px",
    objectFit: "cover",
    border: "1px solid #eee",
    borderRadius: 3,
    width: "80px",
    height: "80px",
  },
  label1: {
    marginBottom: "3px",
    color: colors.text,
    fontSize: "16px",
    "& span": {
      fontSize: "12px",
      color: colors.gray,
    },
  },
  desc: {
    marginTop: "4px",
    fontSize: "13px",
    color: colors.gray,
    "& span": {
      marginLeft: "10px",
      fontSize: "13px",
      color: colors.text,
    },
  },
  date: { fontSize: 12, color: "#666" },
  progress: {
    fontSize: 12,
    color: colors.primary,
  },
});
