import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import CloseLayout from "../../layouts/close/CloseLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";

export default function ShopDetail({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <CloseLayout title="강남본점(청담)">
      <div
        style={{
          height: "250px",
          backgroundColor: "black",
          color: "white",
          marginBottom: "20px",
        }}
      >
        MAP
      </div>
      <Flex row className={classes.item}>
        <Text className={classes.label}>지역</Text>
        <Text className={classes.value}>서울</Text>
      </Flex>
      <Flex row className={classes.item}>
        <Text className={classes.label}>대리점명</Text>
        <Text className={classes.value}>강남본점(청담)</Text>
      </Flex>
      <Flex row className={classes.item}>
        <Text className={classes.label}>상세주소</Text>
        <Text className={classes.value}>
          (06070)서울 강남구 삼성로 736, 한국금거래소
        </Text>
      </Flex>
      <Flex row className={classes.item}>
        <Text className={classes.label}>전화번호</Text>
        <Text className={classes.value}>
          02-516-4400
          <ButtonBase className={classes.button}>전화연결</ButtonBase>
        </Text>
      </Flex>
      <Flex row className={classes.item}>
        <Text className={classes.label}>팩스번호</Text>
        <Text className={classes.value}>02-518-5577</Text>
      </Flex>
      <Flex row className={classes.item}>
        <Text className={classes.label}>찾아오시는 길</Text>
        <Text className={classes.value}>
          지하철 7호선 청담한국금거래소역 9번출구 → 직진 도보 2분
          한국금거래소청담본점
        </Text>
      </Flex>
    </CloseLayout>
  );
}

const useStyle = makeStyles({
  item: {
    margin: "4px 0px",
  },
  label: { color: "#666", fontSize: "13px", flex: 1, padding: "0px 14px" },
  value: {
    color: "#333",
    fontSize: "13px",
    marginRight: "30px",
    flex: 3,
  },
  button: {
    borderRadius: "10px",
    backgroundColor: colors.primary,
    color: "#fff",
    fontFamily: fonts.notoSansKRMedium,
    lineHeight: 1,
    fontSize: "14px",
    padding: "5px 16px",
    marginLeft: "6px",
  },
});
