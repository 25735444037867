import { makeStyles } from "@material-ui/core";
import { ClassSharp } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import { actionHide, actionClose } from "../../redux/action/ActionActions";

export default function Action({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { open, message, onPress, title, content } = useSelector(
    (s) => s.action
  );

  const handleClose = (i) => () => {
    if (onPress[i]) {
      onPress[i]();
    }
    dispatch(actionHide());
  };

  if (!open) {
    return null;
  }

  return (
    <Flex className={classes.root}>
      <Flex className={classes.main}>
        <Text className={classes.message}>
          {content || message.split("").map((s) => (s === "\n" ? <br /> : s))}
        </Text>
        <Flex row style={{ alignSelf: "stretch" }}>
          {title.length > 0 ? (
            <>
              <Button
                onClick={handleClose(0)}
                className={classes.button}
                label={title[0]}
              />
              <Button
                onClick={handleClose(1)}
                className={classes.button}
                label={title[1]}
              />
            </>
          ) : (
            <Button
              onClick={() => {
                dispatch(actionClose());
              }}
              className={classes.button}
              label={"확인"}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: "center",
    zIndex: 40,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  main: {
    alignSelf: "stretch",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px",
    borderRadius: "10px",
    padding: "30px",
  },
  message: {
    color: colors.text,
    fontSize: "16px",
    margin: "20px 0px",
    textAlign: "center",
  },
  button: {
    alignSelf: "center",
    padding: "10px",
    flex: 1,
    marginTop: "30px",
    "&:first-child": {
      marginRight: "16px",
      backgroundColor: "#666666",
    },
  },
});
