import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import consts from "../../libs/consts";

export default function FaqItem(props) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const imageUrl = props.serviceImage;
  useEffect(() => {
    setOpen(false);
  }, [props.searchType, props.categoryId]);

  return (
    <Flex className={classes.root}>
      <ButtonBase
        onClick={() => {
          setOpen((o) => !o);
        }}
        row
        className={classes.header}
      >
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          {`[${props?.faqCategory}] ${props?.title}`}
        </Text>
        <img src={open ? images.up : images.down} className={classes.na} />
      </ButtonBase>
      {open && (
        <Flex className={classes.desc}>
          <Text>
            <div
              className="faqContent"
              dangerouslySetInnerHTML={{
                __html: (props?.content).replace(/\n/g, "<br />"),
              }}
            />
          </Text>
          {imageUrl && (
            <div style={{ marginTop: 5 }}>
              <img
                className={classes.faqImage}
                src={imageUrl && `${consts.s3BaseUrl}${imageUrl.path}`}
                alt="FAQ이미지"
              />
            </div>
          )}
        </Flex>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
  },
  root: {
    borderBottom: "1px solid #eee",
  },
  label: {
    color: "#333",
    fontSize: "16px",
  },
  na: {
    width: "16px",
    height: "8px",
  },
  desc: {
    color: "#333",
    padding: "20px",
    fontSize: "14px",
    height: "100%",
    width: "100%",
    background: "#dfe6ef",
  },
  faqImage: {
    width: "100%",
  },
});
