import { ButtonBase, Divider, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import PhysicalWithDrawalItem from "../../components/physical-withdrawal-item/PhysicalWithDrawalItem";
import ProductDirectTransactionItem from "../../components/product-list/ProductDirectTranscationItem";
import ProductListItemPhysical from "../../components/product-list/ProductListItemPhysical";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import {
  actionShow,
  actionError,
  actionOpen,
} from "../../redux/action/ActionActions";
import DeliveryState from "./DeliveryState";
import ButtonMini from "../../components/button-mini/ButtonMini";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import { objToQueryStr } from "../../services/utils";
import { formatTime } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";

export default function OrderDetail({ match }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  const [orow, setorow] = useState(0);
  const [sPrice, setsPrice] = useState(0);
  const [stype, setstype] = useState("SILVER");
  const [productData, setproductData] = useState({});
  const [transDeatil, settransDeatil] = useState({});
  const [stateindex, setstateindex] = useState(0);

  var orderId = match.params.orderId;
  var orderNo = match.params.orderNo;
  var goodId = match.params.goodId;
  useEffect(() => {
    // 상품 정보 디테일
    APIS.getGoodsDetail(goodId).then(({ data: { data } }) => {
      setproductData(data);
      setstype(data.goldOrSilver);
    });
    // 매장 정보 디테일
    APIS.getGoodsTransDetail(orderId).then(({ data }) => {
      const { status } = data;
      settransDeatil(data);
      if (status === "DELIVERY") {
        setstateindex(1);
      } else if (status === "TAKEOUT" || status === "CONFIRM") {
        setstateindex(2);
      } else if (status === "REJECT") {
        setstateindex(4);
      } else {
        setstateindex(0);
      }
    });
  }, []);

  useEffect(() => {
    // 교환과정
    APIS.getGoodsTransSearch(orderNo).then(({ data: { success, data } }) => {
      if (success) {
        setorow(data);
        setstateindex(2);
      }
    });
  }, [stateindex]);

  useEffect(() => {
    APIS.getCurrentClosingMarketPrice(stype).then(({ data: { data } }) => {
      var splitData = data.split(",");
      setsPrice(splitData[0]);
    });
  }, [stype]);

  if (state?.index === undefined) {
    return (
      <Redirect
        to={{
          pathname: history.location.pathname,
          state: { ...state, index: 0 },
        }}
      />
    );
  }
  const orderCancle = () => {
    const transId = orow.transId;
    dispatch(loadingStart);
    APIS.deleteTransCancel(transId)
      .then(({ data: { data, success, message } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "실물인출 주문을 취소하셨습니다.",
              history.push(routes.myPhysicalWithDrawal),
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const isDirect = state?.type === "direct-transcation";

  const conrimSend = () => {
    var transId = orow.transId;
    APIS.putTransConfirm(transId).then(
      ({ data: { success, data, message } }) => {
        if (success === true) {
          dispatch(actionError("인출확정되었습니다."));
          setstateindex(-1);
        } else {
          dispatch(actionError(message));
        }
      }
    );
  };
  return (
    <BasicLayout
      stackTopbar={{
        option: state.index < 2 && (
          <ButtonBase
            onClick={() => {
              history.push({
                pathname: history.location.pathname,
                state: { ...state, index: state.index + 1 },
              });
            }}
          >
            {/*테스트 다음*/}
          </ButtonBase>
        ),
        title: "주문 상세",
        border: true,
      }}
      white
    >
      {state?.type === "direct-transcation" ? (
        <>
          <div style={{ height: 10 }} />
          <ProductDirectTransactionItem bottom />
          <div style={{ height: 10 }} />
        </>
      ) : (
        <ProductListItemPhysical
          inOrder
          bottom
          orow={orow}
          sPrice={sPrice}
          productData={productData}
        />
      )}

      <Divider style={{ height: 3 }} />

      <Flex className={classes.content}>
        <Flex className="row-between">
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            {orow.status === "CANCEL"
              ? "취소되었습니다."
              : "결제가 완료되었습니다."}
          </Text>
          <Text font={fonts.notoSansKRMedium} className={classes.label2}>
            주문번호 {orow.no}
          </Text>
        </Flex>

        <Flex row style={{ marginTop: "30px" }}>
          <DeliveryState
            index={stateindex}
            status={orow.status}
            shipmentStatus={orow.shipmentStatus}
          />
          <Flex className={classes.deliveryDesc}>
            <Text className={classes.deliveryLabel1}>
              {formatTime(orow.updatedAt, "YYYY-MM-DD H:m")}
            </Text>
            {state.index === 1 && (
              <Text className={classes.deliveryLabel2}>배송 준비중</Text>
            )}
            {state.index === 2 && (
              <Text className={classes.deliveryLabel1}>
                {formatTime(transDeatil.shipmentdate, "YYYY-MM-DD H:m")}
              </Text>
            )}
            <div />
          </Flex>
        </Flex>
      </Flex>
      <Divider style={{ height: 3 }} />
      {orow.delivery === "택배" && (
        <Flex className={classes.content}>
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            {state.index === 2
              ? "배송이 완료되었습니다."
              : "고객님의 주소로 상품이 배송됩니다."}
          </Text>

          <Flex row className={classes.simpleTable}>
            <Text>받는사람</Text>
            <Text>홍길동</Text>
          </Flex>
          <Flex row className={classes.simpleTable}>
            <Text>휴대폰</Text>
            <Text>010-1234-5678</Text>
          </Flex>
          <Flex row className={classes.simpleTable}>
            <Text>주소지</Text>
            <Text>서울 송파구 잠실4동 파크리오A 105</Text>
          </Flex>

          {state.index >= 1 && (
            <ButtonBase
              style={{ backgroundColor: "#333" }}
              className={classes.mainButton}
            >
              배송조회
            </ButtonBase>
          )}
        </Flex>
      )}
      {orow.delivery === "매장방문" && (
        <div style={{ padding: "10px" }}>
          <Text
            font={fonts.notoSansKRMedium}
            className={classes.label1 + " " + classes.item}
          >
            매장을 방문하여 물품을 수령하여주세요
          </Text>
          <ShopItemSimple
            className={classes.shopItem}
            name={transDeatil.shopName}
            distance={"none"}
            address={transDeatil.shopAddress}
            addressDetail={""}
          />

          <Flex row className={classes.item}>
            <Text className={classes.label}>전화번호</Text>
            <Text className={classes.value}>{transDeatil.shopPhoneNumber}</Text>
          </Flex>
          <Flex row className={classes.item}>
            <Text className={classes.label}>방문시간</Text>
            <Text className={classes.value}>
              {transDeatil.visitDate} {transDeatil.visitTime}
            </Text>
          </Flex>
        </div>
      )}
      {state.index === 0 &&
        orow.status !== "CANCEL" &&
        orow.status === "APPROVAL" &&
        orow.shipmentStatus !== "MAKE" &&
        orow.shipmentStatus !== "SHIPMENT" &&
        orow.shipmentStatus !== "DELIVERY" && (
          <ButtonBase
            onClick={() => {
              dispatch(
                actionShow(
                  "실물인출 주문을 취소하시겠습니까?",
                  ["닫기", "결제 취소"],
                  [null, () => orderCancle()]
                )
              );
            }}
            className={classes.textButton}
          >
            결제취소
          </ButtonBase>
        )}

      {orow.status === "TAKEOUT" &&
        (isDirect ? (
          <>
            <Divider style={{ height: 3 }} />
            <Flex className={classes.content}>
              <Text font={fonts.notoSansKRMedium} className={classes.label1}>
                인출을 확정해 주세요.
              </Text>
              <Text className={classes.message}>
                인출완료 후 7일이 지나면 자동 구매 확정 됩니다.
              </Text>

              <ButtonBase
                onClick={() => {
                  if (state.review === "complete") {
                    history.push(routes.reviewDetail(1), {
                      me: true,
                    });
                  } else if (state.order) {
                    history.push(routes.orderCompleteWrite, {
                      type: "review",
                      state: {
                        ...state,
                        review: "complete",
                      },
                    });
                  } else {
                    history.push(routes.orderCompleteWrite, {
                      type: "complete",
                      state: {
                        ...state,
                        order: "complete",
                      },
                    });
                  }
                }}
                className={classes.mainButton}
              >
                {state.review === "complete"
                  ? "작성후기 보기"
                  : state.order === "complete"
                  ? "리뷰쓰기"
                  : "구매 확정"}
              </ButtonBase>
              {state.order !== "complete" && (
                <Text font={fonts.notoSansKRMedium} className={classes.hint}>
                  <Text>상품 반품 관련 문의는 아래로 연락해 주세요.</Text>
                  <br />
                  강남본점(청담) | 1544-9993
                </Text>
              )}
            </Flex>
          </>
        ) : (
          <>
            <Divider style={{ height: 3 }} />
            <Flex className={classes.content}>
              <Text font={fonts.notoSansKRMedium} className={classes.label1}>
                인출을 확정해 주세요.
              </Text>
              <Text className={classes.message}>
                인출완료 후 7일이 지나면 자동 구매 확정 됩니다.
              </Text>

              <ButtonBase
                className={classes.mainButton}
                onClick={() => conrimSend()}
              >
                인출 확정
              </ButtonBase>

              <Text font={fonts.notoSansKRMedium} className={classes.hint}>
                교환 및 반품안내
                <Text>
                  <br />
                  모든 제품은 고객님의 주문에 의해 제작되므로 제작 중에는 취소,
                  교환이 되지 않으니 유의하셔서 주문하시기 바랍니다
                </Text>
              </Text>
            </Flex>
          </>
        ))}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  hint: {
    textAlign: "center",
    margin: "20px 50px 30px 50px",
    fontSize: "14px",
    alignSelf: "center",
    color: "#333",
    "& span": {
      color: "#666",
      fontSize: "12px",
    },
  },
  shopItem: {
    padding: "10px",
    borderRadius: "10px",
    border: "1px solid #ddd",
    backgroundColor: "#eee",
    marginTop: "10px",
  },
  message: {
    color: "#666",
    fontSize: "14px",
    marginTop: "3px",
  },
  label1: { color: "#333", fontSize: "16px" },
  content: {
    padding: "30px 13px",
  },
  label2: {
    color: "#333",
    fontSize: "12px",
  },
  deliveryDesc: {
    justifyContent: "space-between",
    flex: 1,
    alignItems: "flex-end",
  },
  deliveryLabel1: {
    fontSize: "12px",
    color: "#666",
  },
  deliveryLabel2: {
    fontSize: "12px",
    color: "#333",
  },
  textButton: {
    alignSelf: "center",
    margin: "50px",
    borderBottom: "1px solid #333",
    color: "#333",
    fontSize: "14px",
    padding: "3px 0px",
    fontFamily: fonts.notoSansKRMedium,
  },
  simpleTable: {
    alignSelf: "stretch",
    margin: "10px 20px 0px 20px",
    "& span": {
      "&:first-child": {
        width: 70,

        color: "#666",
      },
      fontSize: "14px",
      color: "#333",
    },
  },
  mainButton: {
    alignSelf: "center",
    borderRadius: "3px",
    backgroundColor: "#333",
    fontSize: 16,
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    padding: "10px",
    minWidth: "50%",
    marginTop: "30px",
    backgroundColor: colors.primary,
  },
  item: {
    marginTop: "10px",
  },
  label: {
    marginRight: "15px",
  },
});
