import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";
import colors from "../../libs/colors";
import images from "../../libs/images";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { useHistory } from "react-router";

const TermsList = () => {
  const [termsList, setTermsList] = useState([]);

  useEffect(() => {
    APIS.getTerms().then(({ data: { success, data } }) => {
      success && setTermsList(data);
    });
  }, []);

  return (
    <BasicLayout
      white
      stackTopbar={{
        title: "이용약관",
        border: true,
      }}
    >
      {termsList.map((data, i) => (
        <ListItem key={i.toString()} {...data} />
      ))}
    </BasicLayout>
  );
};

const ListItem = ({ type, title }) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickRow = () => {
    dispatch(loadingStart);
    APIS.getTermsDetail(type)
      .then(
        ({
          data: {
            data: { content },
          },
        }) => {
          history.push(routes.htmlViewer, { title, content });
        }
      )
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <ButtonBase onClick={handleClickRow} row className={classes.root}>
      <Text className={`${classes.label}`}>{title}</Text>
      <img alt="right" src={images.rightgray} />
    </ButtonBase>
  );
};

const useStyle = makeStyles({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    "& img": {
      width: "7px",
      height: "12px",
    },
  },
  label: {
    color: colors.text,
    fontSize: "16px",
  },
  labelDis: {
    color: "#999",
  },
});

export default TermsList;
