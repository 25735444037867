import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { numberWithCommas, formatTime } from "../../libs/utils";

export default function ListItem({ item }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  // console.log(item);
  return (
    <Flex className={classes.root} row>
      <Flex>
        <Text className={classes.l1}>
          {formatTime(item.transDt, "YYYY-MM-DD H:mm:ss")}
        </Text>
        <Text className={classes.l2}>
          {item.remarks}
          {/* <Text>|</Text>  
          {item.transType === "SELL" && " 상품 판매"}
          {item.transType === "WITHDRAW" && " 출금"}
          {item.transType === "BUY" && " 상품 구매"} */}
        </Text>
      </Flex>
      {item.creditDebit === "C" ? (
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          +{numberWithCommas(item.amount)} 원
        </Text>
      ) : (
        <Text font={fonts.notoSansKRMedium} className={classes.label2}>
          -{numberWithCommas(item.amount)} 원
        </Text>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    color: colors.textRed,
    fontSize: "16px",
  },
  label2: {
    color: colors.textBlue,
    fontSize: "16px",
  },
  root: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "19px 15px",
    boxSizing: "border-box",
    margin: "0px 13px",
    borderBottom: "1px solid #eee",
  },
  l1: {
    color: colors.gray,
    fontSize: "12px",
  },
  l2: {
    fontSize: "14px",
    color: colors.text,
    "& span": {
      fontSize: "10px",
      color: "#ccc",
    },
  },
  l3: {
    fontSize: "12px",
    color: colors.text,
    "& span": {
      "&:last-child": {
        fontSize: "10px",
        color: "#ccc",
      },
    },
  },
});
