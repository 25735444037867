import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";

//buy sell
export default function ListItem({
  method,
  data,
  keys,
  center,
  onpriceChangef,
  lastPrice,
}) {
  const classes = useStyle();
  var donPrice = data.orderPrice * 3.75;
  var orderPrice = data.orderPrice;

  function pricedata() {
    onpriceChangef(orderPrice, method);
  }
  return (
    <ButtonBase className={classes.button} onClick={() => pricedata()}>
      <Flex
        className={`${classes.root} ${
          method === "buy" ? classes.rootBuy : classes.rootSell
        }`}
        row
      >
        <Flex className={classes.left}>
          <Text
            style={{
              // color: center
              //   ? colors.black
              //   : method === "buy"
              //   ? colors.textBlue
              //   : colors.textRed,

              color:
                data.orderPrice > lastPrice
                  ? colors.textRed
                  : data.orderPrice < lastPrice
                  ? colors.textBlue
                  : colors.black,
            }}
            font={fonts.notoSansKRMedium}
            className={classes.label}
          >
            <NumberFormat
              value={data.orderPrice}
              displayType={"text"}
              thousandSeparator={true}
              className="spoqa"
            />
          </Text>
          <Text className={classes.hint} font={fonts.notoSansKRLight}>
            <NumberFormat
              value={donPrice}
              displayType={"text"}
              thousandSeparator={true}
              className="spoqa"
            />
            /돈
          </Text>
        </Flex>
        <Flex className={classes.right}>
          <NumberFormat
            value={data.remainGram}
            displayType={"text"}
            thousandSeparator={true}
            className={`${classes.medium} spoqa`}
          />
        </Flex>
      </Flex>
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  hint: { fontSize: "10px", color: "#999999" },
  button: {
    width: "100%",
    alignSelf: "stretch",
    marginBottom: 1,
    boxSizing: "border-box",
  },
  root: { flex: 1, boxSizing: "border-box" },
  rootSell: { backgroundColor: "#E1F4FE" },
  rootBuy: { backgroundColor: "#FDECEC" },
  left: {
    flex: 3,
    borderRight: "1px solid white",
    alignItems: "flex-end",
    padding: "6px 0px",
    paddingRight: "10px",
    justifyContent: "center",
  },
  right: {
    alignItems: "center",
    justifyContent: "center",
    flex: 2,
    padding: "10px 0px",
  },
  medium: { color: "#444", fontSize: "12px" },
  label: { fontSize: 15 },
});
