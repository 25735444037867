export const ROLE = {
  NONE: { NAME: "NONE", LEVEL: 0 },
  ROLE_TEMP: { NAME: "ROLE_TEMP", LEVEL: 1 }, // SNS Login 상태
  ROLE_DORMANT: { NAME: "ROLE_DORMANT", LEVEL: 1 }, // 휴면 회원
  ROLE_SELF_VERIFICATION: { NAME: "ROLE_SELF_VERIFICATION", LEVEL: 2 }, // 본인인증 완료 상태
  ROLE_BANKACCOUNT_VERIFICATION: {
    NAME: "ROLE_BANKACCOUNT_VERIFICATION",
    LEVEL: 3,
  }, // 계좌 인증 완료 상태
};

const routes = {
  login: "/login",
  myreview: "/myreview",
  introduce: "/introducer",
  splash: "/splash",
  main: "/",
  depositWithdrawal: "/depositwithdrawal",
  mypage: "/mypage",
  register: "/register",
  appraiseDirect: "/appraisedirect",
  verifyBank: "/verifyBank",
  wonHistory: "/wonHistory",
  setAddress: "/setAddress",
  appraiseReviewDetail: "/appraise-review-detail",
  orderDetail: (id, no, goodid) => "/orders/" + id + "/" + no + "/" + goodid,
  noticeDetail: (id) => "/notices/" + id,
  eventDetail: (id) => "/events/" + id,
  reviewDetail: (id) => "/reviews/" + id,
  shopDetail: (id) => "/shops/" + id,
  productInquiryAdd: "/productInquiryAdd",
  appraiseDetail: "/appraiseDetail",
  notification: "/notification",
  appraiseRegistMain: "/appraise-regist-main",
  menu: "/menu",
  orderCompleteWrite: "/orderCompleteWrite",
  physicalWithDrawal: "/menu/physicalWithDrawal",
  myDirectTransaction: "/mypage/directTransaction",
  directTransactionDetail: (id) => "/directTransaction/" + id,
  physicalWithDrawalPDetail: "/menu/physicalWithDrawal/products/",
  faq: "/faq",
  exitVerify: "/exitVerify",
  findShop: "/findShop",
  directTransaction: "/menu/directTransaction",
  exitComplete: "/exitComplete",
  exchange: "/exchange",
  writeReview: "/write-review",
  appraise: "/appraise",
  inquiry: "/inquiry",
  order: "/order",
  myPhysicalWithDrawal: "/mypage/physicalWithDrawal",
  exitReason: "/exitReason",
  editProfile: "/editProfile",
  appraiseReturn: "/appraise-return",
  policy: "/policy",
  exit: "/exit",
  trading: "/trading",
  mytradehistory: "/mytradehistory",
  appraiseRegist: "/appraiseRegist",
  notice: "/notice",
  event: "/event",
  withdrawal: "/withdrawal",
  shops: "/shops",
  bankPassword: "/bankPassword",
  assetHistory: "/assetHistory",
  deposit: "/deposit",
  virtualOrder: "/virtualOrder",
  verify: "/verify",
  registerFinish: "/registerFinish",
  selectBank: "/selectBank",
  authGateway: "/auth/gateway",
  selfVerification: "/auth/self-verification",
  authRegisterShop: "/auth/registerShop",
  acceptTerms: "/auth/acceptTerms",
  authRegisterPassword: "/auth/registerPassword",
  authFindShop: "/auth/find-shop",
  htmlViewer: "/html-viewer",
  authBankAccount: "/auth/bank-account",
  termsList: "/terms/terms-list",
  noticeList: "/notices",
  eventList: "/events",
  faqList: "/faq",
  signIn: "/sign/sign-in",
  signUp: "/sign/sign-up",
  signInId: "/sign/sign-in/id",
  signUpId: "/sign/sign-up/id",
  tradingGuide: "/main/trading-guide",
  commonFindShop: "/common/find-shop",
  commonPasswordSecurity: "/common/password-security",
  safeTradeStart: "/safe-trade/start",
  safeTradeStep1: "/safe-trade/step1",
  safeTradeStep2: "/safe-trade/step2",
  safeTradeStep3: "/safe-trade/step3",
  safeTradeStep4: "/safe-trade/step4",
  safeTradeCreateComplete: "/safe-trade/create-complete",
  safeTradeList: "/menu/safe-trade/list",
  safeTradeItem: "/safe-trade/item",
  safeTradeBuy: "/safe-trade/buy",
  safeTradeQna: "/safe-trade/qna",
  mySafeTradeList: "/mypage/safe-trade/list",
  mySafeTradeBuy: "/mypage/safe-trade/buy",
  mySafeTradeSell: "/mypage/safe-trade/sell",
  mySafeTradeSellDetail: "/mypage/safe-trade/sell/detail",
  mySafeTradeSellReturn: "/mypage/safe-trade/sell/return",
};
export default routes;

export const mapRouteToRole = {
  [routes.main]: ROLE.NONE,
  [routes.depositWithdrawal]: ROLE.ROLE_TEMP,
  [routes.mypage]: ROLE.ROLE_TEMP,
  [routes.menu]: ROLE.NONE,
  [routes.signIn]: ROLE.NONE,
  [routes.authGateway]: ROLE.NONE,
  [routes.signUp]: ROLE.NONE,
  [routes.editProfile]: ROLE.ROLE_SELF_VERIFICATION,

  //실물인출
  [routes.order]: ROLE.ROLE_SELF_VERIFICATION,
  [routes.authBankAccount]: ROLE.ROLE_SELF_VERIFICATION,

  //감정평가
  [routes.appraise]: ROLE.ROLE_SELF_VERIFICATION,
  [routes.appraiseRegist]: ROLE.ROLE_SELF_VERIFICATION,
  [routes.appraiseDirect]: ROLE.ROLE_SELF_VERIFICATION,
  [routes.appraiseReturn]: ROLE.ROLE_SELF_VERIFICATION,
  [routes.withdrawal]: ROLE.ROLE_BANKACCOUNT_VERIFICATION,
  [routes.deposit]: ROLE.ROLE_SELF_VERIFICATION,
  [routes.virtualOrder]: ROLE.ROLE_SELF_VERIFICATION,

  //매수매도
  // [routes.trading]: ROLE.ROLE_SELF_VERIFICATION,
  [routes.mytradehistory]: ROLE.ROLE_SELF_VERIFICATION,

  //안심직거래
  // [routes.safeTradeStart]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.safeTradeStep1]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.safeTradeStep2]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.safeTradeStep3]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.safeTradeStep4]: ROLE.ROLE_SELF_VERIFICATION,
  // [routes.mySafeTradeList]: ROLE.ROLE_SELF_VERIFICATION,
  [routes.safeTradeBuy]: ROLE.ROLE_SELF_VERIFICATION, // 상품 구매하기
  [routes.safeTradeQna]: ROLE.ROLE_SELF_VERIFICATION, // 상품 문의하기
};
