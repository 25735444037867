import { ButtonBase } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import buy1 from "../../assets/images/buy1.png";
import buy2 from "../../assets/images/buy2.png";
import buy3 from "../../assets/images/buy3.png";
import buy_main1 from "../../assets/images/buy_main1.png";
import buy_main2 from "../../assets/images/buy_main2.png";
import buy_main3 from "../../assets/images/buy_main3.png";
import ic_con_right from "../../assets/images/ic_con_right.png";
import sell1 from "../../assets/images/sell1.png";
import sell2 from "../../assets/images/sell2.png";
import sell3 from "../../assets/images/sell3.png";
import sell_main1 from "../../assets/images/sell_main1.png";
import sell_main2 from "../../assets/images/sell_main2.png";
import sell_main3 from "../../assets/images/sell_main3.png";
import Bottombar from "../../components/bottombar/Bottombar";
import auth from "../../hoc/auth";
import BasicLayout from "../../layouts/basic/BasicLayout";
import images from "../../libs/images";
import routes from "../../libs/routes";

export default function TradingGuide({ navigate }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  const location = history.location;
  const userReducer = useSelector((s) => s.user);

  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "살래요/팔래요",
        option: (
          <ButtonBase
            onClick={() => {
              history.replace(routes.main);
            }}
          >
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}
    >
      <div className="tab_content">
        <input type="radio" name="tabmenu" id="tab01" checked />
        <label htmlFor="tab01">살래요</label>

        <input type="radio" name="tabmenu" id="tab02" />
        <label htmlFor="tab02">팔래요</label>

        <div className="conbox con1">
          <div className="buy_wrap">
            <div className="buy_main">
              <h3 className="buy_tit">금방금방에서 사기</h3>
              <p className="buy_cont">
                금방금방에서 사고싶은 금/은 제품이 있으면
                <br />
                해당 상품의 중량 이상의 금/은을 매수하고
                <br />
                실물인출 주문을 해주세요.
              </p>
              <ul className="buy_list">
                <li>
                  <span className="spoqa">1</span>
                  <br />
                  <img src={buy_main1} alt="" />
                  <p>입금하기</p>
                </li>
                <li className="bar"></li>
                <li>
                  <span className="spoqa">2</span>
                  <br />
                  <img src={buy_main2} alt="" />
                  <p>
                    주문하기
                    <br />
                    (매수/구매)
                  </p>
                </li>
                <li className="bar"></li>
                <li>
                  <span className="spoqa">3</span>
                  <br />
                  <img src={buy_main3} alt="" />
                  <p>
                    금/은
                    <br />
                    실물인출
                  </p>
                </li>
              </ul>
            </div>
            <div className="buy_order">
              <div>
                <h3 className="order_tit">STEP 1. 입금하기</h3>
                <p className="order_cont">
                  본인의 전용 가상 계좌로 입금을 합니다.
                </p>
                <img src={buy1} alt="" className="buy_list_img" />
                <p className="order_txt">
                  본인의 전용 가상계좌를 발급받은 후,
                  <br />
                  가상계좌로 입금을 해주세요.
                </p>
                <p
                  className="guide_view"
                  onClick={() => {
                    window.location.href =
                      "https://guide.kumbang.co.kr/guide_view_03.html";
                  }}
                >
                  입금 이용가이드 보기
                </p>
                <button
                  className="org_bt"
                  onClick={() => navigate(routes.depositWithdrawal)}
                >
                  입금하기
                  <img src={ic_con_right} alt=">" className="ic_right" />
                </button>
              </div>
              <div className="bg_gr">
                <h3 className="order_tit">STEP 2. 매수하기</h3>
                <p className="order_cont">
                  금/은 <span>매수</span>하기를 합니다.
                </p>
                <img src={buy2} alt="" className="buy_list_img" />
                <p className="order_txt">
                  입금을 한 후 필요한 만큼의 금/은을
                  <br />
                  가격과 중량을 선택하여 매수할 수 있습니다.
                </p>
                <p
                  className="guide_view"
                  onClick={() => {
                    window.location.href =
                      "https://guide.kumbang.co.kr/guide_view_07.html";
                  }}
                >
                  매수 이용가이드 보기
                </p>
                <button
                  className="org_bt"
                  onClick={() => navigate(routes.trading, { method: "buy" })}
                >
                  금/은 매수하기
                  <img src={ic_con_right} alt=">" className="ic_right" />
                </button>
              </div>
              <div>
                <h3 className="order_tit">STEP 3. 금/은 실물인출하기</h3>
                <p className="order_cont">
                  금/은 제품을 실물인출하여 수령할 수 있습니다.
                </p>
                <img src={buy3} alt="" className="buy_list_img" />
                <p className="order_txt">
                  매수한 금/은을 한국금거래소가 제조/보증하는
                  <br />
                  다양한 제품으로 실물인출 주문하여 안전하게
                  <br />
                  수령할 수 있습니다.
                </p>
                <p
                  className="guide_view"
                  onClick={() => {
                    window.location.href =
                      "https://guide.kumbang.co.kr/guide_view_08.html";
                  }}
                >
                  실물인출 이용가이드 보기
                </p>
                <button
                  className="org_bt"
                  onClick={() => navigate(routes.physicalWithDrawal)}
                >
                  실물인출 하기
                  <img src={ic_con_right} alt=">" className="ic_right" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="conbox con2">
          <div className="sell_wrap">
            <div className="sell_main">
              <h3 className="sell_tit">금방금방에서 팔기</h3>
              <p className="sell_cont">
                금방금방에서 가지고 있는 금/은 제품을 팔고 싶으시면
                <br />
                먼저 감정평가 접수를 해주세요.
              </p>
              <ul className="sell_list">
                <li>
                  <span className="spoqa">1</span>
                  <br />
                  <img src={sell_main1} alt="" />
                  <p>
                    감정평가
                    <br />
                    접수
                  </p>
                </li>
                <li className="bar"></li>
                <li>
                  <span className="spoqa">2</span>
                  <br />
                  <img src={sell_main2} alt="" />
                  <p>
                    주문하기
                    <br />
                    (금/은 매도)
                  </p>
                </li>
                <li className="bar"></li>
                <li>
                  <span className="spoqa">3</span>
                  <br />
                  <img src={sell_main3} alt="" />
                  <p>출금하기</p>
                </li>
              </ul>
            </div>
            <div className="sell_order">
              <div>
                <h3 className="order_tit">STEP 1. 감정평가 접수하기</h3>
                <p className="order_cont">
                  보유하고 있는 제품의 감정평가를 접수합니다.
                </p>
                <img src={sell1} alt="" className="sell_list_img" />
                <p className="order_txt">
                  보유하고 있는 금,은 제품을 감정평가 접수를 통해
                  <br />
                  자산으로 등록할 수 있습니다.
                </p>
                <p
                  className="guide_view"
                  onClick={() => {
                    window.location.href =
                      "https://guide.kumbang.co.kr/guide_view_10.html";
                  }}
                >
                  감정평가 이용가이드 보기
                </p>
                <button
                  className="org_bt"
                  onClick={() => navigate(routes.appraiseRegistMain)}
                >
                  금, 은 감정평가 접수하기
                  <img src={ic_con_right} alt=">" className="ic_right" />
                </button>
              </div>
              <div className="bg_gr">
                <h3 className="order_tit">STEP 2. 금/은 매도하기</h3>
                <p className="order_cont">
                  자산으로 보유한 금, 은을 <span>매도</span>합니다.
                </p>
                <img src={sell2} alt="" className="sell_list_img" />
                <p className="order_txt">
                  감정평가를 통해 자산으로 보유한 금, 은 자산을
                  <br />
                  매도하기 주문을 하여 팔 수 있습니다.
                </p>
                <p
                  className="guide_view"
                  onClick={() => {
                    window.location.href =
                      "https://guide.kumbang.co.kr/guide_view_06.html";
                  }}
                >
                  매도 이용가이드 보기
                </p>
                <button
                  className="org_bt"
                  onClick={() => navigate(routes.trading, { method: "sell" })}
                >
                  금/은 매도하기
                  <img src={ic_con_right} alt=">" className="ic_right" />
                </button>
              </div>
              <div>
                <h3 className="order_tit">STEP 3. 출금하기</h3>
                <p className="order_cont">
                  매도체결이 완료된 후 출금할 수 있습니다.
                </p>
                <img src={sell3} alt="" className="sell_list_img" />
                <p className="order_txt">
                  금, 은 매도 체결이 완료되면 원화 자산이 늘어납니다.
                  <br />
                  그러면 언제든 등록한 계좌로 출금신청을 할 수 있습니다.
                  <br />
                  출금신청을 위해서는 본인 실명계좌 인증이 필요합니다.
                </p>
                <p
                  className="guide_view"
                  onClick={() => {
                    window.location.href =
                      "https://guide.kumbang.co.kr/guide_view_04.html";
                  }}
                >
                  출금 이용가이드 보기
                </p>
                <button
                  className="org_bt"
                  onClick={() => navigate(routes.depositWithdrawal)}
                >
                  출금 신청하기
                  <img src={ic_con_right} alt=">" className="ic_right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomBarWithNavigate />
    </BasicLayout>
  );
}

const BottomBarWithNavigate = auth(Bottombar);
