import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Button from "../../components/button/Button";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";
import {
  getCurrentMyPosition,
  objToQueryStr,
  phoneFormat,
} from "../../services/utils";

export default function AppraiseDirect({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const userReducer = useSelector((s) => s.user);
  const state = history.location.state;

  const supply = () => {
    if (!state?.shop?.id) {
      dispatch(messageError("의뢰하실 매장을 선택해 주새요."));
    } else {
      dispatch(
        axiosDispatch(
          {
            method: "post",
            url: consts.apiBaseUrl + "/api/v1/user/market/appraisal/Requests",
            data: {
              acceptTermsYn: "Y",
              method: "현장감정",
              shopId: parseInt(state.shop.id),
              userId: userReducer.id,
            },
          },
          (err, res) => {
            if (err) {
              dispatch(messageError(err));
            } else {
              const appraisalRequestId = res.data.data.id;
              history.push(routes.exchange, {
                deliveryComplete: true,
                appraisalRequestId: appraisalRequestId,
              });
            }
          }
        )
      );
    }
  };

  if (!userReducer.id) {
    return <Redirect to={routes.appraise} />;
  }
  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "현장 감정 접수하기",
      }}
    >
      <Text className={classes.label} font={fonts.notoSansKRBold}>
        현장 감정을 의뢰하실
        <br />
        매장을 선택해 주세요.
      </Text>
      <ButtonMini
        onClick={() => {
          history.push(routes.authFindShop, {
            from: routes.appraiseDirect,
          });
        }}
        label="매장 찾기"
        style={{ marginBottom: state?.shop ? 0 : 30 }}
        className={classes.button}
      />
      {Boolean(state?.shop) && (
        <ShopItemSimple
          className={classes.shopItem}
          name={state?.shop.name}
          address={state?.shop.address}
          addressDetail={state?.shop.addressDetail}
          distance={state?.shop.distance}
        />
      )}
      <Text className={classes.info1}>접수자</Text>
      <Text font={fonts.notoSansKRMedium} className={classes.info2}>
        {userReducer.name}({phoneFormat(userReducer.phone)})
      </Text>
      <Button
        disabled={!Boolean(state?.shop)}
        onClick={supply}
        label="현장 감정 접수하기"
        className="fixed-button"
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: "20px",
    color: "#333",
    margin: "50px 30px 30px 30px",
  },
  button: {
    margin: "0px 30px",
    alignSelf: "flex-start",
  },
  shopItem: {
    margin: "0px 30px",
    marginTop: 10,
  },
  info1: {
    fontSize: 12,
    color: "#666",
    margin: "0px 30px",
  },
  info2: {
    margin: "0px 30px",
    color: "#333",
    fontSize: 16,
  },
});
