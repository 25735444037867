import React, { useEffect, useState } from "react";
import logo from "../../assets/images/kumbang_logo.png";
import list_event_banner01 from "../../assets/images/list_event_banner01.png";
import ic_notice_a_active from "../../assets/images/ic_notice_a_active.png";
import ic_no from "../../assets/images/ic_no.png";
import ic_con_right from "../../assets/images/ic_con_right.png";
import ic_notice_a_active_w from "../../assets/images/ic_notice_a_active_w.png";
import ic_con_right_b from "../../assets/images/ic_con_right_b.png";
import ic_notice_a from "../../assets/images/ic_notice_a.png";
import tr_noti from "../../assets/images/tr_noti.png";
import ev_noti from "../../assets/images/ev_noti.png";
import silver2 from "../../assets/images/silver2.png";
import guide from "../../assets/images/guide.png";
import ic_place from "../../assets/images/ic_place.png";
import ic_call from "../../assets/images/ic_call.png";
import footer_logo from "../../assets/images/footer_logo.png";
import AspectRatioBox from "../../components/common/AspectRatioBox";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import Image from "../../components/image/Image";
import useInterval from "../../hooks/useInterval";
import { refreshMarketPrice } from "../../libs/actions";
import Accordion from "./Accordion";
import { useSelector } from "react-redux";
import { formatTime, numberWithCommas, postMessage } from "../../libs/utils";
import consts, { SERVICE_TYPE } from "../../libs/consts";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";
import { useHistory } from "react-router-dom";
import auth from "../../hoc/auth";
import Bottombar from "../../components/bottombar/Bottombar";
import { ButtonBase, makeStyles } from "@material-ui/core";
import Modal from "../../components/modal/Modal";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";

SwiperCore.use([Navigation, Autoplay, Pagination]);

const Main = ({ navigate }) => {
  const history = useHistory();
  const marketPrice = useSelector((s) => s.marketPrice);
  const auth = useSelector((s) => s.auth);
  const [banners, setBanners] = useState([]);
  const [hotGoods, setHotGoods] = useState([]);
  const [hotSafeTrade, setHotSafeTrade] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const [myNotice, setMyNotice] = useState({});
  const [swiperIndex, setSwiperIndex] = useState(0);
  const state = history?.location?.state || {};
  const [signUpLimit, setSignUpLimit] = useState(null);

  useInterval(() => {
    refreshMarketPrice();
  }, 10000);

  useEffect(() => {
    init();

    // opacity 67
    if (state.errorType === "CANNOT_RESIGNUP") {
      const { leaveDate, reSignupDate } = state;
      setSignUpLimit({ leaveDate, reSignupDate });
      history.replace(routes.main);
    }
  }, []);

  const init = async () => {
    // 앱에서 뒤로가기 못하게 제어
    postMessage({ command: "CAN_NOT_GO_BACK" });

    // Flag 서버 호출하여 API 동작 여부 확인
    postMessage({ command: "CALL_FLAG_API" });

    const banners = await APIS.getEvent().then(
      ({ data: { success, data } }) => {
        if (success) {
          const { openEventList = [] } = data;

          const banners = [];

          openEventList.forEach((event) => {
            event.serviceImages.forEach((image) => {
              image.imageType === "BANNER" &&
                banners.push({
                  src: consts.s3BaseUrl + image.path,
                  content: event.content,
                });
            });
          });
          return banners;
        }
      }
    );

    if (banners?.length) {
      setBanners(banners);
    } else {
      setBanners([{ src: list_event_banner01 }]);
    }

    if (auth?.isSigned && auth?.role?.LEVEL > 1) {
      APIS.getMyNotice().then(({ data: { success, data } }) => {
        success && setMyNotice(data);
      });
    }

    APIS.getNotice().then(({ data: { success, data } }) => {
      success && setNoticeList(data.fixed);
    });

    APIS.getHotGoods().then(({ data: { success, data } }) => {
      success && setHotGoods(data);
    });

    APIS.getHotSafeTrade().then(({ data: { success, data } }) => {
      console.log({ data });
      success && setHotSafeTrade(data);
    });
  };

  useEffect(() => {
    if (auth.role.LEVEL < 2) {
      setMyNotice({});
    }
  }, [auth?.role?.LEVEL]);

  // social 사용자의 경우 본인인증 팝업 노출
  const isSocialUser = auth?.isSigned && auth?.role?.LEVEL === 1;

  return (
    <>
      {/* 헤더 */}
      <header>
        <img src={logo} alt="logo" />
      </header>
      {/* 메인 슬라이드 */}
      <AspectRatioBox width={1080} height={600}>
        {!!banners.length &&
          (banners.length > 1 ? (
            <Swiper
              style={{ width: "100%", height: "100%", background: "#fff" }}
              loop={true}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              className="visual_slide"
              onRealIndexChange={(swiper) => setSwiperIndex(swiper.realIndex)}
            >
              {banners.map(({ src, content }, i) => (
                <SwiperSlide
                  key={i.toString()}
                  onClick={() => {
                    if (content) {
                      postMessage({
                        command: "SET_HEADER",
                        param: {
                          visible: true,
                          goBack: "DEFAULT",
                          title: "이벤트",
                        },
                      });

                      window.location.href = content;
                    }
                  }}
                >
                  <img src={src} alt="이벤트베너" width={"100%"} />
                </SwiperSlide>
              ))}
              <ul style={{ zIndex: 1 }}>
                {banners.map((_, i) => (
                  <li key={i.toString()}>
                    <div
                      className={`Pagination ${swiperIndex === i && "on"}`}
                    />
                  </li>
                ))}
              </ul>
            </Swiper>
          ) : (
            <img
              src={banners[0].src}
              alt="이벤트베너"
              width={"100%"}
              onClick={() => {
                if (banners[0].content) {
                  postMessage({
                    command: "SET_HEADER",
                    param: {
                      visible: true,
                      goBack: "DEFAULT",
                      title: "이벤트",
                    },
                  });

                  window.location.href = banners[0].content;
                }
              }}
            />
          ))}
      </AspectRatioBox>
      {/* 메인 시작 */}
      <div className="kumbang_wrap" style={{ zIndex: 1 }}>
        <div className="kumbang_main">
          {/* 본인 인증 */}
          {isSocialUser && (
            <MyNotice
              type="SELF_VERIFICATION"
              message="본인인증을 진행해 주세요."
              navigate={navigate}
            />
          )}
          {!!myNotice?.type && <MyNotice {...myNotice} navigate={navigate} />}

          <div className="time spoqa">
            ({formatTime(marketPrice.updatedAt, "YYYY.MM.DD HH:mm:ss")})
          </div>
          <div className="accordion vertical">
            <ul>
              <Accordion
                asset="gold"
                navigate={navigate}
                hotGoods={hotGoods.filter(
                  ({ assetType }) => assetType === "GOLD"
                )}
              />
              <Accordion
                asset="silver"
                navigate={navigate}
                hotGoods={hotGoods.filter(
                  ({ assetType }) => assetType === "SILVER"
                )}
              />
              <li>
                <input
                  type="checkbox"
                  id="checkbox-3"
                  name="checkbox-accordion"
                />
                <label htmlFor="checkbox-3">
                  <span className="li_tit other">
                    명품 시계, 귀금속, 다이아몬드
                  </span>
                </label>
                <div className="content content3">
                  <div className="btn_cx_up"></div>
                  <div className="price_product">
                    <div className="product_tit">
                      <h3>
                        안심직거래 인기 상품{" "}
                        <img src={ic_con_right_b} alt=">" />
                      </h3>
                      <button
                        className="btn_all"
                        onClick={() => history.push(routes.safeTradeList)}
                      >
                        전체 보기
                      </button>
                    </div>
                    <div className="product_cont">
                      <ul>
                        <li className="product_card jewelry">
                          <h3>안심직거래</h3>
                          <p>상세 안내 보기</p>
                        </li>

                        {hotSafeTrade.map((item, i) => {
                          return (
                            <li
                              className="product_card"
                              key={i.toString()}
                              onClick={() => {
                                history.push(routes.safeTradeItem, {
                                  id: item.item_id,
                                });
                              }}
                            >
                              <img
                                src={`${consts.s3BaseUrl}/${item.image}`}
                                alt=""
                              />
                              <p>{item.name}</p>
                              <p className="product_price">
                                <span className="spoqa">
                                  {numberWithCommas(item.price)}
                                </span>
                                원
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {!!noticeList.length && (
            <div className="kumbang_noti">
              <Swiper
                style={{ flex: 1 }}
                loop={noticeList.length > 1}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
              >
                {noticeList.map((item, i) => {
                  return (
                    <SwiperSlide
                      key={i.toString()}
                      onClick={() => {
                        history.push(routes.noticeDetail(i), { id: item.id });
                      }}
                    >
                      <p>
                        <img src={ic_notice_a} alt="" />
                        {item.subject}
                      </p>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <button
                className="btn_all"
                onClick={() => {
                  history.push(routes.noticeList);
                }}
              >
                더보기
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="kumbang_guide">
        <div className="notice">
          <div
            className="tr_notice"
            onClick={() => history.push(routes.tradingGuide)}
          >
            <div className="noti_tit">거래절차 안내</div>
            <div className="noti_cont">
              <p>
                매수/매도 주문
                <br />
                입금/출금
                <br />
                실물 인출
              </p>
              <img src={tr_noti} alt="" />
            </div>
          </div>
          <div
            className="ev_notice"
            onClick={() => history.push(routes.appraiseRegistMain)}
          >
            <div className="noti_tit">감정평가/판매접수</div>
            <div className="noti_cont">
              <p>
                간편 감정
                <br />
                현장 감정
                <br />
                안심직거래
              </p>
              <img src={ev_noti} alt="" />
            </div>
          </div>
        </div>
        <div
          className="guide"
          onClick={() =>
            (window.location.href = "https://guide.kumbang.co.kr/index.html")
          }
        >
          <div className="noti_tit">
            이용가이드
            <div className="noti_cont">
              금방금방이 처음이시라면
              <br />
              이용가이드를 통해 도움 받으세요!
            </div>
          </div>
          <img src={guide} alt="" />
        </div>
      </div>
      <footer>
        <div className="kumbang_agency">
          <div
            className="agency_noti"
            onClick={() => {
              postMessage({
                command: "LOG_APPS_FLYER",
                param: { event: "gb_search_store" },
              });
              navigate(routes.authFindShop, { from: routes.main });
            }}
          >
            <img src={ic_place} alt="" /> 대리점 안내
          </div>
          <div
            className="agency_conc"
            onClick={() => {
              postMessage({
                command: "LOG_APPS_FLYER",
                param: { event: "gb_search_call" },
              });
              window.location.href = "tel:15449993";
            }}
          >
            <img src={ic_call} alt="" /> 대리점 연결
          </div>
        </div>
        <div className="bottom_logo">
          <img src={footer_logo} alt="한국금거래소" />
          <p>
            서울 종로구 돈화문로 <span className="spoqa">5</span>가길{" "}
            <span className="spoqa">1 7</span>층 /{" "}
            <span className="spoqa">1670-8978</span>
          </p>
        </div>
      </footer>
      <BottomBarWithNavigate />
      {!!signUpLimit && (
        <SignUpLimitModal
          {...signUpLimit}
          onClose={() => setSignUpLimit(null)}
        />
      )}
    </>
  );
};

const BottomBarWithNavigate = auth(Bottombar);

const MyNotice = ({ type, message, id, navigate }) => {
  let buttonText = "";
  let handleClickButton = () => {};

  switch (type) {
    case "SELF_VERIFICATION":
      buttonText = "본인인증 진행";
      handleClickButton = () =>
        navigate(routes.selfVerification, {
          purpose: "SIGNUP",
        });
      break;
    case "APPRAISAL":
      buttonText = "감정평가";
      handleClickButton = () => navigate(routes.appraise, { tab: 1 });
      break;
    case "GOODS":
      buttonText = "실물인출";
      handleClickButton = () => navigate(routes.myPhysicalWithDrawal);
      break;
  }

  return (
    <div className="main_pop">
      <div className="pop_noti">
        <img src={ic_notice_a_active} alt="" /> {message}
        {/* <img src={ic_no} alt="x" className="btn_close" /> */}
      </div>
      <button onClick={handleClickButton}>
        {buttonText}
        <img src={ic_con_right} />
      </button>
    </div>
  );
};

const SignUpLimitModal = ({ onClose, leaveDate, reSignupDate }) => {
  const classes = useStyle();
  return (
    <Modal onClickBackground={onClose}>
      <Flex className={classes.withdrawalWrap}>
        <Text style={{ fontSize: 24, fontWeight: 700 }}>재가입 불가</Text>
        <Text style={{ fontSize: 16, color: "gray", marginTop: 10 }}>
          ※ {formatTime(leaveDate, "YYYY.MM.DD")} 탈퇴하셨습니다.
        </Text>
        <Text style={{ fontSize: 16, color: "gray", marginTop: 5 }}>
          ※ {formatTime(reSignupDate, "YYYY.MM.DD")} 부터 재가입 가능합니다.
        </Text>

        <ButtonBase className={classes.btn} onClick={onClose}>
          <Text>확인</Text>
        </ButtonBase>
      </Flex>
    </Modal>
  );
};

const useStyle = makeStyles({
  withdrawalWrap: {
    flex: 1,
    alignSelf: "flex-end",
    boxSizing: "border-box",
    position: "relative",
    backgroundColor: "#fff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: "30px 0",
    alignItems: "center",
  },
  btn: {
    width: 200,
    backgroundColor: "#FC5710",
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    padding: 5,
    borderRadius: 5,
    marginTop: 50,
  },
});

export default Main;
