import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { userInit } from "../../redux/user/UserActions";

export default function AuthRegisterShop({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};

  const { recommandShop } = useSelector((s) => s.signUp);

  const register = () => {
    history.push(routes.authRegisterPassword);
  };

  const { id, name } = recommandShop;

  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "추천 매장",
      }}
    >
      <Text font={fonts.notoSansKRMedium} className={classes.label1}>
        추천 매장을 선택해주세요.
      </Text>
      <Text font={fonts.notoSansKRMedium} className={classes.label1}>
        추천 매장이 없으면 &quot;추천 매장 찾기&quot; 후<br />
        &quot;금방금방 본사&quot;를 선택해주세요
      </Text>

      {id && (
        <ShopItemSimple {...recommandShop} shopName={name} className="margin" />
      )}
      <Flex className={classes.bottom}>
        <Button
          onClick={() => history.push(routes.authFindShop)}
          label="추천 매장 찾기"
        />
        <Flex style={{ height: 10 }} />
        <Button
          onClick={register}
          label={"다음"}
          className={`${classes.button} ${!id ? classes.disabled : ""}`}
          disabled={!id}
          textStyle={{ color: !id ? "#c2c2c2" : "black" }}
        />
      </Flex>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label1: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  bottom: {
    position: "absolute",
    bottom: 50,
    left: 30,
    width: "calc(100% - 60px)",

    // marginLeft: 30,
    // marginRight: 30,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
  button: { backgroundColor: "white", border: `1px solid ${colors.primary}` },
  disabled: { background: "#f4f4f4", border: "none" },
});
