import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import BasicLayout from "../../layouts/basic/BasicLayout";
import consts from "../../libs/consts";
import { getCurrentMyPosition, objToQueryStr } from "../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { toastShow } from "../../redux/toast/ToastActions";
import ShopItemSelect from "../../components/shop-item-select/ShopItemSelect";
import { useHistory } from "react-router";
import { messageError } from "../../redux/message/MessageActions";
import axios from "axios";
import * as APIS from "../../libs/apis";
import InputShadow from "../../components/input-shadow/InputShadow";
import SearchTopbar from "../../components/search-topbar/SearchTopbar";
import images from "../../libs/images";
import {
  callPhone,
  getDistanceFromLatLonInKm,
  numberWithCommas,
} from "../../libs/utils";
import queryString from "query-string";
import { updateSignUp } from "../../redux/auth/SignUpReducer";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import routes from "../../libs/routes";
import { escapeRegExp } from "lodash";
import { initFindShop, updateFindShop } from "../../redux/common/findShop";
import search_new from "../../assets/images/search_new.png";
import ic_place_bk from "../../assets/images/ic_place_bk.png";
import ic_phone_bk from "../../assets/images/ic_phone_bk.png";
import ic_delete from "../../assets/images/delete.png";

const CommonFindShop = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};

  const [shops, setShops] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    dispatch(loadingStart);
    dispatch(initFindShop());
    init();
  }, []);

  const init = async () => {
    // 현재 위치 불러오기
    const position = await getCurrentMyPosition()
      .then((position) => {
        return position;
      })
      .catch((position) => {
        return position;
      });

    // 가입 시 본사 우선 노출
    const shopSearchIf = history.location.state?.from ? "" : "RECOMMEND_SHOP";
    const { lat, lng } = position;

    APIS.getNearbyShop(objToQueryStr({ latitude: lat, longitude: lng }))
      .then(({ data: { success, data } }) => {
        if (success) {
          setShops(
            data.map((item) => {
              const { latitude, longitude } = item;
              let distance = 0;
              if (lat && lng && latitude && longitude) {
                distance = getDistanceFromLatLonInKm(
                  lat,
                  lng,
                  latitude,
                  longitude
                ).toFixed(2);
              }
              return { ...item, distance };
            })
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const handleClickShop = (shop) => {
    if (!state.readOnly) {
      dispatch(updateFindShop(shop));
    }

    history.goBack();
  };

  const mapDataToComponent = (shops) => {
    return shops.map((shop, i) => {
      const { id, name, address, phoneNumber, distance, shopType } = shop;

      return (
        <div
          className="store_visit_re"
          onClick={() => handleClickShop(shop)}
          key={i.toString()}
        >
          <img src={ic_place_bk} alt="" className="ic_okay" />
          <div>
            <p className="store_name">
              <span>{name}</span>{" "}
              {!!distance && (
                <>
                  <span className="spoqa">{numberWithCommas(distance)}</span>km
                </>
              )}
            </p>
            <p className="address">{address}</p>
            <img src={ic_phone_bk} />
            <span
              className="spoqa orgbt"
              onClick={(e) => {
                e.stopPropagation();
                callPhone(phoneNumber);
              }}
            >
              {phoneNumber}
            </span>
          </div>
        </div>
      );
    });
  };

  function ch2pattern(ch) {
    const offset = 44032; /* '가'의 코드 */
    // 한국어 음절
    if (/[가-힣]/.test(ch)) {
      const chCode = ch.charCodeAt(0) - offset;
      // 종성이 있으면 문자 그대로를 찾는다.
      if (chCode % 28 > 0) {
        return ch;
      }
      const begin = Math.floor(chCode / 28) * 28 + offset;
      const end = begin + 27;
      return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 한글 자음
    if (/[ㄱ-ㅎ]/.test(ch)) {
      const con2syl = {
        ㄱ: "가".charCodeAt(0),
        ㄲ: "까".charCodeAt(0),
        ㄴ: "나".charCodeAt(0),
        ㄷ: "다".charCodeAt(0),
        ㄸ: "따".charCodeAt(0),
        ㄹ: "라".charCodeAt(0),
        ㅁ: "마".charCodeAt(0),
        ㅂ: "바".charCodeAt(0),
        ㅃ: "빠".charCodeAt(0),
        ㅅ: "사".charCodeAt(0),
      };
      const begin =
        con2syl[ch] ||
        (ch.charCodeAt(0) - 12613) /* 'ㅅ'의 코드 */ * 588 + con2syl["ㅅ"];
      const end = begin + 587;
      return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 그 외엔 그대로 내보냄
    // escapeRegExp는 lodash에서 가져옴
    return escapeRegExp(ch);
  }
  function createFuzzyMatcher(input) {
    const pattern = input.split("").map(ch2pattern).join(".*?");
    return new RegExp(pattern);
  }

  const ShopList = useMemo(() => {
    const matchShops = shops.filter(({ name, address }) => {
      return createFuzzyMatcher(keyword).test(name);
    });
    return mapDataToComponent(matchShops);
  }, [shops, keyword]);

  return (
    <BasicLayout white stackTopbar={{ title: "매장 찾기", border: true }}>
      <div className="order_wrap store">
        <div className="store_search">
          <input
            type="text"
            className="st_search"
            placeholder="매장명 검색, 예)종로,강동 대전"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          {!!keyword && (
            <img
              src={ic_delete}
              className="ic_delete"
              onClick={() => setKeyword("")}
            />
          )}

          <img src={search_new} alt="" className="ic_search" />
        </div>
        <div className="store_visit">
          {/* <div className="store_visit_sub_tit">가까운 매장</div> */}

          {ShopList}
        </div>
      </div>
    </BasicLayout>
  );
};

export default CommonFindShop;
