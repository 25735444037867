import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ic_close from "../../assets/images/ic_close.png";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";
import { actionError } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { toastShow } from "../../redux/toast/ToastActions";

const PasswordSecurity = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef(null);
  const locationState = history.location.state || {};
  const { type, payload } = locationState;

  const [password, setPassword] = useState("");

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleClickPayment = () => {
    if (password.length === 6) {
      dispatch(loadingStart);
      switch (type) {
        case "SAFE_TRADE_BUY":
          // 안심직거래 구매 결제요청
          APIS.postSafeTradeBuyPayment({ ...payload, password })
            .then(({ data: { success, message } }) => {
              if (success) {
                dispatch(toastShow("결제 요청이 완료되었습니다."));
                history.push(routes.main);
                history.push(routes.mySafeTradeList);
              } else {
                dispatch(actionError(message));
              }
            })
            .finally(() => dispatch(loadingEnd));
          break;
      }
    }
  };

  return (
    <div className="password_wrap" style={{ flex: 1 }}>
      <div className="password_view_wrap" style={{ flex: 1 }}>
        <div className="password_view">
          <img src={ic_close} onClick={() => history.goBack()} />
          <p>보안 비밀번호</p>
          <div
            className="password_num"
            style={{ position: "relative" }}
            onClick={() => inputRef.current.focus()}
          >
            <ul>
              <li className={password.length > 0 ? "on" : ""} />
              <li className={password.length > 1 ? "on" : ""} />
              <li className={password.length > 2 ? "on" : ""} />
              <li className={password.length > 3 ? "on" : ""} />
              <li className={password.length > 4 ? "on" : ""} />
              <li className={password.length > 5 ? "on" : ""} />
            </ul>
          </div>
          <button
            className={`password_btn ${password.length === 6 ? "on" : ""}`}
            onClick={handleClickPayment}
          >
            결제하기
          </button>
        </div>
      </div>
      <input
        ref={inputRef}
        type="number"
        style={{ position: "absolute", width: 0, height: 0, opacity: 0 }}
        value={password}
        onChange={(e) => {
          if (e.target.value.length <= 6) {
            setPassword(e.target.value);
          } else {
            document.activeElement.blur();
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            document.activeElement.blur();
          }
        }}
      />
    </div>
  );
};

export default PasswordSecurity;
