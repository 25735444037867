import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PhysicalWithDrawalItem from "../../components/physical-withdrawal-item/PhysicalWithDrawalItem";
import StackTopbar from "../../layouts/basic/StackTopbar";
import MainLayout from "../../layouts/main/MainLayout";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import * as APIS from "../../libs/apis";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";

export default function MyPhysicalWithDrawal({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [productlist, setsProductlist] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(1000);
  //const [productData, setproductData] = useState({});
  const [setprice, setSetprice] = useState(0);
  const [listreload, setlistreload] = useState(0);
  const { id } = useSelector((s) => s.user);
  //const [settype, setSettype] = useState('GOLD');
  const [currentPrice, setCurrentPrice] = useState({ gold: 0, silver: 0 });

  useEffect(() => {
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/goodswithdrawal/trans" +
            "/list?page=" +
            start +
            "&size=" +
            limit +
            "",
        },
        (err, res) => {
          if (err) {
            //dispatch(actionError(err));
          } else {
            var list = [];
            var data = res.data.content;
            setsProductlist(data);
          }
        }
      )
    );

    APIS.getCurrentClosingMarketPrice("GOLD").then(({ data: { data } }) => {
      setCurrentPrice((price) => {
        return { ...price, gold: data.split(",")[0] };
      });
    });
    APIS.getCurrentClosingMarketPrice("SILVER").then(({ data: { data } }) => {
      setCurrentPrice((price) => {
        return { ...price, silver: data.split(",")[0] };
      });
    });
  }, []);

  return (
    <MainLayout white topbar={<StackTopbar border title="내 실물인출" />}>
      {productlist.length ? (
        productlist.map((x, i) => {
          return (
            <>
              <PhysicalWithDrawalItem
                key={i}
                rows={x}
                sPrice={"get"}
                settype={"get"}
                order
                currentPrice={currentPrice}
                toLocation={"orderDetail"}
              />
            </>
          );
        })
      ) : (
        <Flex
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <Text font={fonts.notoSansKRBold} style={{ fontSize: 16 }}>
            실물인출 거래내역이 없습니다.
          </Text>
        </Flex>
      )}
    </MainLayout>
  );
}

const useStyle = makeStyles({});
