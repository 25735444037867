import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ic_check_white from "../../assets/images/ic_check_white.png";
import ic_close from "../../assets/images/ic_close.png";
import ic_place_white from "../../assets/images/ic_place_white.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { encodeFileToBase64, numberWithCommas } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import {
  initSafeTradeOrder,
  updateSafeTradeOrder,
} from "../../redux/safe-trade/safeTradeOrder";
import { initFindShop } from "../../redux/common/findShop";

export default function SafeTradeStep4({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { shop, reservation, reservation_time } = useSelector(
    (state) => state.safeTradeOrder
  );

  const safeTradeOrder = useSelector((state) => state.safeTradeOrder);

  const findShop = useSelector((state) => state.findShop);

  const [timeOption, setTimeOption] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    init();
    if (findShop.id) {
      updateOrder({ shop: findShop });
      dispatch(initFindShop());
    }
  }, []);

  const init = async () => {
    let openTime = await APIS.getSharedCodeTrade("SHOP_OPEN_TIME").then(
      ({ data: { data } }) => {
        return data.value;
      }
    );

    let closeTime = await APIS.getSharedCodeTrade("SHOP_CLOSE_TIME").then(
      ({ data: { data } }) => {
        return data.value;
      }
    );

    const timeArray = [];
    while (openTime < closeTime) {
      if (!openTime || !closeTime) {
        break;
      }
      let open = +openTime;
      let close = open + 200;
      if (close > +closeTime) {
        close = +closeTime;
      }

      timeArray.push({ open, close });
      openTime = close;
    }

    const convertedArray = timeArray.map(({ open, close }) => {
      let openStr = open < 1000 ? "0" + open : "" + open;
      openStr = `${openStr.substring(0, 2)}:${openStr.substring(2)}`;

      let closeStr = close < 1000 ? "0" + close : "" + close;
      closeStr = `${closeStr.substring(0, 2)}:${closeStr.substring(2)}`;

      return `${openStr}~${closeStr}`;
    });
    setTimeOption(convertedArray);

    if (!reservation_time) {
      updateOrder({ reservation_time: convertedArray[0] });
    }
  };

  const updateOrder = (order) => dispatch(updateSafeTradeOrder(order));

  const handleClickNext = () => {
    if (shop?.id && reservation && reservation_time) {
      const formData = new FormData();

      const dto = { ...safeTradeOrder, shop_id: shop.id };
      delete dto.shop;

      const imageKeys = ["front", "side", "back", "model", "contract", "parts"];
      for (let key of imageKeys) {
        if (dto[key]) {
          formData.append(key, dto[key]);
        }
        delete dto[key];
      }

      console.log("dto", dto);

      formData.append(
        "safetrade",
        new Blob([JSON.stringify(dto)], { type: "application/json" })
      );

      for (let key of formData.keys()) {
        console.log(key, ":", formData.get(key));
      }

      dispatch(loadingStart);
      APIS.postSafeTradeOrder(formData)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            history.push(routes.main);
            history.push(routes.safeTradeCreateComplete, data);
            dispatch(initSafeTradeOrder());
          } else {
            dispatch(actionError(message));
          }
        })
        .catch(() => {
          dispatch(actionError("알 수 없는 오류가 발생하였습니다"));
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };

  return (
    <BasicLayout
      stackTopbar={{
        title: "상품 등록",
        option: (
          <ButtonBase onClick={() => history.replace(routes.main)}>
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}
    >
      <div className="trade_wrap">
        <div className="store_fst">
          <h3 className="store_tit">
            제품 판매 접수를 위해
            <br />
            의뢰하실 매장을 선택해 주세요.
          </h3>
          <div
            className="store_find"
            onClick={() => {
              history.push(routes.commonFindShop);
            }}
          >
            매장찾기
          </div>
          {!!shop && (
            <>
              <div className="store_sub_tit">선택 매장</div>
              <div className="store_re">
                <img src={ic_place_white} alt="" className="ic_place" />
                <span>{shop.name}</span>
                {!!shop.distance && (
                  <>
                    <span className="spoqa">
                      {numberWithCommas(shop.distance)}
                    </span>
                    km
                  </>
                )}

                <p>{shop.address}</p>
              </div>
            </>
          )}

          <div className="store_sub_tit">
            희망하시는 매장 방문 일자를 선택하세요.
          </div>

          <div
            className={`store_date ${reservation && "spoqa"}`}
            style={{ position: "relative" }}
          >
            {reservation || "선택"}
            <input
              type="date"
              className={classes.inputDate}
              value={reservation}
              onChange={(e) => updateOrder({ reservation: e.target.value })}
            />
          </div>
          <div className="store_sub_tit">
            희망하시는 매장 방문 시간을 선택하세요.
          </div>
          <div
            className="store_time spoqa"
            onClick={() => setModalVisible(true)}
          >
            {reservation_time || "선택"}
          </div>
        </div>
        <div
          className={`ok_btn ${
            shop?.id && reservation && reservation_time && "on"
          }`}
          onClick={handleClickNext}
        >
          다음
        </div>
      </div>
      <TimeSelectModal
        option={timeOption}
        visible={modalVisible}
        value={reservation_time}
        onChange={(reservation_time) => updateOrder({ reservation_time })}
        onClose={() => setModalVisible(false)}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  inputDate: {
    opacity: 0,
    position: "absolute !important",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100% !important",
    zIndex: 1,
  },
});

const TimeSelectModal = ({
  visible = false,
  option = [],
  value,
  onChange,
  onClose,
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [visible]);
  return (
    <div
      className="trade_wrap_bk"
      style={{
        display: visible ? "block" : "none",
        position: "fixed",
        top: 0,
        zIndex: 10000,
      }}
    >
      <div className="pd_select">
        <div className="select_tit">
          <h3>시간 선택</h3>
          <img src={ic_close} alt="X" onClick={onClose} />
        </div>
        <div className="select_list">
          <ul className="spoqa">
            {option.map((time, key) => (
              <li
                key={key.toString()}
                className={`${selected === time && "on"}`}
                onClick={() => setSelected(time)}
              >
                <div className="ck_cir">
                  <img src={ic_check_white} alt="" />
                </div>
                {time}
              </li>
            ))}
          </ul>
        </div>
        <div
          className="ok_btn on"
          onClick={() => {
            onChange(selected);
            onClose();
          }}
        >
          확인
        </div>
      </div>
    </div>
  );
};
