import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import images from "../../libs/images";

export default function NotificationItem({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex row className={classes.root}>
      <img src={images.notification} className={classes.icon} />
      <Flex className={classes.item}>
        <Text font={fonts.notoSansKRMedium} className={classes.l1}>
          위탁 매도 - 감정평가 접수
        </Text>
        <Text className={classes.l2}>
          접수번호
          <Text font={fonts.notoSansKRMedium}> 45646464646121</Text>
        </Text>
        <Text className={classes.l3}>
          위탁 매도를 위한 감정평가 요청이 접수되었습니다.
        </Text>
        <Text className={classes.l4}>2018.03.15 15:46</Text>
      </Flex>
      <ButtonBase style={{ alignSelf: "flex-start" }}>
        <img src={images.close} className={classes.icon} />
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    borderBottom: "1px solid #eee",
    padding: "20px 13px",
  },
  icon: {
    widows: "14px",
    height: "16px",
  },
  item: {
    flex: 1,
    margin: "0px 9px",
  },
  l1: {
    lineHeight: 1,
    color: "#333",
    fontSize: "16px",
  },
  l2: {
    marginTop: "5px",
    color: "#999",
    fontSize: "12px",
    "& span": {
      color: "#333333",
    },
  },
  l3: {
    marginTop: "8px",
    fontSize: "12px",
    color: "#333333",
  },
  l4: {
    fontSize: "11px",
    color: "#999999",
  },
});
