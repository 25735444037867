import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";
import { actionError } from "../../redux/action/ActionActions";

import Flex from "../../components/flex/Flex";
import InputSmallBorder from "../../components/input-small-border/InputSmallBorder";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import DataButton from "./components/DataButton";
import Radio from "./components/Radio";
import FormPopup from "./FormPopup";

import NumberFormat from "react-number-format";
import * as APIS from "../../libs/apis";
import { ROLE } from "../../libs/routes";

export default function Form({
  settype,
  price,
  method,
  onChangePrice,
  feeRates = [],
  authBlock,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(method === "buy" ? 0 : 1);
  const { id } = useSelector((s) => s.user);
  const [priceType, setPriceType] = useState("supply");
  const [purchaseData, setPurchaseData] = useState({ open: false });
  const [posibleprice, setposibleprice] = useState(0);
  const [inputprice, setinputprice] = useState(price);
  const [gram, setgram] = useState("");
  const [don, setdon] = useState("");
  const [totalprice, settotalprice] = useState("0");
  const [fee, setFee] = useState(0);

  const auth = useSelector((state) => state.auth);

  Number.prototype.format = function () {
    if (this == 0) return 0;

    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = this + "";

    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

    return n;
  };

  // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
  String.prototype.format = function () {
    var num = parseFloat(this);
    if (isNaN(num)) return "";

    return num.format();
  };

  //setinputprice(price.format());

  useEffect(() => {
    const total = (+price * gram).toFixed(0);

    const feeRate = (feeRates.find(({ configType, assetType }) => {
      const method = index === 0 ? "BUY" : "SELL";
      return assetType === settype && configType === `${method}_FEE_RATE`;
    }) || {})["value"];

    let fee = parseInt(total * feeRate);
    if (index === 1) {
      fee = -fee;
    }
    settotalprice(total);
    setFee(fee);
  }, [price, gram, don]);

  useEffect(() => {
    init();
  }, [index]);

  const init = () => {
    if (auth?.role?.LEVEL < ROLE.ROLE_SELF_VERIFICATION.LEVEL) {
      return false;
    }
    setdon("");
    setgram("");

    APIS.getAvailableAmount().then(({ data: { data } }) => {
      const { gold = 0, silver = 0, krw = 0 } = data || {};
      if (index === 1) {
        if (settype === "GOLD") {
          setposibleprice(gold);
        } else {
          setposibleprice(silver);
        }
      } else {
        setposibleprice(krw);
      }
    });
  };

  const handleIndexChange = (index) => () => {
    setIndex(index);
  };

  const handleTrade = () => {
    if (!authBlock()) {
      return false;
    }

    if (settype === "SILVER") {
      if (price % 5 !== 0) {
        dispatch(actionError("5원단위로 가격을 입력해주세요."));
        return;
      }
      if (+(+gram * 10).toFixed(11) % 5 !== 0) {
        dispatch(actionError("0.5g 단위로 중량을 입력해주세요."));
        return;
      }
    } else {
      if (price % 100 !== 0) {
        dispatch(actionError("100원단위로 가격을 입력해주세요."));
        return;
      }

      if (+(+gram * 1000).toFixed(9) % 5 !== 0) {
        dispatch(actionError("0.005g 단위로 중량을 입력해주세요."));
        return;
      }
    }

    if (index === 1) {
      if (posibleprice < gram) {
        dispatch(actionError("주문가능 금액을 초과하였습니다."));
        return;
      }
    } else {
      if (posibleprice < +totalprice + (fee || 0)) {
        dispatch(actionError("주문가능 금액을 초과하였습니다."));
        return;
      }
    }
    if (price < 1) {
      dispatch(actionError("가격을 입력해 주세요"));
      return;
    }
    if (gram < 0.001) {
      dispatch(actionError("중량을 입력해 주세요"));
      return;
    }
    setPurchaseData({
      open: true,
      method: index === 0 ? "buy" : "sell",
      settype,
      priceType,
      inputprice: price,
      gram,
      totalprice,
      id,
      fee,
    });
  };

  const handlePurchasePopupClose = () => {
    setPurchaseData({
      open: false,
    });
    //setgraminput("");
    //setdoninput("");
    //settotalprice("");
    /*
    if(tradeorder === 0){
      settradeorder(1);
    } else {
      settradeorder(0);
    }
    */
  };
  const setgraminput = (val) => {
    var dondata = (val / 3.75).toFixed(2);
    setdon(dondata);
    setgram(val);
  };
  const setdoninput = (val) => {
    var gramdata = (val * 3.75).toFixed(2);
    setdon(val);
    setgram(gramdata);
  };

  const priceSum = function (val) {
    onChangePrice(+price + +val);
  };

  function priceMinus(val) {
    const nextPrice = +price - +val;
    if (nextPrice < 0) {
      onChangePrice(0);
    } else {
      onChangePrice(nextPrice);
    }
  }

  function gramsum(val) {
    setgram(+(+gram + +val).toFixed(3));
  }

  function donsum(val) {
    setgram(+(+gram + +val * 3.75).toFixed(3));
  }

  const handleClickClear = () => {
    onChangePrice("");
    setdon("");
    setgram("");
  };

  return (
    <Flex className={classes.root}>
      {purchaseData.open && (
        <FormPopup
          {...purchaseData}
          onClose={handlePurchasePopupClose}
          onSuccess={() => {
            handleClickClear();
            init();
          }}
        />
      )}
      <Flex row>
        <ButtonBase
          onClick={handleIndexChange(0)}
          className={`${classes.button} ${index === 0 && classes.buttonSel}`}
        >
          매수
        </ButtonBase>
        <ButtonBase
          onClick={handleIndexChange(1)}
          className={`${classes.button} ${index === 1 && classes.buttonSel2}`}
        >
          매도
        </ButtonBase>
      </Flex>
      <Flex className={classes.content}>
        <Text className={classes.message1}>
          {auth?.role?.LEVEL >= ROLE.ROLE_SELF_VERIFICATION.LEVEL && (
            <>
              주문가능
              <Text
                font={fonts.notoSansKRBold}
                className={classes.message1Bold}
              >
                <NumberFormat
                  value={posibleprice}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                {index === 1 ? "g" : "원"}
              </Text>
            </>
          )}
        </Text>
        <Radio
          value={priceType}
          onChange={setPriceType}
          data={[
            { label: "지정가격", value: "supply" },
            //{ label: "시장가", value: "market" },
          ]}
          className={classes.radios}
        />
        {priceType === "market" ? (
          <>
            <InputSmallBorder
              className={classes.input}
              placeholder="0 원"
              label="총액"
              value={totalprice}
              onChange={(v) => settotalprice(v)}
              readonly={false}
              number={true}
            />
          </>
        ) : (
          <>
            <Flex row className={classes.form}>
              <InputSmallBorder
                placeholder="0 원"
                value={price}
                number={true}
                onChange={(e) => {
                  onChangePrice(e);
                }}
                label=""
                className={classes.priceInput}
              />
              {settype === "SILVER" ? (
                <>
                  <DataButton
                    image={images.plus}
                    onClickfunc={priceSum}
                    funcdata={5}
                  />
                  <DataButton
                    image={images.minus}
                    onClickfunc={priceMinus}
                    funcdata={5}
                  />
                </>
              ) : (
                <>
                  <DataButton
                    image={images.plus}
                    onClickfunc={priceSum}
                    funcdata={100}
                  />
                  <DataButton
                    image={images.minus}
                    onClickfunc={priceMinus}
                    funcdata={100}
                  />
                </>
              )}
            </Flex>

            <Flex>
              <InputSmallBorder
                placeholder={settype === "SILVER" ? "최소0.5" : "최소0.005"}
                value={gram}
                number2={true}
                onChange={(e) => {
                  /* 
                  // 금 : 소수점 3자리
                  // 은 : 소수점 1자리
                  const digits = settype === "SILVER" ? 1 : 3;

                  const value = Number(e).toFixed(digits);

                  const multiple = Math.pow(10, digits);
                  const calc = (value * multiple).toFixed(0);

                  // 5 단위 가능
                  if (+calc % 5 === 0) {
                    setgraminput(Number(value) + "");
                  }
 */

                  setgraminput(e);
                }}
                label="중량"
                labeloption="g"
              />
              <Flex row className={classes.buttons}>
                <DataButton label="+10g" onClickfunc={gramsum} funcdata={10} />
                <DataButton
                  label="+100g"
                  onClickfunc={gramsum}
                  funcdata={100}
                />
                <DataButton
                  label="+1000g"
                  onClickfunc={gramsum}
                  funcdata={1000}
                  last
                />
              </Flex>
            </Flex>

            <Flex style={{ marginTop: "12px" }}>
              <Flex row>
                <Text font={fonts.notoSansKRBold} className={classes.equals}>
                  =
                </Text>
                <InputSmallBorder
                  placeholder={settype === "SILVER" ? "최소1돈" : "최소0.01돈"}
                  value={+(+gram / 3.75).toFixed(4)}
                  number2={true}
                  onChange={(e) => {
                    if (settype === "SILVER") {
                      let nextDon = parseInt(+e);

                      if (nextDon % 2) {
                        nextDon++;
                      }
                      setgraminput(nextDon * 3.75);
                      return;
                    } else {
                      setgraminput((+e).toFixed(1) * 3.75);
                      return;
                    }
                  }}
                  label="중량"
                  labeloption="돈"
                />
              </Flex>
              {settype === "SILVER" ? (
                <Flex row className={classes.buttons}>
                  <DataButton
                    label="+10돈"
                    onClickfunc={donsum}
                    funcdata={10}
                  />
                  <DataButton
                    label="+50돈"
                    onClickfunc={donsum}
                    funcdata={50}
                  />
                  <DataButton
                    label="+100돈"
                    last
                    onClickfunc={donsum}
                    funcdata={100}
                  />
                </Flex>
              ) : (
                <Flex row className={classes.buttons}>
                  <DataButton label="+1돈" onClickfunc={donsum} funcdata={1} />
                  <DataButton label="+5돈" onClickfunc={donsum} funcdata={5} />
                  <DataButton
                    label="+10돈"
                    last
                    onClickfunc={donsum}
                    funcdata={10}
                  />
                </Flex>
              )}
            </Flex>

            <InputSmallBorder
              className={classes.input}
              placeholder="0 원"
              label="총액"
              value={+totalprice + (fee || 0)}
              readonly={true}
              number={true}
            />
          </>
        )}

        <Flex className={classes.buttons2} row>
          <ButtonBase className={classes.init} onClick={handleClickClear}>
            초기화
          </ButtonBase>
          <ButtonBase
            onClick={handleTrade}
            className={classes.trans}
            style={{
              backgroundColor: index === 0 ? colors.textRed : colors.textBlue,
            }}
          >
            {index === 0 ? "매수" : "매도"}
          </ButtonBase>
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  trans: {
    flex: 2,
    padding: "10px",
    fontSize: "14px",
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    borderRadius: "5px",
  },
  init: {
    flex: 1,
    padding: "10px",
    backgroundColor: "#999",
    fontSize: "14px",
    color: "#fff",
    marginRight: "6px",
    fontFamily: fonts.notoSansKRBold,
    borderRadius: "5px",
  },
  buttons2: {
    marginTop: "12px",
    alignItems: "center",
    alignSelf: "stretch",
  },
  content: {
    flex: 1,
    padding: "0px 12px",
    alignItems: "flex-start",
    boxSizing: "border-box",
  },
  root: {
    overflowY: "scroll",
    flex: 3,
    alignSelf: "stretch",
  },
  form: {
    margin: "16px 0px",
  },
  input: {
    marginTop: "20px",
  },
  button: {
    textAlign: "center",
    flex: 1,
    fontSize: "16px",
    color: "#999999",
    fontFamily: fonts.notoSansKRMedium,
    backgroundColor: "#dddddd",
    padding: "14px",
  },
  buttonSel: {
    backgroundColor: colors.white,
    color: colors.textRed,
  },
  buttonSel2: {
    backgroundColor: colors.white,
    color: colors.textBlue,
  },
  message1: {
    fontSize: 14,
    color: colors.gray,
    margin: "20px 0px",
  },
  message1Bold: {
    color: colors.text,
    marginLeft: "16px",
    fontSize: 15,
  },
  radios: {},
  buttons: {
    margin: "4px 0px",
  },
  equals: {
    fontSize: "18px",
    color: "#000",
    alignSelf: "center",
    lineHeight: 1,
    margin: "0px 8px",
  },
  priceInput: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});
