import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";
import {
  formatTime,
  getProductSummaryName,
  phoneFormat,
} from "../../services/utils";

export default function ExchangeDeliveryResult({ appraisalRequestId }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((s) => s.user);
  const [data, setData] = useState({});

  useEffect(() => {
    if (appraisalRequestId) {
      dispatch(
        axiosDispatch(
          {
            method: "get",
            url:
              consts.apiBaseUrl +
              "/api/v1/user/market/appraisal/Requests/" +
              appraisalRequestId,
          },
          (err, res) => {
            if (err) {
              dispatch(messageError(err));
            } else {
              setData({ ...res.data.data });
            }
          }
        )
      );
    } else {
      dispatch(
        messageShow("조회 불가능한 주문입니다.", () => {
          history.replace(routes.appraise);
        })
      );
    }
  }, [appraisalRequestId]);

  return (
    <Flex>
      {data.method === "택배" ? (
        <Text className={classes.title} font={fonts.notoSansKRBold}>
          택배 접수(수거) 예약이
          <br />
          접수 되었습니다.
        </Text>
      ) : data.method === "현장감정" ? (
        <Text className={classes.title} font={fonts.notoSansKRBold}>
          현장 감정이
          <br />
          접수 되었습니다.
        </Text>
      ) : (
        <Text className={classes.title} font={fonts.notoSansKRBold}>
          매장 방문 예약이
          <br />
          접수 되었습니다.
        </Text>
      )}
      {data.method === "현장감정" ? (
        <>
          <Text className={classes.desc} style={{ marginTop: "20px" }}>
            접수자
          </Text>
          <Text className={classes.name} font={fonts.notoSansKRMedium}>
            {data.user?.name}({phoneFormat(data.user?.phone)})
          </Text>

          <Flex className={classes.card + " shadow"}>
            <Flex row className={classes.labelWrap}>
              <img src={images.ed2} />
              <Text font={fonts.notoSansKRMedium}>접수번호 {data.number}</Text>
            </Flex>

            <Flex
              row
              className={classes.labelWrap}
              style={{ alignItems: "flex-start", marginBottom: 10 }}
            >
              <img src={images.ed3} />
              <Text>
                {data.shop?.name}
                <br />
                <Text
                  style={{
                    fontSize: 11,
                    color: colors.gray,
                  }}
                >
                  {data.shop?.address} {data.shop?.addressDetail}
                </Text>
              </Text>
            </Flex>

            <Flex row className={classes.labelWrap}>
              <img src={images.ed4} />
              <Text>
                {formatTime(
                  data.appraisalRequestStatus.filter(
                    (x) => x.status === "접수"
                  )[0]?.createdAt,
                  "YYYY.MM.DD"
                )}
              </Text>
            </Flex>
          </Flex>
        </>
      ) : data.method === "택배" ? (
        <>
          <Text font={fonts.notoSansKRMedium} className={classes.message}>
            <img
              alt="w"
              src={images.danger}
              style={{
                width: 12,
                height: 12,
                marginRight: "6px",
                objectFit: "contain",
              }}
            />
            확인사항
          </Text>
          <Text className={classes.desc} style={{ marginTop: "5px" }}>
            <Text font={fonts.notoSansKRMedium}>
              ㆍ꼭 박스포장을 해주셔야 수거가 가능합니다.
            </Text>
            <br />
            ㆍ박스 포장이 되지 않은 경우 제품 수거가 거절될 수 있습니다.
          </Text>
          <Flex className={classes.card + " shadow"}>
            <Flex row className={classes.labelWrap}>
              <img src={images.ed1} />
              <Text>
                {getProductSummaryName(
                  data.appraisalSimples
                    ?.map((x) => x.appraisalProduct.name)
                    .join(",")
                )}
              </Text>
            </Flex>

            <Flex row className={classes.labelWrap}>
              <img src={images.ed2} />
              <Text font={fonts.notoSansKRMedium}>접수번호 {data.number}</Text>
            </Flex>

            <Flex row className={classes.labelWrap}>
              <img src={images.ed3} />
              <Text>택배 수거 접수</Text>
            </Flex>

            <Flex row className={classes.labelWrap}>
              <img src={images.ed4} />
              <Text>
                {data.collectDate} {data.collectTime}
              </Text>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Text className={classes.desc} style={{ marginTop: "20px" }}>
            접수자
          </Text>
          <Text className={classes.name} font={fonts.notoSansKRMedium}>
            {data.user?.name}({phoneFormat(data.user?.phone)})
          </Text>

          <Text
            font={fonts.notoSansKRMedium}
            style={{ marginTop: 20 }}
            className={classes.message}
          >
            <img
              alt="w"
              src={images.danger}
              style={{
                width: 12,
                height: 12,
                marginRight: "6px",
                objectFit: "contain",
              }}
            />
            확인사항
          </Text>
          <Text className={classes.desc} style={{ marginTop: "5px" }}>
            <Text font={fonts.notoSansKRMedium}>
              ㆍ매장 방문 시 보증서가 있는 경우 제품과 함께 가져 오셔야 합니다.
              <br />
              ㆍ매장 방문시 본인 외에는 접수가 불가합니다.
            </Text>
          </Text>

          <Flex className={classes.card + " shadow"}>
            <Flex row className={classes.labelWrap}>
              <img src={images.ed1} />
              <Text>
                {getProductSummaryName(
                  data.appraisalSimples
                    ?.map((x) => x.appraisalProduct.name)
                    .join(",")
                )}
              </Text>
            </Flex>

            <Flex row className={classes.labelWrap}>
              <img src={images.ed2} />
              <Text font={fonts.notoSansKRMedium}>접수번호 {data.number}</Text>
            </Flex>

            <Flex
              row
              className={classes.labelWrap}
              style={{ alignItems: "flex-start", marginBottom: 10 }}
            >
              <img src={images.ed3} />
              <Text>
                {data.shop?.name}
                <br />
                <Text
                  style={{
                    fontSize: 11,
                    color: colors.gray,
                  }}
                >
                  {data.shop?.address} {data.shop?.addressDetail}
                </Text>
              </Text>
            </Flex>

            <Flex row className={classes.labelWrap}>
              <img src={images.ed4} />
              <Text>
                {data.visitDate} {data.visitTime}
              </Text>
            </Flex>
          </Flex>
        </>
      )}

      {data.method === "택배" ? (
        <Text className={classes.desc}>
          ㆍ등록하신 내용은 관리자가 승인 후 제품수거를 위한 택배접수가
          완료됩니다.
          <br />
          ㆍ등록하신 내용이 부적절하거나 오류가 있을 경우 택배접수가 되지
          않습니다.
          <br />
          ㆍ상세정보에서 실시간 진행 사항을 조회하실 수 있습니다.
        </Text>
      ) : (
        <Text className={classes.desc}>
          ㆍ등록하신 내용은 관리자가 승인 후 감정평가 접수가 완료됩니다.
          <br />
          ㆍ등록하신 내용이 부적절하거나 오류가 있을 경우 감정평가 접수가 되지
          않습니다.
          <br />
          ㆍ상세정보에서 실시간 진행 사항을 조회하실 수 있습니다.
        </Text>
      )}

      <div style={{ height: 100 }} />

      <Button
        onClick={() => {
          history.push(routes.appraise, {
            tab: 1,
          });
        }}
        className="fixed-button"
        label="확인"
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  title: {
    margin: "35px 30px 0px 30px",
    fontSize: "20px",
    color: colors.text,
  },
  name: {
    color: "#333",
    fontSize: 16,
    margin: "0px 30px",
  },
  desc: {
    color: "#666",
    fontSize: "12px",
    margin: "20px 30px 0px 30px",
    "& span": { color: colors.textRed },
  },
  message: {
    color: "#333",
    fontSize: "12px",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    margin: "30px 30px 0px 30px",
  },
  list: {
    paddingLeft: "10px",
    overflowX: "auto",
  },
  label: {
    marginTop: 25,
    fontSize: 16,
    color: "#000",
  },
  label2: {
    fontSize: "14px",
    coloR: colors.text,
    alignSelf: "center",
  },
  cb: {
    margin: "30px 30px 10px 30px",
  },
  input: {
    margin: "0px 30px",
    "&:last-child": {
      marginBottom: "50px",
    },
  },
  card: {
    margin: "10px 30px 10px 30px",
    padding: "5px 18px 15px 18px",
  },
  labelWrap: {
    alignItems: "center",
    marginTop: "10px",
    "& img": {
      marginRight: "6px",
      width: "12px",
      height: "12px",
      objectFit: "contain",
    },
    "& span": {
      lineHeight: 1,
      fontSize: "14px",
      color: colors.text,
    },
  },
});
