import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Notification from "../../components/notification/Notification";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";

export default function Topbar({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <Flex className={classes.root} row>
      <Text font={fonts.notoSansKRMedium} className={classes.label}>
        입출금
      </Text>
      {/*<Notification />*/}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    height: "60px",
    alignItems: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    justifyContent: "space-between",
  },
  label: {
    color: "#333",
    fontSize: "18px",
  },
});
