import {
  ButtonBase,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import More from "../../components/more/More";
import ProductBasicInformation from "../../components/product-basic-information/ProductBasicInformation";
import ProductImages from "../../components/product-images/ProductImages";
import ProductInquiry from "../../components/product-inquiry/ProductInquiry";
import ProductReview from "../../components/product-review/ProductReview";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { actionError } from "../../redux/action/ActionActions";
import { loadingStart, loadingEnd } from "../../redux/loading/LoadingActions";

import Information from "./Information";
import * as APIS from "../../libs/apis";

export default function PhysicalWithdrawalPDetail({ navigate }) {
  const classes = useStyle();
  const history = useHistory();
  const state = history.location.state || {};
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [data, setData] = useState([{}, {}, {}, {}]);
  const [detail, setdetail] = useState();
  const [stock, setstock] = useState(0);
  const [seloption, setseloption] = useState("");
  const [acceptable, setAcceptable] = useState({});

  const authState = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (authState.isSigned) {
      APIS.getUserHoldingsInfo().then(({ data: { data } }) => {
        setAcceptable(data);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getGoodsDetail(state.id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setdetail(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  }, [state.id]);

  const handleClickPost = () => {
    if (!authState.isSigned) {
      dispatch(actionError("로그인 후 이용 가능합니다."));
      return;
    }

    if (stock < 1) {
      dispatch(actionError("수량을 입력해주세요."));
      return;
    }
    if (detail.options === true && detail.optionValue !== "") {
      if (seloption === "") {
        dispatch(actionError("옵션을 선택해주세요."));
        return;
      }
    }

    if (
      detail["goldOrSilver"] === "GOLD" &&
      acceptable.acceptableGold < detail.gram * stock
    ) {
      dispatch(actionError("사용가능 금(GOLD)이 부족합니다."));
      return;
    }

    if (
      detail["goldOrSilver"] === "SILVER" &&
      acceptable.acceptableSilver < detail.gram * stock
    ) {
      dispatch(actionError("사용가능 은(SILVER)이 부족합니다."));
      return;
    }

    if (acceptable.acceptableKrw < detail.fees * stock) {
      dispatch(actionError("사용가능 원화(KRW)가 부족합니다."));
      return;
    }

    navigate(routes.order, {
      type: "physical-withdrawal",
      detail: detail,
      id: state.id,
      sPrice: state.sPrice,
      settype: state.settype,
      stock: stock,
      seloption: seloption,
    });
  };
  return (
    <BasicLayout
      white
      stackTopbar={{
        title: "상품 상세",
        border: true,
      }}
    >
      {tab === 0 && detail && <ProductImages imagesData={detail} />}
      {tab === 0 && detail && (
        <Information
          x={detail}
          sPrice={state.sPrice}
          setstock={setstock}
          stock={stock}
          setseloption={setseloption}
        />
      )}
      <Tabs
        value={tab}
        TabIndicatorProps={{ style: { background: "black" } }}
        style={{
          borderBottom: "1px solid #eee",
        }}
        onChange={(e, v) => {
          //console.log(v);
          setTab(v);
        }}
        indicatorColor="primary"
        textColor={colors.text}
        variant="fullWidth"
        className={classes.tabs}
      >
        <Tab
          // value="appraise"
          color={colors.gray}
          label="상세설명"
        />
        <Tab
          color={colors.gray}
          //value="real"
          label="기본정보"
        />
        {/* <Tab
          color={colors.gray}
          //value="direct"
          label="상품문의"
        />
        <Tab
          color={colors.gray}
          //value="direct"
          label="상품후기"
        /> */}
      </Tabs>
      <Flex className={classes.main}>
        {(() => {
          switch (tab) {
            case 0:
              return (
                <Flex style={{ padding: 13, paddingBottom: 40 }}>
                  {detail?.comment.split("\n").map((comment, k) => {
                    //this.props.data.content: 내용
                    return (
                      <span key={k}>
                        {comment}
                        <br />
                      </span>
                    );
                  })}
                </Flex>
              );
            case 1:
              return <ProductBasicInformation data={detail.goodsInfoDtoList} />;
            case 2:
              return <ProductInquiry />;
            case 3:
              return <ProductReview />;
          }
        })()}

        <div style={{ height: "100px" }} />
      </Flex>
      {tab === 0 && (
        <Button
          onClick={handleClickPost}
          label="인출하기"
          className="fixed-button"
        />
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },
  textButton: {
    paddingRight: "16px",
    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "contain",
    },
  },
  main: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: "20px",
    padding: "10px 16px",
    lineHeight: 1,
  },

  legnth: {
    marginLeft: "10px",
    fontSize: "12px",
    color: colors.text,
  },
  legnth2: {
    marginRight: "10px",
    fontSize: "12px",
    color: colors.text,
  },
});
