import splash from "../assets/images/splash.png";
import test1 from "../assets/images/test1.png";
import logo from "../assets/images/logo.png";
import logohorizontal from "../assets/images/logohorizontal.png";
import close from "../assets/images/close.png";
import naver from "../assets/images/naver.png";
import kakaotalk from "../assets/images/kakaotalk.png";
import facebook from "../assets/images/facebook.png";
import apple from "../assets/images/apple.png";
import notifi from "../assets/images/notifi.png";
import sell from "../assets/images/sell.png";
import buy from "../assets/images/buy.png";
import right from "../assets/images/right.png";
import notice from "../assets/images/notice.png";
import protect2 from "../assets/images/protect2.png";
import test2 from "../assets/images/test2.png";
import test4 from "../assets/images/test4.png";
import knowshops from "../assets/images/knowshops.png";
import simpleinspect from "../assets/images/simpleinspect.png";
import google from "../assets/images/google.png";
import star from "../assets/images/star.png";
import useguide1 from "../assets/images/useguide1.png";
import useguide2 from "../assets/images/useguide2.png";
import useguide3 from "../assets/images/useguide3.png";
import logowhite from "../assets/images/logowhite.png";
import bottom1 from "../assets/images/bottom1.png";
import bottom2 from "../assets/images/bottom2.png";
import bottom3 from "../assets/images/bottom3.png";
import bottom4 from "../assets/images/bottom4.png";
import bottom5 from "../assets/images/bottom5.png";
import notification from "../assets/images/notification.png";
import danger from "../assets/images/danger.png";

import bottom1on from "../assets/images/bottom1on.png";
import bottom2on from "../assets/images/bottom2on.png";
import bottom3on from "../assets/images/bottom3on.png";
import bottom4on from "../assets/images/bottom4on.png";
import bottom5on from "../assets/images/bottom5on.png";
import back from "../assets/images/back.png";
import checkon from "../assets/images/checkon.png";
import checkoff from "../assets/images/checkoff.png";
import checkcircleon from "../assets/images/checkcircleon.png";
import down from "../assets/images/down.png";
import arrowright from "../assets/images/arrowright.png";
import arrowdown from "../assets/images/arrowdown.png";
import location from "../assets/images/location.png";
import call from "../assets/images/call.png";
import home from "../assets/images/home.png";
import arrowrighton from "../assets/images/arrowrighton.png";
import ibk from "../assets/images/ibk.png";
import woori from "../assets/images/woori.png";
import plus from "../assets/images/plus.png";
import minus from "../assets/images/minus.png";
import kb from "../assets/images/kb.png";
import avatar from "../assets/images/avatar.png";
import notifi2 from "../assets/images/notifi2.png";
import rightgray from "../assets/images/rightgray.png";
import star_sel from "../assets/images/star_sel.png";
import camera from "../assets/images/camera.png";
import calendar from "../assets/images/calendar.png";
import up from "../assets/images/up.png";
import closewhite from "../assets/images/closewhite.png";
import trash from "../assets/images/trash.png";
import checkcircle_out from "../assets/images/checkcircle_out.png";
import appraise1 from "../assets/images/appraise1.png";
import appraise2 from "../assets/images/appraise2.png";
import appraise3 from "../assets/images/appraise3.png";
import purityimage from "../assets/images/purityimage.png";
import appraisepicguide from "../assets/images/appraisepicguide.png";
import appraise4 from "../assets/images/appraise4.png";
import sample1 from "../assets/images/sample1.png";
import ed1 from "../assets/images/ed1.png";
import dt1 from "../assets/images/dt1.png";
import dt2 from "../assets/images/dt2.png";
import dt3 from "../assets/images/dt3.png";
import dt4 from "../assets/images/dt4.png";
import dt5 from "../assets/images/dt5.png";
import dt6 from "../assets/images/dt6.png";
import dt7 from "../assets/images/dt7.png";
import ed2 from "../assets/images/ed2.png";
import ed3 from "../assets/images/ed3.png";
import ed4 from "../assets/images/ed4.png";
import search from "../assets/images/search.png";
import menuun from "../assets/images/menuun.png";
import goldbar from "../assets/images/goldbar.png";
import grid from "../assets/images/grid.png";
import more from "../assets/images/more.png";
import updown from "../assets/images/updown.png";
import security from "../assets/images/security.png";
import protect from "../assets/images/protect.png";
import simpleinspect2 from "../assets/images/simpleinspect2.png";
import sample2 from "../assets/images/sample2.png";

import mt1 from "../assets/images/mt1.png";
import mt2 from "../assets/images/mt2.png";
import mt3 from "../assets/images/mt3.png";
import mt4 from "../assets/images/mt4.png";
import mt5 from "../assets/images/mt5.png";
import mt6 from "../assets/images/mt6.png";

import mainBanner from "../assets/images/main/mainBanner.png";
import findShop from "../assets/images/main/findShop.png";
import callShopInfo from "../assets/images/main/callShopInfo.png";

export default {
  protect2,
  security,
  sample2,
  dt1,
  dt2,
  mt1,
  mt2,
  mt3,
  mt4,
  mt5,
  mt6,
  protect,
  dt3,
  dt4,
  dt5,
  dt6,
  dt7,
  simpleinspect2,
  goldbar,
  grid,
  more,
  updown,
  home,
  google,
  menuun,
  ed1,
  ed2,
  ed3,
  ed4,
  appraise4,
  down,
  appraise1,
  appraise2,
  appraise3,
  checkcircle_out,
  search,
  up,
  purityimage,
  trash,
  camera,
  danger,
  call,
  sample1,
  location,
  calendar,
  appraisepicguide,
  closewhite,
  notifi2,
  ibk,
  rightgray,
  plus,
  arrowdown,
  avatar,
  minus,
  woori,
  kb,
  splash,
  bottom1,
  checkoff,
  checkon,
  checkcircleon,
  arrowright,
  arrowrighton,
  bottom2,
  bottom3,
  bottom4,
  back,
  bottom5,
  bottom1on,
  bottom2on,
  bottom3on,
  bottom4on,
  bottom5on,
  test4,
  star_sel,
  star,
  useguide1,
  notice,
  useguide2,
  notification,
  logowhite,
  useguide3,
  right,
  sell,
  simpleinspect,
  buy,
  apple,
  close,
  knowshops,
  test1,
  test2,
  logo,
  naver,
  kakaotalk,
  facebook,
  notifi,
  logohorizontal,
  mainBanner,
  findShop,
  callShopInfo,
};
