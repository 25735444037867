import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import Flex from "../../../components/flex/Flex";
import Text from "../../../components/text/Text";
import colors from "../../../libs/colors";

export default function Radio({ data, value, onChange }) {
  const classes = useStyle();
  return (
    <Flex row className={classes.root}>
      {data.map((x, i) => {
        return (
          <ButtonBase
            onClick={() => {
              onChange(x.value);
            }}
            key={i.toString()}
          >
            <Flex row className={classes.item}>
              <span
                className={
                  classes.radio + " " + (x.value === value && classes.radioSel)
                }
              >
                <span className={classes.radioCenter} />
              </span>
              <Text className={classes.label}>{x.label}</Text>
            </Flex>
          </ButtonBase>
        );
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    alignSelf: "flex-start",
    alignItems: "center",
  },
  label: {
    fontSize: 14,
    marginLeft: "8px",
    color: colors.text,
    lineHeight: 1,
  },
  item: {
    alignItems: "center",
    marginRight: "25px",
  },
  radioSel: {
    backgroundColor: colors.black + " !important",
  },
  radio: {
    width: 14,
    height: 14,
    borderRadius: 100,
    backgroundColor: "#dddddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioCenter: {
    width: 5,
    height: 5,
    borderRadius: 100,
    backgroundColor: colors.white,
    zIndex: 2,
  },
});
