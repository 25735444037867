import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { getRequest } from "../../network";
import { actionShow } from "../../redux/action/ActionActions";
import { messageError } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function AppraiseRegist1({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const [data, setData] = useState([]);
  const user = useSelector((s) => s.user);
  const [data2, setData2] = useState([]);

  useEffect(() => {
    if (state.onlyGold) {
      dispatch(toastShow("금 제품만 추가감정할 수 있습니다."));
    } else if (state.onlySilver) {
      dispatch(toastShow("은 제품만 추가감정할 수 있습니다."));
    }
  }, [state]);

  useEffect(() => {
    const selectedId = history.location.state?.appraisalItem?.id;

    dispatch(loadingStart);

    const getGoldProducts = APIS.getAppraisalProducts(
      objToQueryStr({ type: "GOLD" })
    )
      .then(({ data: { data } }) => {
        setData(
          data.map((x) => {
            if (selectedId === x.id) {
              x.selected = true;
            }

            return x;
          })
        );
      })
      .catch((err) => dispatch(messageError(err)));

    const getSilverProducts = APIS.getAppraisalProducts(
      objToQueryStr({ type: "SILVER" })
    )
      .then(({ data: { data } }) => {
        setData2(
          data.map((x) => {
            if (selectedId === x.id) {
              x.selected = true;
            }

            return x;
          })
        );
      })
      .catch((err) => dispatch(messageError(err)));

    Promise.all([getGoldProducts, getSilverProducts]).finally(() =>
      dispatch(loadingEnd)
    );
  }, []);

  return (
    <Flex>
      <Text className={classes.message}>
        <Text font={fonts.notoSansKRBold}>
          어떤 제품을
          <br />
          감정하실 건가요?
        </Text>
        <br />
        1개의 제품만 선택해 주세요.
      </Text>
      <Flex row className={classes.list}>
        <div className={classes.gold}>금</div>
        {data.map((x, i) => {
          return (
            <Flex
              onClick={
                state.onlySilver
                  ? null
                  : () => {
                      setData2([
                        ...data2.map((x) => ({ ...x, selected: false })),
                      ]);
                      setData([
                        ...data.map((z, y) => {
                          if (y === i) {
                            if (z.selected) {
                              z.selected = false;
                            } else {
                              z.selected = true;
                            }
                          } else {
                            z.selected = false;
                          }
                          return z;
                        }),
                      ]);
                    }
              }
              key={i.toString()}
              style={{
                border: x.selected ? "3px solid " + colors.primary : "",
              }}
              className={classes.item + " shadow"}
            >
              <img className={classes.image} src={x.imageUrl} />
              <Text font={fonts.notoSansKRMedium} className={classes.label}>
                {x.name}
              </Text>
              {state.onlySilver && <div className={classes.itemHide} />}
            </Flex>
          );
        })}
      </Flex>
      <Flex row className={classes.list}>
        <div className={classes.silver}>은</div>
        {data2.map((x, i) => {
          return (
            <Flex
              onClick={
                state.onlyGold
                  ? null
                  : () => {
                      setData([
                        ...data.map((x) => ({ ...x, selected: false })),
                      ]);
                      setData2([
                        ...data2.map((z, y) => {
                          if (y === i) {
                            if (z.selected) {
                              z.selected = false;
                            } else {
                              z.selected = true;
                            }
                          } else {
                            z.selected = false;
                          }
                          return z;
                        }),
                      ]);
                    }
              }
              key={i.toString()}
              style={{
                border: x.selected ? "3px solid " + colors.primary : "",
              }}
              className={classes.item + " shadow"}
            >
              <img className={classes.image} src={x.imageUrl} />
              <Text font={fonts.notoSansKRMedium} className={classes.label}>
                {x.name}
              </Text>
              {state.onlyGold && <div className={classes.itemHide} />}
            </Flex>
          );
        })}
      </Flex>
      {[...data, ...data2].filter((x) => x.selected).length ? (
        <Text className={classes.label2}>
          <Text
            className={classes.label2}
            style={{ color: colors.textBlue }}
            font={fonts.notoSansKRMedium}
          >
            {[...data, ...data2].filter((x) => x.selected)[0].type === "GOLD"
              ? "금"
              : "은"}{" "}
            {">"} {[...data, ...data2].filter((x) => x.selected)[0].name}
          </Text>
          를 감정하시겠습니까?
        </Text>
      ) : (
        ""
      )}
      <Button
        onClick={() => {
          if (!user.id) {
            dispatch(
              actionShow(
                "로그인 하신후 이용 가능합니다",
                ["닫기", "로그인"],
                [
                  null,
                  () => {
                    history.push(routes.login);
                  },
                ]
              )
            );
            return;
          }
          history.replace(routes.appraiseRegist, {
            step: 1,
            appraisalItem: {
              ...[...data, ...data2].filter((x) => x.selected)[0],
            },
          });
          history.push(routes.appraiseRegist, {
            ...state,
            appraisalItem: {
              ...[...data, ...data2].filter((x) => x.selected)[0],
            },
            step: 2,
          });
        }}
        disabled={![...data, ...data2].filter((x) => x.selected).length}
        label="다음"
        className={classes.button}
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  message: {
    margin: "35px 30px 30px 30px",
    color: "#666",
    fontSize: "14px",
    "& span": {
      fontSize: "20px",
      color: colors.text,
    },
  },
  list: {
    overflowX: "auto",
    marginBottom: "20px",
    width: "100vw",
    maxWidth: "100vw",
  },
  item: {
    //  height: 120,
    minWidth: "25%",
    width: "25%",
    alignSelf: "stretch",
    padding: "20px",
    position: "relative",
    borderRadius: "5px",
    marginLeft: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: 3,
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  silver: {
    width: 40,
    minWidth: 40,
    alignSelf: "stretch",
    backgroundColor: "#c0c0c0",
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 3,
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    display: "flex",
    height: 160,
    color: "#000",
    fontSize: 16,
  },
  gold: {
    marginBottom: 3,
    width: 40,
    minWidth: 40,
    height: 160,
    backgroundColor: "#ffd700",
    fontFamily: fonts.notoSansKRBold,
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    display: "flex",
    color: "#000",
    fontSize: 16,
  },
  button: {
    zIndex: 2,
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    borderRadius: "0px",
    width: "100%",
    alignSelf: "stretch",
  },
  image: {
    backgroundColor: "#ddd",
    width: 70,
    objectFit: "cover",
    height: 70,
    borderRadius: "100px",
  },
  label: {
    marginTop: 25,
    fontSize: 16,
    color: "#000",
  },
  label2: {
    fontSize: "14px",
    marginBottom: 100,
    coloR: colors.text,
    alignSelf: "center",
  },
  itemHide: {
    position: "absolute",
    borderRadius: 5,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: "rgba(0,0,0,0.1)",
  },
});
