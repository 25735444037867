import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ic_close from "../../assets/images/ic_close.png";
import ic_check_white from "../../assets/images/ic_check_white.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { numberWithCommas } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { updateSafeTradeOrder } from "../../redux/safe-trade/safeTradeOrder";

const ORDER_STATUS = ["새상품", "매우 좋음", "좋음", "보통"];
export default function SafeTradeStep1({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const {
    category,
    category_detail,
    name,
    out_date,
    buy_price,
    place,
    buy_date,
    status,
    price,
  } = useSelector((state) => state.safeTradeOrder);

  const originCategory = useRef(category);

  const [modalState, setModalState] = useState({
    title: "",
    visible: false,
    data: [],
    onChange: () => {},
    value: "",
  });

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getSafeTradeCategory()
      .then(({ data: { success, data } }) => {
        success && setCategories(data.sort((a, b) => a.sort - b.sort));
      })
      .finally(() => dispatch(loadingEnd));
  }, []);

  useEffect(() => {
    if (originCategory.current !== category) {
      setBrands([]);
      updateOrder({ category_detail: "" });
    }
    if (!category) {
      return false;
    }

    dispatch(loadingStart);
    APIS.getSafeTradeBrand(category)
      .then(({ data: { success, data } }) => {
        success && setBrands(data.sort((a, b) => a.sort - b.sort));
      })
      .finally(() => dispatch(loadingEnd));
  }, [category]);

  const closeModal = () => {
    setModalState({
      title: "",
      visible: false,
      data: [],
      onChange: () => {},
      value: "",
    });
  };

  const updateOrder = (order) => {
    dispatch(updateSafeTradeOrder(order));
  };

  const handleClickCategory = () => {
    setModalState({
      title: "카테고리",
      visible: true,
      data: categories,
      onChange: (category) => updateOrder({ category }),
      value: category,
    });
  };

  const handleClickBrand = () => {
    if (!category) {
      return false;
    }
    setModalState({
      title: "브랜드",
      visible: true,
      data: brands.map((brand) => ({ ...brand, name: brand.brand })),
      onChange: (category_detail) => updateOrder({ category_detail }),
      value: category_detail,
    });
  };

  const handleClickNext = () => {
    if (category && category_detail && name && price) {
      history.push(routes.safeTradeStep2);
    }
  };

  const viewField = {
    category: category
      ? categories.find(({ id }) => id === category)?.name
      : "선택",
    category_detail: category_detail
      ? brands.find(({ id }) => id === category_detail)?.brand
      : "선택",
  };
  return (
    <BasicLayout
      stackTopbar={{
        title: "1/3",
        option: (
          <ButtonBase onClick={() => history.replace(routes.main)}>
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}
    >
      <div className="progressbar">
        <div className="per33"></div>
      </div>
      <div className="trade_wrap">
        <div className="pd_sec">
          <h3 className="pd_tit">제품의 정보를 입력해 주세요.</h3>

          <div className="pd_sec_checkbox">
            <div className={`pd_box ${category && "success"}`}>
              <div className="pd_box_tit">
                제품 카테고리 <span className="org">*필수</span>
              </div>
              <div className="pd_select_box" onClick={handleClickCategory}>
                {viewField.category}
              </div>
            </div>

            <div className={`pd_box ${category_detail && "success"}`}>
              <div className="pd_box_tit">
                브랜드 (제조사) <span className="org">*필수</span>
              </div>
              <div className="pd_select_box" onClick={handleClickBrand}>
                {viewField.category_detail}
              </div>
            </div>

            <div className={`pd_box ${name && "success"}`}>
              <div className="pd_box_tit">
                제품명 (모델명) <span className="org">*필수</span>
              </div>
              <div className="pd_box_text">
                <input
                  type="text"
                  placeholder="제품명(모델명)을 입력해주세요."
                  value={name}
                  onChange={(e) => updateOrder({ name: e.target.value })}
                />
                <div
                  className="clear"
                  onClick={() => updateOrder({ name: "" })}
                >
                  <img src={ic_close} alt="X" />
                </div>
              </div>
            </div>

            <div className="pd_box">
              <div className="pd_box_tit">제조, 출시년도</div>
              <div
                className="pd_select_box cal spoqa"
                style={{ position: "relative" }}
              >
                {out_date}
                <input
                  type="date"
                  className={classes.inputDate}
                  value={out_date}
                  onChange={(e) => updateOrder({ out_date: e.target.value })}
                />
              </div>
            </div>

            <div className="pd_box">
              <div className="pd_box_tit">구매 가격</div>
              <div className="pd_select_box won">
                <input
                  type="text"
                  placeholder="구매하신 금액을 입력해주세요."
                  value={numberWithCommas(buy_price)}
                  onChange={(e) => {
                    let values = e.target.value;
                    values = values.replace(/,/gi, "");
                    var regexp = /^[0-9]*$/;
                    if (!regexp.test(values)) {
                      return;
                    } else {
                      updateOrder({ buy_price: values });
                    }
                  }}
                  inputMode="decimal"
                />
              </div>
            </div>

            <div className="pd_box">
              <div className="pd_box_tit">구매처</div>
              <div className="pd_box_text">
                <input
                  type="text"
                  placeholder="구매하신 곳을 입력해주세요."
                  value={place}
                  onChange={(e) => updateOrder({ place: e.target.value })}
                />
                <div
                  className="clear"
                  onClick={() => updateOrder({ place: "" })}
                >
                  <img src={ic_close} alt="X" />
                </div>
              </div>
            </div>
            <div className="pd_box">
              <div className="pd_box_tit">구입년도</div>
              <div
                className="pd_select_box cal spoqa"
                style={{ position: "relative" }}
              >
                {buy_date}
                <input
                  type="date"
                  className={classes.inputDate}
                  value={buy_date}
                  onChange={(e) => updateOrder({ buy_date: e.target.value })}
                />
              </div>
            </div>
            <div className="pd_box">
              <div className="pd_box_tit">제품 상태</div>
              <div>
                <ul>
                  {ORDER_STATUS.map((option, i) => (
                    <li
                      key={i.toString()}
                      className={`${option === status && "on"}`}
                      onClick={() => updateOrder({ status: option })}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={`pd_box ${price && "success"}`}>
              <div className="pd_box_tit">
                판매 희망 가격 <span className="org">*필수</span>
              </div>
              <div className="pd_select_box won">
                <input
                  type="text"
                  placeholder="판매 희망 금액을 입력해주세요."
                  value={numberWithCommas(price)}
                  onChange={(e) => {
                    let values = e.target.value;
                    values = values.replace(/,/gi, "");
                    var regexp = /^[0-9]*$/;
                    if (!regexp.test(values)) {
                      return;
                    } else {
                      updateOrder({ price: values });
                    }
                  }}
                  inputMode="decimal"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`ok_btn ${
            category && category_detail && name && price && "on"
          }`}
          onClick={handleClickNext}
        >
          다음
        </div>
      </div>
      <SelectModal {...modalState} onClose={closeModal} />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  inputDate: {
    opacity: 0,
    position: "absolute !important",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100% !important",
    zIndex: 1,
  },
});

const SelectModal = ({
  title = "",
  visible = false,
  data = [],
  onChange = () => {},
  onClose,
  value = "",
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value, visible]);

  return (
    <div
      className="trade_wrap_bk"
      style={{
        display: visible ? "block" : "none",
        position: "fixed",
        top: 0,
        zIndex: 10000,
      }}
    >
      <div className="pd_select">
        <div className="select_tit">
          <h3>{title} 선택</h3>
          <img src={ic_close} alt="X" onClick={onClose} />
        </div>
        <div className="select_list">
          <ul>
            {data.map(({ name, id }, i) => (
              <li
                className={`${!!selected && (id === selected ? "on" : "off")}`}
                key={i.toString()}
                onClick={() => setSelected(id)}
              >
                <div className="ck_cir">
                  <img src={ic_check_white} alt="" />
                </div>
                {name}
              </li>
            ))}
          </ul>
        </div>
        <div
          className={`ok_btn ${selected && "on"}`}
          onClick={() => {
            if (selected) {
              onChange(selected);
              onClose();
            }
          }}
        >
          확인
        </div>
      </div>
    </div>
  );
};
