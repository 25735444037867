import { makeStyles, InputBase, CardMedia, Grid } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import fonts from "../../libs/fonts";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import images from "../../libs/images";
import { actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import consts from "../../libs/consts";
import moment from "moment";
import StackTopbar from "../../layouts/basic/StackTopbar";
import routes from "../../libs/routes";
import { ContactsOutlined } from "@material-ui/icons";

export default function Inquiry({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation({});
  const { state = {} } = location;
  const history = useHistory();
  const [pastConversation, setPastConversation] = useState([]);
  const [message, setMessage] = useState("");
  const [qnaImage, setQnaImage] = useState("");
  const messagesEndRef = useRef(null);
  const inputRef = useRef();
  const testRef = useRef(null);
  const originHeight = useRef(0);
  const [textAreaHeight, setTextAreaHeight] = useState(32);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const validationData = () => {
    if (!state.content || state.content === "" || message === "") {
      return "내용을 입력하여 주세요";
    }
    return "";
  };

  const reformData = (data) => {
    const itemData = [...data] || [];
    const changePastConversation = itemData.map((item, key) => {
      return { ...item, day: item?.["createdAt"].split(" ")[0] };
    });
    const dayList = changePastConversation.map((item, key) => {
      return { day: item?.day };
    });

    const deduplicationDay = dayList.filter((dayName, idx, arr) => {
      return arr.findIndex((item) => item.day === dayName.day) === idx;
    });

    let parseData = {};
    let arrayData = [];

    deduplicationDay.forEach((day) => {
      // day 날짜 필터링
      changePastConversation.forEach((item) => {
        if (item.day === day.day) {
          arrayData.push(item);
          parseData[day.day] = [...arrayData];
        }
      });
      arrayData = [];
    });
    return parseData;
  };

  const handleClickRegister = () => {
    if (!state.image) {
      const errorMessage = validationData();
      if (errorMessage) {
        dispatch(actionOpen(errorMessage, null, null, null, true));
        return false;
      }
    }
    const json = JSON.stringify({
      ...state,
      content: message,
    });

    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("qna", blob);
    formData.append(
      "image",
      Object.keys(qnaImage).length !== 0 ? qnaImage : ""
    );

    dispatch(loadingStart);
    APIS.postQnaRegister(formData)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen("문의 접수에 실패하였습니다", null, null, null, true)
          );
        } else {
          setMessage("");
          setQnaImage("");
          setTextAreaHeight(32);
          data.unshift({
            content: "궁금하신 사항을 입력해 주세요",
            contentType: "TEXT",
            createdAt: "",
            id: "",
            managerName: "MANAGER",
            qnaWriter: "MANAGER",
            userId: null,
            userName: null,
            userSocialId: null,
          });
          setPastConversation(reformData(data));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };
  const onChangeImage = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    if (file) {
      const fs = new FileReader();
      fs.onloadend = () => {
        file.uri = fs.result;
        if (name === "qnaImage") {
          setQnaImage(file);
          setTextAreaHeight(160);
          handleValueChange("content", null);
          handleValueChange("image", file);
        }
      };
      fs.readAsDataURL(file);
    }
    e.target.value = "";
  };

  useEffect(() => {
    handleValueChange("content", "");
    handleValueChange("image", "");

    fetchItem();
  }, []);

  const fetchItem = () => {
    dispatch(loadingStart);
    APIS.getQnaList()
      .then(({ data: { success, data } }) => {
        if (success) {
          data.unshift({
            content: "궁금하신 사항을 입력해 주세요",
            contentType: "TEXT",
            createdAt: "",
            id: "",
            managerName: "MANAGER",
            qnaWriter: "MANAGER",
            userId: null,
            userName: null,
            userSocialId: null,
          });
          setPastConversation(reformData(data));
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [pastConversation]);

  useEffect(() => {
    !contentHeight && setContentHeight(contentRef.current.offsetHeight);
  }, []);

  useEffect(() => {
    const parseValue = textAreaHeight > 70 ? 0 : 2;

    const nextScrollTop =
      textAreaHeight > originHeight.current
        ? testRef.current?.scrollTop +
          textAreaHeight -
          originHeight.current +
          parseValue
        : testRef.current?.scrollTop +
          originHeight.current -
          textAreaHeight +
          parseValue;

    document.getElementById("chatList")?.scrollTo(0, nextScrollTop);

    originHeight.current = textAreaHeight;
  }, [textAreaHeight]);

  return (
    <BasicLayout>
      <StackTopbar
        title="1:1 문의하기"
        border
        onGoBack={() => {
          history.replace(routes.menu);
        }}
        style={{ position: "fixed", width: "100%", zIndex: 10000 }}
      />
      {contentHeight ? (
        <Flex id="test" style={{ height: contentHeight, overflow: "hidden" }}>
          <Flex
            style={{
              overflowY: "scroll",
              backgroundColor: "rgb(239,243,247)",
              height: "100%",
              marginTop: textAreaHeight > 60 ? 60 : 60,
              marginBottom:
                qnaImage !== ""
                  ? textAreaHeight > 0
                    ? 160
                    : 40
                  : textAreaHeight > 60
                  ? 60
                  : 40,
              padding: "0px 20px 14px 20px",
            }}
            innerRef={testRef}
            id="chatList"
            onLoad={() => {
              scrollToBottom();
            }}
          >
            {Object.keys(pastConversation).length ? (
              Object.keys(pastConversation)?.map((day) => {
                if (day !== "") {
                  return (
                    <>
                      <Text className={classes.day}>{day}</Text>
                      {pastConversation[day]?.map((item, index) => {
                        const defaultProps = {
                          ...item,
                          userType: item.qnAWriter === "USER" ? "user" : "manager",
                          messageType:
                            item.contentType === "IMAGE" ? "image" : "text",
                        };
                        return <ChatItem {...defaultProps} key={index} />;
                      })}
                    </>
                  );
                } else {
                  const defaultProps = {
                    userType: "manager",
                    messageType: "text",
                    content: "궁금하신 사항을 말씀해 주세요.",
                  };
                  return <ChatItem {...defaultProps} />;
                }
              })
            ) : (
              <Text style={{ marginTop: 720, flex: 1, alignItems: "center" }} />
            )}
            <div ref={messagesEndRef} />
          </Flex>

          <Flex className={classes.inputform} row>
            <input
              accept="image/*"
              type="file"
              id="rasied-image-file"
              name="qnaImage"
              style={{ display: "none" }}
              onChange={onChangeImage}
              onClick={onInputClick}
            />
            <label htmlFor="rasied-image-file" className={classes.imageLabel}>
              <img src={images.plus} className={classes.imageButton} />
            </label>
            {qnaImage ? (
              <div
                className={classes.df}
                style={{
                  flexDirection: "column",
                  height: textAreaHeight,
                  paddingTop: 5,
                }}
              >
                <div className={classes.inputImage}>
                  <CardMedia
                    component="img"
                    image={
                      qnaImage.path
                        ? consts.s3BaseUrl + "/" + qnaImage.path
                        : qnaImage.uri
                    }
                    width="100"
                    style={{ borderRadius: 10 }}
                  />
                </div>
                <Grid className={classes.dfcs}>
                  <Button
                    className={classes.buttonDelete}
                    onClick={() => {
                      setTextAreaHeight(0);
                      setQnaImage("");
                      handleValueChange("image", undefined);
                    }}
                    size="small"
                    label="삭제"
                  ></Button>
                </Grid>
              </div>
            ) : (
              <InputBase
                multiline
                ref={inputRef}
                style={{
                  flex: 1,
                  border: "1px solid #f5f5f5",
                }}
                inputProps={{
                  style: {
                    maxHeight: "55px",
                  },
                }}
                value={message}
                onChange={(e) => {
                  e.target.style.height = "auto";
                  const scrollHeight = e.target.scrollHeight;

                  let inputHeight = 68;
                  if (e.target.scrollHeight <= inputHeight) {
                    inputHeight = e.target.scrollHeight + 13;
                  }
                  setTextAreaHeight(inputHeight);

                  e.target.style.height = scrollHeight + "px";

                  setMessage(e.target.value);
                  handleValueChange("content", e.target.value);
                }}
              />
            )}
            <Button
              onClick={handleClickRegister}
              className={
                qnaImage ? classes.buttonRegisterImage : classes.button
              }
              label="등록"
            ></Button>
          </Flex>
        </Flex>
      ) : (
        <Flex innerRef={contentRef} style={{ flex: 1 }} />
      )}
    </BasicLayout>
  );
}

const ChatItem = (props) => {
  const classes = useStyle();

  const formatDate = (date) => {
    const time = moment(date).format("HH:mm");
    const getTime = time.substring(0, 2);
    const intTime = parseInt(getTime);
    let str = "";
    let cvHour = 0;
    if (intTime < 12) {
      str = "오전";
    } else {
      str = "오후";
    }
    if (intTime === 12) {
      cvHour = intTime;
    } else {
      cvHour = intTime % 12;
    }
    const res = str + ("0" + cvHour).slice(-2) + time.slice(-3);
    return res;
  };

  return (
    <>
      {props.userType === "manager" ? (
        <Text
          className={props.userType === "manager" ? classes.managerName : ""}
        >
          {"금방금방"}
        </Text>
      ) : (
        ""
      )}

      <Flex
        className={
          props.userType === "user"
            ? classes.userMessage
            : classes.managerMessage
        }
      >
        {props.createdAt ? (
          <div key={props.key}>{formatDate(props.createdAt)}</div>
        ) : null}

        {props.messageType === "image" ? (
          <div className={classes.df}>
            <div className={classes.dfcc}>
              <CardMedia
                component="img"
                key={props.key}
                image={props.content ? consts.s3BaseUrl + props.content : ""}
                width="100"
                style={{ borderRadius: 10 }}
              />
            </div>
          </div>
        ) : (
          <Text
            className={
              props.userType === "user"
                ? classes.userChatContent
                : classes.managerChatContent
            }
          >
            {props.content?.split("").map((x, i) => {
              if (x === "\n") {
                return <br key={i.toString()} />;
              } else {
                return x;
              }
            })}
          </Text>
        )}
      </Flex>
    </>
  );
};

const useStyle = makeStyles({
  buttonDelete: {
    height: 26,
    width: 70,
    color: "#fff",
    backgroundColor: "#000",
  },
  buttonRegisterImage: {
    fontFamily: fonts.notoSansKRBold,
    padding: "10px 30px",
    marginLeft: "auto",
    backgroundColor: "black",
  },
  button: {
    fontFamily: fonts.notoSansKRBold,
    padding: "10px 30px",
    backgroundColor: "black",
    borderRadius: "0px",
  },
  managerName: {
    marginTop: 20,
    textAlign: "left",
  },
  userChatContent: {
    fontSize: 15,
    color: "#fff",
    "word-break": "break-all",
    maxWidth: "67%",
    backgroundColor: "#0078fe",
    padding: 10,
    width: "fit-content",
    borderRadius: 20,
  },
  userName: {
    marginTop: 20,
    textAlign: "right",
  },

  managerChatContent: {
    fontSize: 15,
    color: "#000",
    justifyContent: "center",
    "word-break": "break-all",
    maxWidth: "67%",
    backgroundColor: "#dedede",
    padding: 10,
    width: "fit-content",
    borderRadius: 20,
  },
  imageLabel: {
    alignSelf: "center",
    width: 40,
    textAlignLast: "center",
  },
  imageButton: {
    width: 24,
    height: 24,
    border: "2px solid #ada4a4",
    borderRadius: 5,
    padding: 3,
  },
  scroll: {
    backgroundColor: "rgb(239,243,247)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    width: "100%",
    padding: "30px 20px 25px 20px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": { width: 10, display: "none" },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#2f3542",
      borderRadius: 10,
      backgroundClip: "padding-box",
      border: "2px solid transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "grey",
      borderRadius: 10,
      boxShadow: "inset 0px 0px 5px white",
    },
  },
  inputform: {
    borderTop: "0.7px solid #dddddd",
    backgroundColor: "#fff",
    position: "fixed",
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: "stretch",
    flex: 1,
    flexDirection: "revert",
    backgroundColor: "white",
    maxHeight: 160,
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    alignItems: "center",
    width: "150px",
  },
  inputImage: {
    display: "flex",
    alignItems: "center",
    width: 140,
    height: 124,
    alignSelf: "center",
  },
  dfcs: {
    alignSelf: "center",
  },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  userMessage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: 10,
  },
  managerMessage: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  day: {
    fontWeight: "bold",
    alignSelf: "center",
    backgroundColor: "#9eaec1",
    padding: 10,
    marginTop: 20,
    textAlign: "center",
    width: "fit-content",
    borderRadius: 20,
  },
});
