import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import camera from "../../assets/images/camera.png";
import ex2 from "../../assets/images/ex2.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { encodeFileToBase64 } from "../../libs/utils";
import { updateSafeTradeOrder } from "../../redux/safe-trade/safeTradeOrder";

const POSITIONS = ["front", "side", "back", "model"];
export default function SafeTradeStep2({}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { front, side, back, model } = useSelector(
    (state) => state.safeTradeOrder
  );

  const inputRef = useRef(null);

  const [src, setSrc] = useState({
    front: null,
    side: null,
    back: null,
    model: null,
  });

  const fileToSrc = async (key, file) => {
    if (file) {
      const imageSrc = await encodeFileToBase64(file);
      setSrc((src) => {
        return { ...src, [key]: imageSrc };
      });
    } else {
      setSrc((src) => {
        return { ...src, [key]: null };
      });
    }
  };

  useEffect(() => {
    fileToSrc("front", front);
  }, [front]);
  useEffect(() => {
    fileToSrc("side", side);
  }, [side]);
  useEffect(() => {
    fileToSrc("back", back);
  }, [back]);
  useEffect(() => {
    fileToSrc("model", model);
  }, [model]);

  const updateOrder = (order) => dispatch(updateSafeTradeOrder(order));

  const handleClickImage = (key) => () => {
    updateOrder({ [key]: null });
    inputRef.current.index = key;
    inputRef.current?.click();
  };

  const handleChangeFile = async (e) => {
    const file = e.target.files[0];

    if (file) {
      updateOrder({ [inputRef.current.index]: file });
    }

    e.target.value = "";
  };

  const handleClickNext = () => {
    if (front && side && back && model) {
      history.push(routes.safeTradeStep3);
    }
  };
  return (
    <BasicLayout
      stackTopbar={{
        title: "2/3",
        option: (
          <ButtonBase onClick={() => history.replace(routes.main)}>
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}
    >
      <div className="progressbar">
        <div className="per66"></div>
      </div>
      <div className="trade_wrap">
        <div className="pd_thd">
          <h3 className="pd_tit">제품의 사진을 등록해 주세요.</h3>

          <div className="pd_pic">
            <ul>
              {POSITIONS.map((key) => (
                <li key={key} onClick={handleClickImage(key)}>
                  {src[key] ? (
                    <img
                      src={src[key]}
                      alt="제품이미지"
                      className="pic_product"
                    />
                  ) : (
                    <img src={camera} alt="" className="pic_ic" />
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="pd_pic_guide">
            <div className="pic_guide_tit">제품 사진 촬영 가이드</div>
            <ul className="pic_guide_cont">
              <li>
                ㆍ흰 배경에서 제품 전체가 선명하게 나오도록 촬영해 주세요.
              </li>
              <li>ㆍ구성품은 별도로 촬영하시고, 제품 자체만 촬영해 주세요.</li>
              <li>
                ㆍ제품의 상태를 파악할 수 있게 다양한 각도록 촬영해 주세요.
              </li>
              <li>ㆍ모델명이 있는 경우 모델명이 나오도록 촬영해 주세요.</li>
            </ul>
            <div className="pd_pic_ex">
              <img src={ex2} />
            </div>
          </div>
        </div>
        <div
          className={`ok_btn ${front && side && back && model && "on"}`}
          onClick={handleClickNext}
        >
          다음
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleChangeFile}
        ref={inputRef}
        style={{ display: "none" }}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({});
