import { InputBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function InputSmallBorder({
  label,
  placeholder,
  className,
  value,
  onChange,
  readonly,
  number,
  labeloption,
  number2,
}) {
  const classes = useStyle();

  var valuedata = value;
  Number.prototype.format = function () {
    if (this == 0) return 0;

    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = this + "";

    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

    return n;
  };

  // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
  String.prototype.format = function () {
    var num = parseFloat(this);
    if (isNaN(num)) return "";

    return num.format();
  };

  if (number) {
    if (value) {
      valuedata = valuedata * 1;
      valuedata = valuedata.format();
    }
  }
  return (
    <Flex row className={classes.root + " " + className}>
      {label && (
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          {label}
        </Text>
      )}

      <InputBase
        onChange={(e) => {
          if (onChange) {
            var values = e.target.value;
            if (number) {
              values = values.replace(/,/gi, "");
              var regexp = /^[0-9]*$/;
              if (!regexp.test(values)) {
                return;
              } else {
                values = values.replace(/,/gi, "");
              }
            }
            onChange(values);
          }
        }}
        inputProps={{ inputMode: number ? "decimal" : "text" }}
        // inputMode="decimal"
        type={number2 ? "number" : "text"}
        className={classes.input}
        placeholder={placeholder}
        value={valuedata}
        readOnly={readonly}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            document.activeElement.blur();
          }
        }}
      />
      {labeloption && (
        <Text font={fonts.notoSansKRMedium} className={classes.labeloption}>
          {labeloption}
        </Text>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: "13px",
    padding: "10px",
    color: colors.text,
  },
  labeloption: {
    fontSize: "13px",
    padding: "10px",
    color: colors.text,
    backgroundColor: "#eee",
  },
  root: {
    alignSelf: "stretch",
    border: "0.7px solid #dddddd",
    borderRadius: "5px",
  },
  input: {
    fontFamily: fonts.notoSansKRRegular,
    flex: 1,
    fontSize: "14px",
    alignSelf: "stretch",
    textAlign: "right",
  },
});
