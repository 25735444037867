import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import _ from "react-app-polyfill/ie9";
import __ from "react-app-polyfill/ie11";
import axios from "axios";
import { expireAuth } from "./libs/actions";

// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  (error) => {
    const status = error?.response?.status;

    if (status === 401 || status === 403) {
      expireAuth();
    }

    console.log("api error", error);
    return Promise.reject(error);
  }
);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
