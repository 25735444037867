import { ButtonBase, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Button from "../../components/button/Button";
import ButtonRadio from "../../components/button-radio/ButtonRadio";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { userInit } from "../../redux/user/UserActions";
import { updateSignUp } from "../../redux/auth/SignUpReducer";
import Checkbox from "../../components/checkbox/Checkbox";
import CheckboxCircle from "../../components/checkbox-circle/CheckboxCircle";
import Flex from "../../components/flex/Flex";
import images from "../../libs/images";
import * as APIS from "../../libs/apis";
import RNHeader from "../../components/common/RNHeader";

export default function AcceptTerms({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const [terms, setTerms] = useState([]);
  const { termsTypes = [] } = useSelector((s) => s.signUp);

  useEffect(() => {
    APIS.getTerms().then(({ data: { success, data = [], message } }) => {
      if (success) {
        const filtered = data.filter(({ signup }) => signup);
        setTerms(
          filtered.map((item) => {
            const checked = termsTypes.includes(item.type);
            return { ...item, checked };
          })
        );
      }
    });
  }, []);

  const register = () => {
    const termsTypes = terms
      .filter(({ checked }) => checked)
      .map(({ type }) => type);

    dispatch(updateSignUp({ termsTypes }));

    history.push(routes.authRegisterShop);
  };

  const handleClickAll = (checked) => {
    setTerms(terms.map((item) => ({ ...item, checked })));
  };

  const handleClickCheck = (checked, i) => {
    const item = terms[i];

    const copy = [...terms];
    copy.splice(i, 1, { ...item, checked });
    setTerms(copy);
  };

  const canNext =
    terms.findIndex(({ checked, required }) => required && !checked) === -1;
  const allCheck = terms.findIndex(({ checked }) => !checked) === -1;
  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "서비스 이용을 위해 약관에 동의해 주세요.",
        onGoBack: () => {
          history.push(routes.main);
        },
      }}
    >
      <RNHeader
        visible={false}
        goBack="CUSTOM"
        onGoBack={() => {
          history.push(routes.main);
        }}
      />
      <CheckboxCircle
        className={classes.checkbox}
        label="전체동의"
        checked={allCheck}
        onCheckedChange={handleClickAll}
      />
      {terms.map(({ type, title, required, checked }, i) => {
        return (
          <Checkbox
            key={i.toString()}
            optionComponent={
              <img
                onClick={(e) => {
                  e.stopPropagation();

                  APIS.getTermsDetail(type).then(
                    ({
                      data: {
                        data: { content },
                      },
                    }) => {
                      history.push(routes.htmlViewer, { title, content });
                    }
                  );
                }}
                alt="right"
                src={images.right}
                className={classes.right}
              />
            }
            className={classes.cb}
            onCheckedChange={(checked) => handleClickCheck(checked, i)}
            checked={checked}
            label={`${required ? "[필수]" : "[선택]"} ${title}`}
          />
        );
      })}
      <ButtonBase
        onClick={register}
        disabled={!canNext}
        className={classes.rightWrap}
      >
        <img
          alt="next"
          src={canNext ? images.arrowrighton : images.arrowright}
          className={classes.arrowright}
        />
      </ButtonBase>

      {/* 
      <Button
        label="전체 동의"
        className={classes.allButton}
        onClick={() => {
          setState((state) => ({
            ...state,
            checkedList: TEMP_TERMS.map((_, i) => i),
          }));
        }}
      />

      <Flex className={classes.terms}>
        {TEMP_TERMS.map(({ content }, i) => {
          return (
            <CheckboxCircle
              key={i.toString()}
              className={classes.term}
              labelSize={14}
              checked={checkedList.includes(i)}
              onCheckedChange={(checked) => {
                let nextList = checkedList.slice();
                if (checked) {
                  nextList.push(i);
                } else {
                  nextList.splice(nextList.indexOf(i), 1);
                }

                setState((state) => {
                  return { ...state, checkedList: nextList };
                });
              }}
              label={content}
            />
          );
        })}
      </Flex>

      <div style={{ height: "100px" }} />
      <Button
        onClick={register}
        label={"다음"}
        className={classes.button}
        disabled={TEMP_TERMS.length !== checkedList.length}
      />
       */}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  allButton: {
    margin: "20px 30px 20px 30px",
    borderRadius: "20px",
  },
  terms: { margin: "0px 30px 0px 30px" },
  term: { marginTop: "10px", width: "100%", justifyContent: "start" },
  label1: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  button: {
    margin: "100px 30px",
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },

  checkbox: {
    margin: "20px 30px 0px 30px",
  },
  arrowright: {
    width: "40px",
    height: "30px",
  },
  rightWrap: {
    position: "absolute",
    bottom: 50,
    right: 30,
  },
  right: { width: "8px", height: "12px" },
  cb: {
    margin: "0px 30px",
    marginTop: 30,
  },
});
