import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import BasicLayout from "../../layouts/basic/BasicLayout";
import consts from "../../libs/consts";
import { getCurrentMyPosition, objToQueryStr } from "../../services/utils";
import { useDispatch } from "react-redux";
import { toastShow } from "../../redux/toast/ToastActions";
import ShopItemSelect from "../../components/shop-item-select/ShopItemSelect";
import { useHistory } from "react-router";
import { messageError } from "../../redux/message/MessageActions";
import axios from "axios";

export default function FindShop({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { shopId, state, to, mode } = history.location.state || {};
  const [shops, setShops] = useState([]);
  const [near, setNear] = useState();

  useEffect(() => {
    getCurrentMyPosition()
      .then((latlng) => {
        axios
          .get(
            consts.apiBaseUrl +
              "/api/shop/list" +
              objToQueryStr({
                ...latlng,
              })
          )
          .then((res) => {
            const data = res.data;
            setShops([
              ...data.map((x, i) => {
                if (x.id === shopId) {
                  x.checked = true;
                }

                return x;
              }),
            ]);
          })
          .catch((e) => {
            dispatch(messageError(e));
          });

        axios
          .get(
            consts.apiBaseUrl +
              "/api/shop/nearListOne" +
              objToQueryStr({
                ...latlng,
              })
          )
          .then((res) => {
            const data = res.data;
            if (data.id === shopId) {
              data.checked = true;
            }
            setNear({ ...data });
          })
          .catch((e) => {
            dispatch(messageError(e));
          });
      })
      .catch((e) => {
        axios
          .get(consts.apiBaseUrl + "/api/shop/list")
          .then((res) => {
            const data = res.data;
            setShops([
              ...data.map((x, i) => {
                if (x.id === shopId) {
                  x.checked = true;
                }

                return x;
              }),
            ]);
          })
          .catch((e) => {
            dispatch(messageError(e));
          });

        dispatch(
          toastShow(
            "위치 정보를 수집할 수 없습니다. 가까운 매장을 불러올 수 없습니다."
          )
        );
      });
  }, []);

  const handleClick = (shop) => () => {
    if (mode === "click") {
      history.replace(to, { ...state, shop: shop });
    }
  };

  const RenderItem = ShopItemSelect; // : ShopItem;
  const districts = Array.from(new Set(shops.map((x) => x.district)));
  return (
    <BasicLayout
      white
      stackTopbar={{
        title: "매장 찾기",
        border: true,
      }}
    >
      <Text className={classes.label} font={fonts.notoSansKRMedium}>
        가까운 매장
      </Text>
      {Boolean(near) && (
        <RenderItem
          {...near}
          list
          onClick={handleClick(near)}
          onCheckedChange={(v) => {
            if (mode === "click") {
              handleClick(near)();
              return;
            }
            if (v) {
              setNear({ ...near, checked: true });
            } else {
              setNear({ ...near, checked: false });
            }
            setShops([
              ...shops.map((y) => {
                if (y.id === near.id) {
                  y.checked = v;
                } else {
                  y.checked = false;
                }
                return y;
              }),
            ]);
          }}
        />
      )}
      {districts.map((x, i) => {
        return (
          <Flex
            key={i.toString()}
            style={{
              marginBottom: i === districts.length - 1 ? 30 : 0,
            }}
          >
            <Text className={classes.label} font={fonts.notoSansKRMedium}>
              {x}
            </Text>
            {shops
              .filter((y) => y.district === x)
              .map((shop, index) => {
                return (
                  <RenderItem
                    {...shop}
                    list
                    key={index.toString()}
                    onClick={handleClick(shop)}
                    onCheckedChange={(v) => {
                      if (mode === "click") {
                        handleClick(shop)();
                        return;
                      }
                      setShops([
                        ...shops.map((y) => {
                          if (y.id === shop.id) {
                            if (shop.id === near.id) {
                              setNear({ ...near, checked: v });
                            } else {
                              setNear({ ...near, checked: false });
                            }
                            y.checked = v;
                          } else {
                            y.checked = false;
                          }
                          return y;
                        }),
                      ]);
                    }}
                  />
                );
              })}
          </Flex>
        );
      })}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  root: {
    height: "100%",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  label: {
    fontSize: "14px",
    color: "#333",
    margin: "0px 13px",
    marginTop: "16px",
    padding: "13px 0px",
    alignSelf: "stretxh",
    borderBottom: "1px solid #eee",
  },
});
