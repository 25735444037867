import axios from "axios";
export const postRequest = async (url, data, headers) => {
  try {
    const response = await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        ...headers,
      },
    });
    const responseData = response.data;
    return responseData;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    } else {
      throw error.message || error;
    }
  }
};

export const putRequest = async (url, data, headers) => {
  try {
    const response = await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        ...headers,
      },
    });
    const responseData = response.data;
    return responseData;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    } else {
      throw error.message || error;
    }
  }
};

export const getRequest = async (url, data = {}, headers) => {
  try {
    const response = await axios({
      method: "get",
      url:
        url +
        "?" +
        Object.keys(data)
          .map((key) => key + "=" + data[key])
          .join("&"),
      headers: {
        ...headers,
      },
    });
    const responseData = response.data;
    return responseData;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    } else {
      throw error.message || error;
    }
  }
};
export const deleteRequest = async (url, data = {}, headers) => {
  try {
    const response = await axios({
      method: "delete",
      url:
        url +
        "?" +
        Object.keys(data)
          .map((key) => key + "=" + data[key])
          .join("&"),
      headers: {
        ...headers,
      },
    });
    const responseData = response.data;
    return responseData;
  } catch (error) {
    if (error.response) {
      const { data } = error.response;
      let errorObj;
      if (typeof data === "string") {
        errorObj = { message: data };
      } else {
        errorObj = data;
      }
      throw errorObj;
    } else {
      throw error.message || error;
    }
  }
};
