import React, { useEffect, useState } from "react";
import ic_up from "../../assets/images/ic_up.png";
import ic_buy from "../../assets/images/ic_buy.png";
import ic_sell from "../../assets/images/ic_sell.png";
import ic_con_right_b from "../../assets/images/ic_con_right_b.png";
import gold1 from "../../assets/images/gold1.png";
import gold2 from "../../assets/images/gold2.png";
import ic_down from "../../assets/images/ic_down.png";
import silver1 from "../../assets/images/silver1.png";
import silver2 from "../../assets/images/silver2.png";
import { useSelector } from "react-redux";
import { formatTime, numberWithCommas, postMessage } from "../../libs/utils";
import AspectRatioBox from "../../components/common/AspectRatioBox";
import QuoteChart from "./QuoteChart";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";

const CONSTANT = {
  gold: {
    TITLE: "금(Gold)",
    HOT_GOODS_IMG: gold1,
  },
  silver: {
    TITLE: "은(Silver)",
    HOT_GOODS_IMG: silver1,
  },
};
const Accordion = ({ asset, navigate, hotGoods }) => {
  const marketPrice = useSelector((s) => s.marketPrice);
  const [chart, setChart] = useState([]);

  useEffect(() => {
    getChartData();
  }, []);

  // 차트 데이터 load
  const getChartData = () => {
    const endDate = new Date();
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 1);

    APIS.getClosingContract({
      assetType: asset.toUpperCase(),
      from: formatTime(fromDate, "YYYY-MM-DD"),
      to: formatTime(endDate, "YYYY-MM-DD"),
    }).then(({ data: { data } }) => {
      setChart(
        data.map(({ quoteDate, closePrice }) => {
          return { date: quoteDate, price: +closePrice };
        })
      );
    });
  };

  // 매수,매도 클릭시 이벤트
  const handleClickTrading = (state) => {
    return () => {
      navigate(routes.trading, { type: asset, ...state });
    };
  };

  const { current, closing } = marketPrice[asset];

  const diffPrice = current - closing;
  const diffPer = +((diffPrice / current) * 100).toFixed(2) || 0;
  const diff = diffPrice > 0 ? "up" : diffPrice < 0 ? "down" : "normal";

  return (
    <li>
      <input
        type="checkbox"
        id={`checkbox-${asset}`}
        name="checkbox-accordion"
      />
      <label htmlFor={`checkbox-${asset}`}>
        <span className="li_tit">{CONSTANT[asset]["TITLE"]}</span>
        <div className="li_money">
          <div className="li_price">
            <span className="spoqa">{numberWithCommas(current)}</span>원/g
            <br />
            <span className="bot_price">
              <span className="spoqa">
                {numberWithCommas(parseInt(current * 3.75))}
              </span>{" "}
              원/돈
            </span>
          </div>
          <div className={`li_change spoqa ${diff}`}>
            {diff === "up" && "+"}
            {diffPrice}
            <br />
            {diff === "up" && <img src={ic_up} alt="up" />}
            {diff === "down" && <img src={ic_down} alt="down" />}
            {diffPer}%
          </div>
        </div>
      </label>
      <div className="content">
        <div className="content-price">
          <AspectRatioBox width={350} height={200} style={{ marginTop: 10 }}>
            <QuoteChart data={chart} />
          </AspectRatioBox>
          <div className="price_btn">
            <button
              className="price_buy"
              onClick={handleClickTrading({ method: "buy" })}
            >
              <img src={ic_buy} alt="" />
              매수
            </button>
            <button
              className="price_sell"
              onClick={handleClickTrading({ method: "sell" })}
            >
              <img src={ic_sell} alt="" />
              매도
            </button>
          </div>
        </div>
        <div className="price_product">
          <div className="product_tit">
            <h3>
              실물인출 인기 상품 <img src={ic_con_right_b} alt=">" />
            </h3>
            <button
              className="btn_all"
              onClick={() => navigate(routes.physicalWithDrawal)}
            >
              전체 보기
            </button>
          </div>
          <div className="product_cont">
            <ul>
              <li
                className={`product_card ${asset}`}
                onClick={() => {
                  postMessage({
                    command: "SET_HEADER",
                    param: {
                      visible: true,
                      goBack: "DEFAULT",
                      title: "실물인출 안내",
                    },
                  });

                  window.location.href = 'https://guide.kumbang.co.kr/guide_view_08.html';
                }}
              >
                <h3>
                  금방금방
                  <br />
                  실물인출 안내
                </h3>
                <p>상세 안내 보기</p>
                <img src={CONSTANT[asset]["HOT_GOODS_IMG"]} alt="" />
              </li>
              {hotGoods.map(({ image, name, gram, totalPrice, id }, i) => {
                return (
                  <li
                    className="product_card"
                    key={i.toString()}
                    onClick={() => {
                      navigate(routes.physicalWithDrawalPDetail, { id });
                    }}
                  >
                    <img src={image} alt="" />

                    <p className="spoqa">
                      {name}
                      <span className="spoqa">({numberWithCommas(gram)}g)</span>
                    </p>
                    <p className="product_price">
                      <span className="spoqa">
                        {numberWithCommas(totalPrice)}
                      </span>
                      원
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Accordion;
