import axios from "axios";
import queryString from "query-string";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const setAuthorization = (authToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
};

export const initAuthorization = () => {
  axios.defaults.headers.common["Authorization"] = "";
};

export const getOauthUrls = () => {
  return axios.get("/api/v1/user/auth/oauth/urls");
};

export const getAuthToken = (code) => {
  return axios.post(`/api/v1/user/auth/oauth/token`, { code });
};

export const getSelfVerificationRequestId = (purpose) => {
  return axios.post(`/api/v1/user/verification/self/prepare`, { purpose });
};

export const postSelfVerificationComplete = (param) => {
  return axios.post(`/api/v1/user/verification/self/complete`, param);
};

export const getUserAddress = () => {
  return axios.get(`/api/v1/user/auth/user/address`);
};

export const addUserAddress = (param) => {
  return axios.put(`/api/v1/user/auth/user/address`, param);
};

export const modifyUserAddress = (param) => {
  return axios.post(`/api/v1/user/auth/user/address`, param);
};

export const postSignOut = () => {
  return axios.post(`/api/v1/user/auth/oauth/logout`);
};

export const getTerms = () => {
  return axios.get(`/api/v1/user/shared/terms`);
};

export const getTermsDetail = (type) => {
  return axios.get(`/api/v1/user/shared/terms/${type}`);
};

export const getNearbyShop = (query) => {
  return axios.get(`/api/v1/user/shared/shop${query}`);
};

export const getShopByName = (param) => {
  return axios.post(`/api/v1/user/shared/shop/search`, param);
};

export const getUserInfo = () => {
  return axios.get("/api/v1/user/auth/user/me");
};

export const getGoldorSilver = (param) => {
  // console.log("data = " + param);
  return axios.get("/api/infotrade/getcurrentmarketprice?type=" + param);
};

export const getUserHoldingsInfo = () => {
  return axios.get(`/api/v1/user/asset`);
};

export const getUserTradeInfo = () => {
  return axios.get(`/api/v1/user/auth/user/trade`);
};

export const getCurrentMarketPrice = (type) => {
  return axios.get(
    `/api/v1/user/market/buysell/currentmarketprice?type=${type}`
  );
};

export const getHotGoods = () => {
  return axios.get(`/api/v1/user/market/shared/hot/goods`);
};
export const getHotSafeTrade = () => {
  return axios.get(`/api/v1/user/market/shared/hot/safe-trade`);
};

export const putgoodwithdrawalOrder = (param, gid) => {
  return axios.put(`/api/v1/user/market/goodswithdrawal/trans/${gid}`, param);
};

export const getBanks = () => {
  return axios.get(`/api/v1/user/shared/code/bank`);
};

export const authBankAccount = (param) => {
  return axios.post(`/api/v1/user/asset/bankaccount/assign`, param);
};

export const getUserAsset = () => {
  return axios.get(`/api/v1/user/asset/info`);
};

export const withdrawalSend = (param) => {
  return axios.post(`/api/v1/user/market/withdrawal/withdrawal`, param);
};

export const getCurrentClosingMarketPrice = (type) => {
  return axios.get(
    `/api/v1/user/market/buysell/currentclosingmarketprice?type=${type}`
  );
};

// 기간 별 일일 종가 조회
export const getClosingContract = ({ assetType, from, to }) => {
  return axios.get(
    `/api/v1/user/market/buysell/closingcontract?assetType=${assetType}&from=${from}&to=${to}`
  );
};

export const getVirtualAccount = () => {
  return axios.get("/api/v1/user/asset/virtual");
};

export const getWonsHistory = (endDt, startDt, userId) => {
  return axios.get(
    `/api/v1/user/userkrwinout?endDt=${endDt}&startDt=${startDt}&userId=${userId}`
  );
};

export const putTransConfirm = (transId) => {
  return axios.put(
    `/api/v1/user/market/goodswithdrawal/trans/${transId}/confirm`
  );
};

export const getSharedCodeTrade = (config = "", asset = "") => {
  return axios.get(
    `/api/v1/user/shared/code/trade?config=${config}${
      asset ? `&asset=${asset}` : ""
    }`
  );
};

export const getSharedCodeConfig = (config) => {
  return axios.get(`/api/v1/user/shared/code/config/${config}`);
};

// 실물인출 > 카테고리 조회
export const getGoodsCategoryList = () => {
  return axios.get("/api/v1/user/market/goodswithdrawal/goodsCategory/list");
};

// 실물인출 목록 조회
export const getGoods = (categoryId, query = "") => {
  return axios.get(
    `/api/v1/user/market/goodswithdrawal/goods/${categoryId}/list${query}`
  );
};

// 실물인출 상품 상세
export const getGoodsDetail = (goodsId) => {
  return axios.get(
    `/api/v1/user/market/goodswithdrawal/goods/${goodsId}/detail`
  );
};

// 실물인출 주문 상세
export const getGoodsTransDetail = (orderId) => {
  return axios.get(
    `/api/v1/user/market/goodswithdrawal/trans/${orderId}/getDetail`
  );
};

// 실물인출 주문내역 조회
export const getGoodsTransSearch = (orderNo) => {
  return axios.get(
    `/api/v1/user/market/goodswithdrawal/trans/search?no=${orderNo}`
  );
};

// 실물인출 주문 취소
export const deleteTransCancel = (transId) => {
  return axios.delete(`/api/v1/user/market/goodswithdrawal/trans/${transId}`);
};

/**************************************************
 * 감정평가
 **************************************************/

// 감정평가 > 상세 > 후기 조회
export const getAppraisalFeedbakcByRequest = (requestId) => {
  return axios.get(
    `/api/v1/user/market/appraisal/FeedbacksByRequest?requestId=${requestId}`
  );
};

// 감정평가 > 상세 > 상태 변경
export const putAppraisalRequestsStatus = (id, param) => {
  return axios.put(
    `/api/v1/user/market/appraisal/Requests/${id}/status`,
    param
  );
};

// 감정평가 간편감정 제품 조회
export const getAppraisalProducts = (query = "") => {
  return axios.get(`/api/v1/user/market/appraisal/appraisalProducts${query}`);
};

// 감정평가 간편감정 결과 조회
export const getAppraisalSimplesDetail = (id = "") => {
  return axios.get(`/api/v1/user/market/appraisal/Simples/${id}`);
};

// 감정평가 간편감정 결과 조회
export const getAppraisalSimples = (query = "") => {
  return axios.get(`/api/v1/user/market/appraisal/Simples${query}`);
};

// 감정평가 요청 목록 조회
export const getAppraisalRequests = (query = "") => {
  return axios.get(`/api/v1/user/market/appraisal/Requests${query}`);
};

// 간편감정 삭제
export const deleteAppraisalSimple = (id = "") => {
  return axios.delete(`/api/v1/user/market/appraisal/Simples/${id}`);
};

/**************************************************
 * 출금
 **************************************************/

export const getWithdrawalAccount = (query = "") => {
  return axios.get(`/api/v1/user/market/withdrawal/account${query}`);
};

// 출금 계좌 비밀번호 체크
export const postAccountSecurity = (param) => {
  return axios.post(`/api/v1/user/market/withdrawal/account/security`, param);
};

/**************************************************
 * 매수/매도
 **************************************************/

// 매수/매도 요청
export const postRequestTrade = (param) => {
  return axios.post(`/api/v1/user/market/buysell/requesttrade`, param);
};

// 미체결 목록
export const getPendingSummary = (assetType) => {
  return axios.get(
    `/api/v1/user/market/buysell/pendingsummary?assetType=${assetType}`
  );
};

// 주문 가능 금액
export const getAvailableAmount = () => {
  return axios.get(`/api/v1/user/market/buysell/availableamount`);
};

// 나의 미체결 내역
export const getProcessingList = (assetType) => {
  return axios.get(`/api/v1/user/market/buysell/processing/${assetType}/list`);
};

// 체결 내역
export const getSignedList = ({ from, to }) => {
  return axios.get(
    `/api/v1/user/market/buysell/signed/list?from=${from}&to=${to}`
  );
};

// 상세 체결 내역
export const getSignedHistoryList = (id) => {
  return axios.get(`/api/v1/user/market/buysell/signed/${id}/list`);
};

// 주문 취소
export const deleteBuySell = (id) => {
  return axios.delete(`/api/v1/user/market/buysell/${id}`);
};

// 매수,매도 수수료율 조회
export const getBuySellFee = () => {
  return axios.get(`/api/v1/user/market/buysell/fee`);
};

/**************************************************
 * 아이디 로그인 / 회원가입
 **************************************************/

// 아이디 체크
export const getValidateLoginId = (loginId = "") => {
  return axios.get(`/api/v1/user/auth/id/validate?loginId=${loginId}`);
};

// 비밀번호 체크
export const postValidatePassword = (param) => {
  return axios.post(`/api/v1/user/auth/id/validate_password`, param);
};

// 아이디로 회원가입
export const postSignUpId = (param) => {
  return axios.post(`/api/v1/user/auth/id/signup`, param);
};

// 아이디로 로그인
export const postSignInId = (param) => {
  return axios.post(`/api/v1/user/auth/id/signin`, param);
};

/**************************************************
 * FAQ
 **************************************************/

// FAQ 리스트 요청
export const getFaqData = (paramQuery) => {
  return axios.get(`/api/v1/user/faq/${paramQuery || ""}`);
};

export const getCategory = () => {
  return axios.get(`/api/v1/user/faq/category`);
};

/**************************************************
 * QNA
 **************************************************/
export const getQnaList = () => {
  return axios.get(`/api/v1/user/qna`);
};
export const postQnaRegister = (data) => {
  return axios.post(`/api/v1/user/qna`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**************************************************
 * 메인
 **************************************************/
// 공지사항 조회
export const getNotice = () => {
  return axios.get(`/api/v1/user/notice`);
};

// 이벤트 배너 조회
export const getEvent = () => {
  return axios.get(`/api/v1/user/event`);
};

// 내 알림 내역 조회
export const getMyNotice = () => {
  return axios.get(`/api/v1/user/notice/my`);
};

// 푸쉬 알림 클리어
export const putNotificationClear = () => {
  return axios.put(`/api/v1/user/notification/push`);
};

/**************************************************
 * 안심직거래
 **************************************************/
// 카테고리 조회
export const getSafeTradeCategory = () => {
  return axios.get(`/api/v1/user/market/safe-trade/category`);
};

// 브랜드 조회
export const getSafeTradeBrand = (id) => {
  return axios.get(`/api/v1/user/market/safe-trade/category/${id}`);
};

// 제품 판매 등록
export const postSafeTradeOrder = (param) => {
  return axios.post(`/api/v1/user/market/safe-trade/order`, param, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// 제품 목록 조회
export const getSafeTradeList = (param) => {
  return axios.get(
    `/api/v1/user/market/safe-trade?${queryString.stringify(param)}`
  );
};

// 상품 정보 조회
export const getSafeTradeItem = (itemId) => {
  return axios.get(`/api/v1/user/market/safe-trade/item/${itemId}`);
};

// 상품 이미지 조회
export const getSafeTradeItemImage = (itemId) => {
  return axios.get(`/api/v1/user/market/safe-trade/item/${itemId}/image`);
};

// 상품 상세 설명 조회
export const getSafeTradeItemDetail = (itemId) => {
  return axios.get(`/api/v1/user/market/safe-trade/item/${itemId}/detail`);
};

// 상품 상세 기본 정보 조회
export const getSafeTradeItemInfo = (itemId) => {
  return axios.get(`/api/v1/user/market/safe-trade/item/${itemId}/info`);
};

// 내 구매 상품 목록 조회
export const getMySafeTradeBuyList = () => {
  return axios.get(`/api/v1/user/market/safe-trade/mypage/order/buy?size=999`);
};

// 내 판매 상품 목록 조회
export const getMySafeTradeSellList = () => {
  return axios.get(`/api/v1/user/market/safe-trade/mypage/order/sell?size=999`);
};

// 내 구매 상품 조회
export const getMySafeTradeBuy = (orderBookId) => {
  return axios.get(
    `/api/v1/user/market/safe-trade/mypage/order/buy/${orderBookId}`
  );
};

// 내 판매 상품 조회
export const getMySellSafeSell = (orderId) => {
  return axios.get(
    `/api/v1/user/market/safe-trade/mypage/order/sell/${orderId}`
  );
};

// 거래 진행 상태 조회
export const getSafeTradeHistory = (itemId, type, orderBookId = "") => {
  return axios.get(
    `/api/v1/user/market/safe-trade/mypage/item/${itemId}/history?types=${type}${
      orderBookId ? `&orderBookId=${orderBookId}` : ""
    }`
  );
};

// 판매 취소
export const deleteMySafeTrade = (itemId) => {
  return axios.delete(`/api/v1/user/market/safe-trade/mypage/item/${itemId}`);
};

// 제품 판매 신청 정보 조회
export const getSafeTradeOrderSearch = (orderId) => {
  return axios.get(`/api/v1/user/market/safe-trade/order/search/${orderId}`);
};

// 감정 결과 상세 조회
export const getMySafeTradeItemDetail = (itemId) => {
  return axios.get(
    `/api/v1/user/market/safe-trade/mypage/item/${itemId}/detail`
  );
};

// 제품 판매 요청
export const postSafeTradeSellOpen = (itemId, price) => {
  return axios.post(
    `/api/v1/user/market/safe-trade/mypage/item/${itemId}/open`,
    { price }
  );
};

// 제품 수수료 계산
export const getSafeTradeCalculate = (itemId, type, price) => {
  // type > BUY || SELL
  return axios.get(
    `/api/v1/user/market/safe-trade/mypage/item/${itemId}/calculate?types=${type}&price=${price}`
  );
};

// 제품 판매 요청
export const postSafeTradeUpdatePrice = (itemId, price) => {
  return axios.post(`/api/v1/user/market/safe-trade/mypage/item/${itemId}`, {
    price,
  });
};

// 제품 구매 신청
export const postSafeTradeBuy = (itemId, param) => {
  return axios.post(`/api/v1/user/market/safe-trade/item/${itemId}/buy`, param);
};

// 제품 결제 요청
export const postSafeTradeBuyPayment = ({ itemId, orderBookId, password }) => {
  return axios.post(
    `/api/v1/user/market/safe-trade/item/${itemId}/buy/${orderBookId}`,
    { password }
  );
};

// 제품 결제 취소
export const deleteSafeTradeBuyPayment = ({ itemId, orderBookId }) => {
  return axios.delete(
    `/api/v1/user/market/safe-trade/item/${itemId}/buy/${orderBookId}`
  );
};

// 구매 확정
export const postSafeTradeConfirm = (itemId) => {
  return axios.post(
    `/api/v1/user/market/safe-trade/mypage/item/${itemId}/confirm`
  );
};

// 상품 문의 조회
export const getSafeTradeQna = (itemId) => {
  return axios.get(`/api/v1/user/safe-trade/item/${itemId}/qna`);
};

// 상품 문의 하기
export const postSafeTradeQna = (itemId, param) => {
  return axios.post(`/api/v1/user/safe-trade/item/${itemId}/qna`, param);
};

// 상품 문의 삭제
export const deleteSafeTradeQna = (itemId, qnaId) => {
  return axios.delete(`/api/v1/user/safe-trade/item/${itemId}/qna/${qnaId}`);
};
