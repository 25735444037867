import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import routes from "../../libs/routes";
import Button from "../button/Button";
import NumberFormat from "react-number-format";
import consts from "../../libs/consts";

export default function PhysicalWithDrawalItem({
  rows,
  sPrice,
  settype,
  stock,
  seloption,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  var setPrice = sPrice * (rows.gram * stock);
  return (
    <Flex className={classes.root}>
      <ButtonBase
        onClick={() => {
          return;
        }}
        className={classes.item}
      >
        <img
          src={
            rows.imageIds[0].path
              ? consts.s3BaseUrl + "/" + rows.imageIds[0].path
              : images.goldbar
          }
          className={classes.sample1}
        />
        <Flex className={classes.main}>
          <Flex row className="row-between" style={{ alignSelf: "stretch" }}>
            <Text className={classes.label1}>{rows.name}</Text>
            <Text className={classes.label1}>{rows.code}</Text>
          </Flex>
          <Text className={classes.label2}>
            <Text font={fonts.notoSansKRMedium}>{rows.name}</Text>
          </Text>
          <Flex row className="row-between" style={{ alignSelf: "stretch" }}>
            <Text font={fonts.notoSansKRMedium}>제품중량</Text>
            <Text font={fonts.notoSansKRMedium}>
              {rows.gram * stock}g
              {/* = <NumberFormat value={setPrice} displayType={'text'} thousandSeparator={true} />원 */}
            </Text>
          </Flex>
          <Flex row className="row-between" style={{ alignSelf: "stretch" }}>
            <Text font={fonts.notoSansKRMedium}>제품수량</Text>
            <Text font={fonts.notoSansKRMedium}>{stock}개</Text>
          </Flex>
          <Flex row className="row-between" style={{ alignSelf: "stretch" }}>
            <Text font={fonts.notoSansKRMedium}>선택옵션</Text>
            <Text font={fonts.notoSansKRMedium}>{seloption}</Text>
          </Flex>
          <Flex row className="row-between" style={{ alignSelf: "stretch" }}>
            <Text font={fonts.notoSansKRMedium}>인출수수료</Text>
            <Text font={fonts.notoSansKRMedium}>
              <NumberFormat
                value={rows.fees * stock}
                displayType={"text"}
                thousandSeparator={true}
              />
              원
            </Text>
          </Flex>
        </Flex>
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  sample1: {
    width: "70px",
    border: "1px solid #eee",
    objectFit: "contain",
    height: "70px",
  },
  root: {
    borderBottom: "1px solid #eee",
  },
  item: {
    padding: "14px 0px",
    margin: "0px 14px",
    alignItems: "center",
    display: "flex",
  },
  main: {
    marginLeft: "16px",
    alignSelf: "stretch",
    alignItems: "flex-start",
    flex: 1,
  },
  label1: { color: colors.gray, fontSize: 12 },
  label2: {
    color: colors.gray,
    fontSize: 12,
    "& span": {
      fontSize: "16px",
      color: "#000",
    },
  },
  buttons: {
    margin: "13px 13px 20px 13px",
    "& button": {
      flex: 1,
      padding: "6px",
      marginLeft: "5px",
      "& span": {
        fontSize: "14px",
      },
      "&:first-child": {
        backgroundColor: "#fff",
        border: "1px solid " + colors.primary,
        "& span": {
          color: colors.primary + " !important",
        },
        marginLeft: "0px",
        marginRight: "5px",
      },
    },
  },
  orderState: { color: colors.primary, fontSize: "12px" },
});
