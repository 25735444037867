import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import * as APIS from "../../libs/apis";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import AssetItem from "./AssetItem";
import CanOrder from "./CanOrder";
import Header from "./Header";

export default function MyPage({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { userId } = useSelector((s) => s.auth);
  const [holdings, setHoldings] = useState({
    krw: 0,
    gold: 0,
    silver: 0,
    acceptableKrw: 0,
    acceptableGold: 0,
    acceptableSilver: 0,
  });

  const [tradeInfo, setTradeInfo] = useState({
    appraisalCount: 0,
    goodsWithdrawCount: 0,
    tradeCount: 0,
    safeTradeCount: 0,
  });

  const [assetInfo, setAssetInfo] = useState([]);

  useEffect(() => {
    APIS.getUserHoldingsInfo().then(({ data: { success, data = {} } }) => {
      success && setHoldings(data);
    });

    APIS.getUserTradeInfo().then(
      ({
        data: {
          success,
          data = {
            appraisalCount: 0,
            goodsWithdrawCount: 0,
            tradeCount: 0,
            safeTradeCount: 0,
          },
        },
      }) => {
        success && setTradeInfo(data);
      }
    );

    APIS.getUserAsset().then(({ data: { success, data = [] } }) => {
      success && setAssetInfo(data);
    });
  }, []);

  const { appraisalCount, goodsWithdrawCount, tradeCount, safeTradeCount } =
    tradeInfo;
  return (
    <MainLayout white>
      <Header {...holdings} navigate={navigate} />
      <CanOrder {...holdings} navigate={navigate} />

      <Flex row className={classes.root}>
        <ButtonBase
          onClick={() => {
            navigate(routes.appraise);
          }}
          className={classes.m}
        >
          <Text className={classes.ml} font={fonts.notoSansKRBold}>
            {appraisalCount}
          </Text>
          <Text className={classes.ml2}>감정평가</Text>
        </ButtonBase>
        <ButtonBase
          onClick={() => {
            navigate(routes.myPhysicalWithDrawal);
            // history.push(routes.myPhysicalWithDrawal);
          }}
          className={classes.m}
        >
          <Text className={classes.ml} font={fonts.notoSansKRBold}>
            {goodsWithdrawCount}
          </Text>
          <Text className={classes.ml2}>실물인출</Text>
        </ButtonBase>
        <ButtonBase
          className={classes.m}
          onClick={() => {
            navigate(routes.mytradehistory);
          }}
        >
          <Text className={classes.ml} font={fonts.notoSansKRBold}>
            {tradeCount}
          </Text>
          <Text className={classes.ml2}>매수/매도</Text>
        </ButtonBase>
        <ButtonBase
          className={classes.m}
          onClick={() => {
            navigate(routes.mySafeTradeList);
          }}
        >
          <Text className={classes.ml} font={fonts.notoSansKRBold}>
            {safeTradeCount}
          </Text>
          <Text className={classes.ml2}>안심직거래</Text>
        </ButtonBase>
      </Flex>
      <Flex className={classes.list}>
        {assetInfo.map((item, i) => (
          <AssetItem key={i.toString()} {...item} />
        ))}
      </Flex>
    </MainLayout>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",
  },
  m: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding: "10px",
  },
  ml: {
    color: colors.text,
    fontSize: "28px",
  },
  ml2: {
    color: colors.gray,
    fontSize: "13px",
  },
  list: {
    backgroundColor: colors.background,
    flex: 1,
  },
});
